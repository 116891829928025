import React from "react";
import { FaSort } from "react-icons/fa";

import ToasterAlert from "../utils/ToasterAlert";


const ProductDetailPricing = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,
}) => {
  return (
<>

    <ToasterAlert/>

    
    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
    <div className="p-8 md:p-14">
        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
          Product List
        </span>

        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  ID
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  sku
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("name")}
                    className="flex items-center space-x-1"
                  >
                    NAME
                    {sortColumn === "name" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                        <FaSort></FaSort>
                        ) : (
                          <FaSort></FaSort>
                        )}
                      </span>
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  brand
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Account GROUP
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Customer Pricing
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  auto price
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  rULE
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems &&
                currentItems.map((item) => (
                  <tr key={item._id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item._id}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.sku}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.brand}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.customerGroup}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                  
                      
                      <div className="text-sm text-gray-500 whitespace-normal">
                      {item.customer_group_price >=0 ?"£":""}
                        {
                        item.customer_group_price && item.customer_group_price.toFixed(2)} 
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.price}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {item.customer_rule_name}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>



    
    </>
  );
};




export default ProductDetailPricing;
