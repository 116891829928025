import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const pageSettingData = createAsyncThunk(
  "pageSetting",

  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "page_settings/get_pageSetting");
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const pageSettingSlice = createSlice({
  name: "pageSetting",
  initialState: {
    pageSettings : [],
    loading: false,
    error: null,
  },
  reducers: {
    addpageSetting: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(pageSettingData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(pageSettingData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(pageSettingData.fulfilled, (state, action) => {
          state.loading = false;
          state.pageSettings = action.payload;
        
        }
      );
  },
});
export const { addpageSetting } = pageSettingSlice.actions;
export default pageSettingSlice.reducer;


