import React from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const PermsTab = () => {

    const navigate = useNavigate();


    useEffect(()=>{const role = JSON.parse(localStorage.getItem("user_data")).role;if (role == "reseller" || role == "user") {navigate("/dashboard")}},[]);


  return (
  <div></div>
  )
}

export default PermsTab