import React, { useState, useEffect } from "react";
import getApiData from "../helpers/getApiData";
import { CircularLoader } from "../Container/CircularLoader";
import { MdDelete } from "react-icons/md";
import postApiData from "../helpers/postApiData";
import { AiOutlineSearch } from "react-icons/ai";
const RejectedProducts = ({ props, updateBit }) => {
  const [rejectedList, setRejectedList] = useState([]);
  const [circularProgress, setCircularProgress] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      setCircularProgress(true);
      const getRejectedProducts = await getApiData(
        "auth/get_rejected_products",
        ""
      );
      setRejectedList(getRejectedProducts);
      setCircularProgress(false);
    };
    fetchData();
  }, []);



  const handleSearch = async () => {
    setCircularProgress(true);
    try {
      const getRejectedProducts = await getApiData(
        "auth/get_rejected_products",
        searchText
      );
      setRejectedList(getRejectedProducts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setCircularProgress(false);
    }
  };



  


  const handleDeleteButton = async (_id, name) => {
    const val = { name: name, id: _id };

    await postApiData("auth/delete_rejected_products", val);
    updateBit((prev) => !prev);
    handleSearch();
   
  };

  return (
    <div className="overflow-y-auto">
      {circularProgress ? (
        <CircularLoader />
      ) : (
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto   absolute  h-fit  inset-0 z-50 outline-none focus:outline-none">
          <div className="flex items-center justify-center min-h-screen bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0 max-h-full overflow-y-auto">
              <div className="flex flex-col justify-center p-8 md:p-14 overflow-y-auto">
                <div className="flex items-end justify-end p-0 ">
                  <button
                    className="p-1 border-0 text-maincolor ml-auto bg-transparent text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      props(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor">×</span>
                  </button>
                </div>
                <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400 text-maincolor">
                  Rejected Products
                </span>


                <div className="relative w-full max-w-lg">
                <form onSubmit={(e) => { e.preventDefault(); handleSearch(); }}>
                    <div className="flex grid-cols-2 gap-4 w-full ">
                      <div className="flex  w-full overflow-hidden rounded-md bg-white shadow shadow-black/20">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                        </div>
                        <input
                          value={searchText}
                          onChange={(e) => setSearchText(e.target.value)}
                          type="search"
                          className="bg-gray-50 border flex-shrink-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                          placeholder="Search product... by Name"
                        />
                      </div>
                      <div className="flex w-full">
                        <button
                          type="submit"
                          className="w-full sm:w-auto border p-2 bg-maincolor text-white border-gray-300 text-md py-2 rounded-lg hover:bg-maincolor hover:text-white"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>

          </div>



                <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-maincolor">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          List of Rejected Products
                        </th>

                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {rejectedList &&
                        rejectedList.results &&
                        rejectedList.results.map((item) => (
                          <tr key={item._id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium font-dmsans text-gray-900">
                                {item.productDetails.name}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-dmsans text-gray-900">
                                {item.rejected_products &&
                                  item.rejected_products.map(
                                    (product, index) => (
                                      <tr>
                                        <td>
                                          <div
                                            key={index}
                                            className="text-maincolor font-dmsans font-normal"
                                          >
                                            <div
                                              className="text-sm font-semibold text-gray-500 font-dmsans"
                                              style={{
                                                whiteSpace: "normal",
                                              }}
                                            >
                                              {product}
                                            </div>
                                          </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                          <button
                                            onClick={() =>
                                              handleDeleteButton(
                                                item._id,
                                                product
                                              )
                                            }
                                            href="#"
                                            className="text-extra-color hover:extra-color"
                                          >
                                            <MdDelete />
                                          </button>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">
                                <button></button>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RejectedProducts;
