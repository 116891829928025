import React, { useState, useContext, useEffect } from "react";

import { useDispatch } from "react-redux";

import { ValidateUpdateDistributor } from "../utils/validateAPI";

import { useFormik } from "formik";

const DistributorUpdate = ({ distributorState, props, distributorData }) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      name: distributorState.name,
      cost_per_uplift: distributorState.cost_per_uplift,
      currency: distributorState.currency,
      id: distributorState._id,
    },
    validate: ValidateUpdateDistributor,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      props(false);
      dispatch(distributorData);
      return false;
    },
  });

  return (
    <>
      <div className="justify-center items-center scrollbar scrollbar-thumb-maincolor overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="container mx-auto">
          <div className="flex items-center  justify-center min-h-screen  bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
              <div className="flex flex-col justify-center p-8 md:p-14 ">
                <div className="flex items-end  justify-end  p-0 ">
                  <button
                    className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => {
                      props(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor ">×</span>
                  </button>
                </div>
                <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                  Update Distributor
                </span>

                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Distributor Name
                    </span>
                    <input
                      {...formik.getFieldProps("name")}
                      id="name"
                      type="text"
                      value={formik.values.name}
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      placeholder="distributor Name"
                    ></input>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>
                  <div className="py-4">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Cost Per Uplift
                    </span>
                    <div className="grid grid-cols-3 gap-0 mt-5">
                      <div className="flex items-center">
                        <input
                          {...formik.getFieldProps("cost_per_uplift")}
                          selected={formik.values.cost_per_uplift}
                          type="number"
                          className="w-20 placeholder:font-dmsans  border-b  focus:outline-none text-center focus:border-maincolor  focus:border-2 transition-colors placeholder:font-light"
                        />
                      </div>

                      <div className="flex items-center">
                        <div
                          {...formik.getFieldProps("currency")}
                          className="relative items-center"
                        >
                          <input
                            type="radio"
                            name="currency"
                            value="£"
                            checked={formik.values.currency === "£"}
                            onChange={formik.handleChange}
                          />
                          <span className="mx-1">£</span>
                        </div>
                        <div className="relative">
                          <input
                            {...formik.getFieldProps("currency")}
                            type="radio"
                            name="currency"
                            value="%"
                            checked={formik.values.currency === "%"}
                            onChange={formik.handleChange}
                          />
                          <span className="mx-2">%</span>
                        </div>
                      </div>
                    </div>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="py-4 ">
                    <button
                      type="submit"
                      className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                    >
                      Update Distributor
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default React.memo(DistributorUpdate);
