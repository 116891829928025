import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const plightData = createAsyncThunk(
  "plight",
  async (payload, { rejectWithValue }) => {
    try {
      const user_role = JSON.parse(localStorage.getItem("user_data")).role;
      const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
    
      const queryString = encodeURIComponent(JSON.stringify(payload));
        
      if (user_role == "admin") {
        const request = await axios.get(
          `${env.API_URL}product/product_new_light/${queryString}`
        );


        const response = await request.data;

        return response;
      } else {
        const request = await axios.get(
          env.API_URL + "plight/reseller_get_all_plights/" + user_id
        );
        const response = await request.data;
        return response;
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const plightSlice = createSlice({
  name: "plight",
  initialState: {
    plights: [],
    loading: false,
    plightCount: 0,
    error: null,
  },
  reducers: {
    addplight: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(plightData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(plightData.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(plightData.fulfilled, (state, action) => {
        state.loading = false;

        state.plights = action.payload;
      });
  },
});
export const { addplight } = plightSlice.actions;
export default plightSlice.reducer;
