import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import { useState, useEffect,useMemo } from "react";
import postApiData from "../helpers/postApiData";

import { CircularLoader } from "../Container/CircularLoader";

import ToasterAlert from "../utils/ToasterAlert";
import moment from "moment";
import useTabSetting from "../utils/useTabSettings";


const ProductDetail = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,

  setpublishUpdate

}) => {
  const [publishStates, setPublishStates] = useState({});
 
  const [name,key] = useTabSetting("/product_history");

  
  const [CircularProgress, setCircularProgress] = useState({});

  const newPublishStates = useMemo(() => {
    const publishStates = {};
  
    for (const loadBrands of currentItems) {
      publishStates[loadBrands._id] = loadBrands.publish;
    }
    return publishStates;
  }, [currentItems]);




  const handleCheckboxChange = async (productId, publishValue) => {
    try {

      
      setCircularProgress(true);

      await postApiData("product/update_product", {
        id: productId,
        value: publishValue,
      });



      setpublishUpdate((prev)=>prev + 1);

      setCircularProgress(false);

   
    } catch (err) {
      console.error(err);
    }
  };


  return (


    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
      <div className="p-8 md:p-14">
        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
          Product List
        </span>




{ CircularProgress == true ? 


<CircularLoader></CircularLoader>:<></>

}

<ToasterAlert></ToasterAlert>


          <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">


       


                <tr>


                {name &&  name.map((column, index) => {
      switch (column) {
        case "STOCK":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              STOCK
            </th>
          );
        case "NAME":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <button
                onClick={() => handleColumnSort("name")}
                className="flex items-center space-x-1"
              >
                NAME
                {sortColumn === "name" && (
                  <span
                    className={`${
                      sortDirection === "asc"
                        ? "text-gray-600"
                        : "text-maincolor dark:text-maincolor"
                    }`}
                  >
                    {sortDirection === "asc" ? (
                      <AiOutlineArrowUp />
                    ) : (
                      <AiOutlineArrowDown />
                    )}
                  </span>
                )}
              </button>
            </th>
          );
        case "PRICE":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PRICE
            </th>
          );
        case "DISTRIBUTOR":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              DISTRIBUTOR
            </th>
          );
        case "URL":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              URL
            </th>
          );
        case "CATEGORY":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Category
            </th>
          );
        case "CUSTOMER MARKUP":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              customer markup
            </th>
          );
        case "PRICE NEW":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PRICE NEW
            </th>
          );
        case "PRICE DIFFERENCE FOR NEW":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PRICE DIFFERENCE FOR NEW
            </th>
          );
        case "PRICE DIFFERENCE FOR OLD":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              PRICE DIFFERENCE FOR OLD
            </th>
          );
        case "PUBLISH PRICE OLD":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Publish Price Old
            </th>
          );
        case "TO BE PUBLISH OR MARKUP	DATE":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              To be Publish or markup
            </th>
          );
        case "DATE":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
          );
        case "EAN":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              EAN
            </th>
          );
        case "SKU":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              sku
            </th>
          );
        case "HARD STOP (%)":
          return (
            <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              hard stop (%)
            </th>
          );
        default:
          return null;
      }
    })}
    





{/*                   
                
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    STOCK
                  </th>
              
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <button
                      onClick={() => handleColumnSort("name")}
                      className="flex items-center space-x-1"
                    >
                      NAME
                      {sortColumn === "name" && (
                        <span
                          className={`${
                            sortDirection === "asc"
                              ? "text-gray-600"
                              : "text-maincolor dark:text-maincolor"
                          }`}
                        >
                          {sortDirection === "asc" ? (
                            <AiOutlineArrowUp />
                          ) : (
                            <AiOutlineArrowDown />
                          )}
                        </span>
                      )}
                    </button>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    PRICE
                  </th>

            

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    DISTRIBUTOR
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    URL
                  </th>
                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Category
                  </th>
                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    customer markup
                  </th>

      
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    PRICE NEW
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    PRICE Difference For New
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    PRICE Difference For Old
                  </th>
                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Publish Price Old
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    To be Publish or markup
                  </th>

                  
                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Date
                  </th>

                  
        
          
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  EAN
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  sku
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  hard stop (%) 
                  </th>
                   */}

                  <th className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
        

              <tbody className="bg-white divide-y divide-gray-200">
  {currentItems &&
    currentItems.map((item) => (
      <tr key={item._id}>
        
        {name && name.map((column, index) => {
          switch (column) {
            case "STOCK":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.stock}
                  </div>
                </td>
              );
            case "NAME":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900 whitespace-normal">
                    {item.name}
                  </div>
                </td>
              );
            case "PRICE":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.price >= 0 ? "£" : ""}
                    {item.price && item.price.toFixed(2)}
                  </div>
                </td>
              );
            case "DISTRIBUTOR":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">
                    {item.distributor}
                  </div>
                </td>
              );
            case "URL":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">
                    {item.product_url}
                  </div>
                </td>
              );
            case "CATEGORY":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-500">{item.category}</div>
                </td>
              );


            case "CUSTOMER MARKUP":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.customer_group_price > 0 ? "£" : ""}
                    {item.customer_group_price &&
                      item.customer_group_price.toFixed(2)}
                  </div>
                </td>
              );

              
            case "PRICE NEW":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.priceNew >= 0 ? "£" : ""}
                    {item.priceNew && item.priceNew.toFixed(2)}
                  </div>
                </td>
              );
              
            case "PRICE DIFFERENCE FOR NEW":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.priceDiffNew >= 0 ? "£" : ""}
                    {item.priceDiffNew && item.priceDiffNew.toFixed(2)}
                  </div>
                </td>
              );
            case "PRICE DIFFERENCE FOR OLD":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.priceDiffOld >= 0 ? "£" : ""}
                    {item.priceDiffOld && item.priceDiffOld.toFixed(2)}
                  </div>
                </td>
              );
            case "PUBLISH PRICE OLD'":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.publishPrice >= 0 ? "£" : ""}
                    {item.publishPrice && item.publishPrice.toFixed(2)}
                  </div>
                </td>
              );
            case "TO BE PUBLISH OR MARKUP":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.markup_price > 0 ? "£" : ""}
                    {item.markup_price && item.markup_price.toFixed(2)}
                  </div>
                </td>
              );
            case "DATE":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {moment(item.date).format("YYYY-MM-DD")}
                  </div>
                </td>
              );
            case "EAN":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.ean}
                  </div>
                </td>
              );
            case "SKU":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.sku}
                  </div>
                </td>
              );
            case "HARD STOP (%)":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.hard_stop_rule}
                  </div>
                </td>
              );
            default:
              return null;
          }
        })}
      </tr>
    ))}
</tbody>



            </table>
        </div>
        
      </div>
    </div>
  );
};

export default ProductDetail;
