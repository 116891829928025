import React, { useEffect, useState } from "react";
import Header from "../Container/Header";
//import {Link} from 'react-router-dom';
import MenuHeader from "../Container/MenuHeader";
import getApiData from "../helpers/getApiData";
import { Link } from "react-router-dom";
const DashBoard = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const newdata = await getApiData("product/get_all_filters", "");
      
      setData(newdata);
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="items-center mx-auto container my-2 0">
        <span className="py-4 text-xl text-left text-gray-500 font-dmsans">
          You are now viewing a version of this site that only shows products in
          the pricelist. The category, distributor and brand links above all
          apply to this list of items from your distributors.
        </span>
        
        <div id="main" className="grid grid-cols-2 gap-1 justify-evenly ">
          <div className="bg-gray-300 w-26 md:h-80 h-full   border-green-600 border-l-4">
            
            <div className="flex flex-col items-center w-full">
              <span className="text-2xl font-medium text-subheading-400 font-dmsans mt-2 mb-20 border-b  border-gray-500">
                <Link to="/markup"> Markups</Link>
              </span>
              <ul className="pl-4">
                <li>
                  Default markup
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.gmarkup} %
                  </span>
                </li>
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.markup}
                  </span>
                  price rules created.
                </li>
                <li>
                  Lowest markup
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.lowest_markup} %
                  </span>
                </li>
                <li>
                  Highest markup
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.max_markup} %
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-300 w-26 md:h-80 h-full  border-green-600 border-l-4">
            <div className="flex flex-col items-center w-full">
              <span className="text-2xl font-medium text-subheading-400 font-dmsans mt-2 mb-20 border-b  border-gray-500">
                <Link to="/distributors"> Distributors</Link>
              </span>
              <ul className="pl-4">
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.distributor}
                  </span>
                  active distributor(s)
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-300 w-26 md:h-80 h-full  border-yellow-600 border-l-4">
            <div className="flex flex-col items-center w-full">
              <span className="text-2xl font-medium text-subheading-400 font-dmsans mt-2 mb-20 border-b  border-gray-500">
                <Link to="/product_list"> Products</Link>
              </span>
              <ul className="pl-4">
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.product}
                  </span>
                  unique products published.
                </li>
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.category}
                  </span>
                  categories published.
                </li>
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.brand}
                  </span>
                  brands published.
                </li>
                <li>
                  <span className="text-maincolor font-dmsans mx-2">
                    {data.distributor}
                  </span>
                  distributors published.
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-gray-300 w-26 h-80  border-blue-600 border-l-4"></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashBoard;
