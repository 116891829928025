import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";

import { googleData } from "../helpers/AuthStore/googleSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import { CircularLoader } from "../Container/CircularLoader";
import { CiDeliveryTruck } from "react-icons/ci";
import { CgDanger } from "react-icons/cg";
import { GrStatusGood } from "react-icons/gr";
import { Tooltip } from "react-tooltip";
import "./Products.css";
import { TbPlayerEjectFilled } from "react-icons/tb";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { MdUnpublished } from "react-icons/md";



import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AiOutlineSearch } from "react-icons/ai";

import ScreenRights from "../utils/ScreenRights";

import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import usePageSetting from "../utils/usePageSetting";

import ToasterAlert from "../utils/ToasterAlert";
import postApiData from "../helpers/postApiData";
import RejectedProducts from "./RejectedProducts";

import { AiFillInfoCircle } from "react-icons/ai";
import useTabSetting from "../utils/useTabSettings";

const GoogleProducts = () => {
  const dispatch = useDispatch();
  let googleProducts = useSelector((state) => state.googleProducts);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [optionsFilter, setOptionsFilter] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuerySupplier, setSearchQuerySupplier] = useState("");
  const [searchQuerySupplierTitle, setSearchQuerySupplierTitle] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [CircularProgress, setCircularProgress] = useState(false);

  const [updateBit, setUpdateBit] = useState(false);
  const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
  const screen_name = "/google_products";

  const [name, key] = useTabSetting("/google_products");
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);
  const [selectedFilters, setSelectedFilters] = useState({
    filtered: "",
  });

  const filterOptions = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 2,
      name: "No",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleFilter = (filtered) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      filtered,
    }));
  };

  const handleRejection = async (title, name) => {
   

    await postApiData("auth/add_rejected_product", {
      name: title,
      product_id: name,
    });


    dispatch(
      googleData({
        page: currentPage,
        searchQuery: searchQuery,
        searchQuerySupplier: searchQuerySupplier,
        searchQuerySupplierTitle: searchQuerySupplierTitle,
      })
    );
  };

  const optionElementsFilter = useMemo(() => {
    return optionsFilter.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsFilter]);

  useEffect(() => {
    async function fetchData() {
      const resultsFilter = [];
      resultsFilter.push({ key: 0, value: "" });
      filterOptions.map((value) => {
        resultsFilter.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionsFilter([...resultsFilter]);
    }

    fetchData();
  }, []);

  let totalPages = Math.ceil(
    googleProducts.googleProducts.productCount / itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    setCircularProgress(true);

    dispatch(
      googleData({
        page: currentPage,
        searchQuery: searchQuery,
        searchQuerySupplier: searchQuerySupplier,
        searchQuerySupplierTitle: searchQuerySupplierTitle,
        selectedFilters: selectedFilters,
      })
    );

    setCircularProgress(false);
  }, [
    dispatch,
    currentPage,
    searchQuery,
    searchQuerySupplier,
    searchQuerySupplierTitle,
    updateBit,
    selectedFilters,
  ]);

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;
    endPage = isNaN(endPage) == true ? 0 : endPage;
    startPage = isNaN(startPage) == true ? 0 : startPage;
    totalPages = isNaN(totalPages) == true ? 0 : totalPages;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };

  
  const handleUnPublish = async (id, suggested_price) => {
    setCircularProgress(true);
    // await postApiData()

    await postApiData("product/update_products_by_google_unpublish", {
      id: id,
      price: suggested_price,
    });
    setCircularProgress(false);

     
    dispatch(
      googleData({
        page: currentPage,
        searchQuery: searchQuery,
        searchQuerySupplier: searchQuerySupplier,
        searchQuerySupplierTitle: searchQuerySupplierTitle,
        selectedFilters: selectedFilters,
      })
    );



  };




  const handlePublish = async (id, suggested_price) => {
    setCircularProgress(true);
    // await postApiData()

    await postApiData("product/update_products_by_google", {
      id: id,
      price: suggested_price,
    });
    setCircularProgress(false);

    
    dispatch(
      googleData({
        page: currentPage,
        searchQuery: searchQuery,
        searchQuerySupplier: searchQuerySupplier,
        searchQuerySupplierTitle: searchQuerySupplierTitle,
        selectedFilters: selectedFilters,
      })
    );



  };

  return (
    <>
      {checkRights && checkRights == true ? (
        <>
          {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

          <ToasterAlert />
          <Header></Header>
          <MenuHeader></MenuHeader>

          {showModal ? (
            <RejectedProducts
              props={setShowModal}
              updateBit={setUpdateBit}
              className="overflow-y-auto"
            ></RejectedProducts>
          ) : null}

          <div className="mx-2 ">
            <div>
              <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                {pageSettings && pageSettings.title}
              </span>

              <div className="flex grid-col-2 gap-2">
                <div className="    ">
                  <span
                    className="py-4  text-base text-left text-gray-500 font-dmsans"
                    dangerouslySetInnerHTML={{
                      __html: pageSettings && pageSettings.desc,
                    }}
                  ></span>
                </div>

                <a
                  data-tooltip-id="info_tooltip"
                  data-tip
                  data-tooltip-place="top"
                  className="  whitespace-normal font-dmsans "
                >
                  <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
                </a>

                <Tooltip
                  id="info_tooltip"
                  style={customStyleToolTip}
                  className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
                >
                  {pageSettings && pageSettings.info ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: pageSettings.info }}
                    />
                  ) : (
                    <p></p>
                  )}
                </Tooltip>
              </div>
            </div>

            <div className="flex flex-col min-h-screen bg-transparent md:mt-2 mt-20">
              <div className="mx-4 sm:mx-8 mt-10 flex items-center justify-between">
                <label htmlFor="simple-search" className="sr-only">
                  Search
                </label>
                <div className="flex flex-col sm:flex-row gap-4 w-full max-w-screen-lg">
                  <div className="relative flex-grow">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search Google Products by Name"
                      className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 py-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                      <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-4 w-full max-w-screen-lg mx-2">
                  <div className="relative flex-grow">
                    <input
                      type="text"
                      value={searchQuerySupplier}
                      onChange={(e) => setSearchQuerySupplier(e.target.value)}
                      placeholder="Search Supplier's Name"
                      className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 py-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                      <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row gap-4 w-full max-w-screen-lg mx-2 ">
                  <div className="relative flex-grow">
                    <input
                      type="text"
                      value={searchQuerySupplierTitle}
                      onChange={(e) =>
                        setSearchQuerySupplierTitle(e.target.value)
                      }
                      placeholder="Search Google's Product Title"
                      className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 py-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                      <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                    </div>
                  </div>
                </div>
                <div className="relative flex-grow  p-2 mx-2">
                  <button
                    onClick={async () => {
                      setShowModal(true);
                    }}
                    type="submit"
                    className="w-full sm:w-auto border p-2 bg-maincolor text-white border-gray-300 text-md py-2 rounded-lg hover:bg-maincolor hover:text-white"
                  >
                    Rejected Products
                  </button>
                </div>
              </div>

              <div className="relative mx-2">
                <div className="flex flex-col">
                  <label className="text-sm mb-1 text-gray-500">
                    Red Flag Products:
                  </label>
                  <select
                    value={selectedFilters.filtered}
                    onChange={(e) => handleFilter(e.target.value)}
                    className="scrollbar scrollbar-thumb-maincolor block w-full sm:w-56 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-maincolor focus:border-maincolor"
                  >
                    {optionElementsFilter}
                  </select>
                </div>
              </div>

              <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                <div className="p-8 md:p-14">
                  <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                    Google Products List
                  </span>

                  <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>

                          {name &&
                            name.map((column, index) => {
                              switch (column) {
                                case "LOWEST PRICE WITH STORE":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      lowest price with store
                                    </th>
                                  );

                                case "STOCK":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                                  );
                                case "HARD STOP RULE":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      hard stop rule
                                    </th>
                                  );

                                case "SUGGESTED PRICE (WITHOUT VAT)":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      suggested Price (Without VAT)
                                    </th>
                                  );
                                case "PUBLISH":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      Publish
                                    </th>
                                  );

                                  case "UN PUBLISHED":
                                    return (
                                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Un-Publish
                                      </th>
                                    );


                                case "COMPETITORS":
                                  return (
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                      COMPETITORS
                                    </th>
                                  );
                                default:
                                  return null;
                              }
                            })}
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {googleProducts.googleProducts.googleProducts &&
                          googleProducts.googleProducts.googleProducts.map(
                            (item, index) => (
                              <tr key={index}>
                                <td className="flex w-full whitespace-normal">
                                  <div className="text-sm  font-dmsans font-medium text-gray-900 w-max">
                                    <table className="w-full divide-y divide-gray-200">
                                      <thead className="bg-gray-50  w-full">
                                        <tr className="bg-gray-50">
                                          <th className="px-6 py-3  text-left text-xs flex w-full  font-semibold font-dmsans text-gray-500 uppercase tracking-wider">
                                            Xinix Co
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody className=" bg-white divide-y divide-gray-200 ">
                                        <tr>
                                          <td>
                                            <div className="text-sm font-normal text-gray-900">
                                              <img
                                                className=" w-20 h-20"
                                                src={
                                                  item.productDetails.image_url
                                                }
                                              ></img>
                                            </div>
                                          </td>

                                          <div className="text-maincolor font-dmsans font-normal">
                                            <div
                                              className="text-sm font-semibold text-gray-500 font-dmsans"
                                              style={{
                                                whiteSpace: "normal",
                                              }}
                                            >
                                              {item.productDetails.name}
                                            </div>
                                          </div>

                                          <div className="mt-9 font-dmsans font-normal text-maincolor">
                                            <div className="font-dmsans  text-maincolor  font-normal">
                                              {item.productDetails.category}
                                            </div>

                                            <div className="font-dmsans  text-maincolor  font-normal">
                                              {item.productDetails.distributor}
                                            </div>

                                            <div className="font-dmsans  text-maincolor  font-normal">
                                              {item.productDetails.sku}
                                            </div>

                                            <div className="font-dmsans font-bold text-md mt-2  text-gray-500">
                                              <div className="flex grid-col-2 gap-2">
                                                <div className="text-maincolor font-dmsans font-semibold">
                                                  Buy Price:
                                                </div>
                                                <div>
                                                  {"£"}
                                                  {item.productDetails.price.toFixed(
                                                    2
                                                  )}
                                                </div>
                                              </div>

                                              <div className="flex grid-col-2 gap-2">
                                                <div className="text-maincolor font-dmsans font-semibold">
                                                  Buy Price with Margin(Markup):
                                                </div>
                                                <div>
                                                  {"£"}

                                                  {item.productDetails
                                                    .markup_price == 0
                                                    ? item.productDetails
                                                        .price &&
                                                      item.productDetails.price.toFixed(
                                                        2
                                                      )
                                                    : item.productDetails
                                                        .markup_price &&
                                                      item.productDetails.markup_price.toFixed(
                                                        2
                                                      )}
                                                </div>
                                              </div>
                                            </div>

                                            <div className="font-bold text-xl ">
                                              <div className="flex grid-col-2 gap-2">
                                                <div className="font-dmsans  font-semibold  text-maincolor ">
                                                  With Markup and VAT:
                                                </div>
                                                <div className="font-dmsans   text-gray-500">
                                                  {"£"}
                                                  {item.vat.toFixed(2)}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </td>

                                {name &&
                                  name.map((column, index) => {
                                    switch (column) {
                                      case "LOWEST PRICE WITH STORE":
                                        return (
                                          <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="font-medium text-md  text-maincolor font-dmsans ">
                                              <div className="flex grid-col-2 gap-2 ">
                                                {"£"}

                                                {item.min_price.toFixed(2)}

                                                <div className="">
                                                  {item.min_supplier}
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                        );

                                      case "HARD STOP RULE":
                                        return (
                                          <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                              {item.hardstopRule} {"%"}
                                            </div>
                                          </td>
                                        );

                                      case "SUGGESTED PRICE (WITHOUT VAT)":

                                        return (
                                          <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm font-medium text-gray-900">
                                              <input
                                                id={`suggestedPrice${
                                                  item.suggested_price &&
                                                  item.suggested_price.toFixed(
                                                    2
                                                  )
                                                }`}
                                                className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                                                defaultValue={
                                                  item.suggested_price &&
                                                  item.suggested_price.toFixed(
                                                    2
                                                  )
                                                }
                                                type="number"
                                                min={0}
                                              ></input>
                                            </div>
                                          </td>
                                        );
                                        case "UN PUBLISHED":
                                          return (
                                            <td>
                                              <button
                                                className={`flex items-center justify-center w-auto border h-12 ${
                                                  !item.isPublished 
                                                    ? "bg-gray-300 text-gray-500 bg-text cursor-not-allowed"
                                                    : "bg-textColor text-white hover:bg-textColor hover-text-white"
                                                } border-gray-300 text-md p-2 rounded-lg mb-6`}
                                                //    className="flex items-center justify-center w-auto border h-12 bg-white  text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                                                onClick={(e) => {
                                                  if (
                                                    item.isPublished
                                                    
                                                  ) {
                                                    {
                                                      handleUnPublish(
                                                        item.product_id,
                                                
                                                      );
                                                    }
                                                  }
                                                }}
                                              >
                                                <MdUnpublished  className="text-maincolor text-xl" />
                                              </button>
                                            </td>
                                          );

                                          


                                      case "PUBLISH":
                                        return (
                                          <td>
                                            <button
                                              className={`flex items-center justify-center w-auto border h-12 ${
                                                item.isPublished ||
                                                item.redFlag == true
                                                  ? "bg-gray-300 text-gray-500 bg-text cursor-not-allowed"
                                                  : "bg-textColor text-white hover:bg-textColor hover-text-white"
                                              } border-gray-300 text-md p-2 rounded-lg mb-6`}
                                              //    className="flex items-center justify-center w-auto border h-12 bg-white  text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                                              onClick={(e) => {
                                                if (
                                                  !item.isPublished &&
                                                  item.redFlag == false
                                                ) {
                                                  {
                                                    handlePublish(
                                                      item.product_id,
                                                      item.suggested_price
                                                    );
                                                  }
                                                }
                                              }}
                                            >
                                              <CiDeliveryTruck className="text-maincolor text-xl" />
                                            </button>
                                          </td>
                                        );

                                      case "COMPETITORS":
                                        return (
                                          <td className="px-6 py-4 whitespace-nowrap ">
                                            <div className="text-sm font-dmsans font-medium text-gray-900">
                                              <Slider {...settings}>
                                                <table className="min-w-full divide-y divide-gray-200    ">
                                                  <thead className="bg-gray-50">
                                                    <tr>
                                                      {item.AllSuppliers.map(
                                                        (Initem, index) => (
                                                          <th
                                                            className="px-6 py-3 text-left text-xs  font-semibold font-dmsans text-gray-500 uppercase tracking-wider"
                                                            key={index}
                                                          >
                                                            {Initem}
                                                          </th>
                                                        )
                                                      )}
                                                    </tr>
                                                  </thead>

                                                  <tbody className=" bg-white divide-y divide-gray-200 ">
                                                    {item.AllData.map(
                                                      (AllInData, index) => (
                                                        <td
                                                          className="px-6 py-4 whitespace-nowrap  border border-solid"
                                                          key={index}
                                                        >
                                                          <tr>
                                                            <div className="text-sm font-normal text-gray-900">
                                                              <img
                                                                className=" w-20 h-20"
                                                                src={
                                                                  AllInData.thumbnail
                                                                }
                                                              ></img>
                                                            </div>

                                                            <div className="text-maincolor font-dmsans font-normal">
                                                              <div
                                                                className="text-sm font-semibold text-gray-500 font-dmsans"
                                                                style={{
                                                                  whiteSpace:
                                                                    "normal",
                                                                }}
                                                              >
                                                                {
                                                                  AllInData.title
                                                                }
                                                              </div>
                                                            </div>

                                                            <div className="mt-9 font-dmsans font-normal text-maincolor">
                                                              <div className="font-dmsans  text-maincolor  font-normal">
                                                                {AllInData.product_rating
                                                                  ? `Product Rating: ${AllInData.product_rating}`
                                                                  : ""}
                                                              </div>

                                                              <div className="font-dmsans  text-maincolor  font-normal">
                                                                {AllInData.product_reviews
                                                                  ? `Product Reviews: ${AllInData.product_reviews}`
                                                                  : ""}
                                                              </div>

                                                              <div className="font-dmsans  text-maincolor  font-normal">
                                                                {AllInData.store_rating
                                                                  ? `Store Rating: ${AllInData.store_rating}`
                                                                  : ""}
                                                              </div>

                                                              <div className="font-dmsans  text-maincolor  font-normal">
                                                                {AllInData.store_reviews
                                                                  ? `Store Reviews: ${AllInData.store_reviews}`
                                                                  : ""}
                                                              </div>
                                                              <div className=" flex grid-col-2 border border-solid  justify-center py-4 ">
                                                                <p className=" font-dmsans font-normal  justify-center text-center mx-2 mt-4">
                                                                  Product
                                                                  Similarity:
                                                                </p>

                                                                <CircularProgressbar
                                                                  styles={buildStyles(
                                                                    {
                                                                      rotation: 0.25,
                                                                      strokeLinecap:
                                                                        "butt",
                                                                      textSize:
                                                                        "20px",
                                                                      pathTransitionDuration: 0.5,
                                                                      pathColor: `rgba(245, 98, 28, ${
                                                                        AllInData.similarity /
                                                                        100
                                                                      })`,
                                                                      textColor:
                                                                        "#f5621c",
                                                                      trailColor:
                                                                        "#d6d6d6",
                                                                      backgroundColor:
                                                                        "red",
                                                                    }
                                                                  )}
                                                                  className=" flex  w-full justify-center "
                                                                  value={
                                                                    AllInData.similarity &&
                                                                    AllInData.similarity.toFixed(
                                                                      2
                                                                    )
                                                                  }
                                                                  text={` ${
                                                                    AllInData.similarity &&
                                                                    AllInData.similarity.toFixed(
                                                                      2
                                                                    )
                                                                  }%`}
                                                                />
                                                              </div>

                                                              <div className="flex  grid-col-2 gap-2 justify-between ">
                                                                <p
                                                                  className={`w-fit  justify-start text-md p-2 rounded-lg mb-6  ${
                                                                    AllInData.disable
                                                                      ? "text-gray-400"
                                                                      : "text-maincolor"
                                                                  }`}
                                                                >
                                                                  Add to
                                                                  Rejection
                                                                  List:
                                                                </p>

                                                                <button
                                                                  disable={AllInData.disable.toString()}
                                                                  disabled={
                                                                    AllInData.disable
                                                                  }
                                                                  onClick={() => {
                                                                    handleRejection(
                                                                      AllInData.title,
                                                                      item.productDetails.name
                                                                    );
                                                                  
                                                                  }}
                                                                  className={`w-fit text-md p-2 rounded-lg mb-6 justify-end ${
                                                                    AllInData.disable
                                                                      ? "text-gray-400"
                                                                      : "text-maincolor"
                                                                  }`}
                                                                >
                                                                  <TbPlayerEjectFilled />
                                                                </button>
                                                              </div>
                                                              {AllInData.store_link ? (
                                                                <div>
                                                                  <div className="flex grid-row-2 gap-2">
                                                                    {
                                                                      "Store Link :"
                                                                    }

                                                                    <Link
                                                                      to={
                                                                        AllInData.store_link
                                                                      }
                                                                      target="_blank"
                                                                    >
                                                                      <BiLinkExternal />
                                                                    </Link>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                ""
                                                              )}
                                                            </div>
                                                            <div className="font-dmsans  text-maincolor  font-normal">
                                                              {AllInData.delivery
                                                                ? `Delivery: ${AllInData.delivery}`
                                                                : ""}
                                                            </div>

                                                            <div className="font-dmsans font-bold text-3xl mt-2  text-gray-500">
                                                              {"£"}

                                                              {AllInData.price}
                                                            </div>
                                                          </tr>
                                                        </td>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </Slider>
                                            </div>
                                          </td>
                                        );
                                    }
                                  })}
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className=" flex justify-end mb-10">
                <div className="flex flex-col ">
                  <div className="flex flex-1 mt-2">
                    <button
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
                    >
                      <AiFillCaretLeft />
                    </button>
                    <div className=" ">{getPages()}</div>

                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
                    >
                      <AiFillCaretRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : checkRights ? (
        <ScreenRights></ScreenRights>
      ) : (
        <></>
      )}
    </>
  );
};

export default GoogleProducts;
