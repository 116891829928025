import React from "react";
import { useMemo, useCallback,useLayoutEffect } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { distributorData } from "../helpers/AuthStore/distributorSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { GiCrossMark } from "react-icons/gi";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import { CircularLoader } from "../Container/CircularLoader";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import postApiData from "../helpers/postApiData";
import DistributorUpdate from "./DistributorUpdate";
import moment from "moment";
import usePageSetting from "../utils/usePageSetting";

import { AiFillInfoCircle } from "react-icons/ai";

import { Tooltip } from "react-tooltip";
import ScreenRights from "../utils/ScreenRights";
import { customStyleToolTip } from "../utils/customStyleToolTip";



import PermsTab from "./PermsTab";

const Distributor = () => {
  const dispatch = useDispatch();
  let distributor = useSelector((state) => state.distributor);

  const [showModal, setShowModal] = useState(false);
  const  [CircularProgress,setCircularProgress] = useState(false);

  const [distributorState, setdistributorState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  

  const [selectedItems, setSelectedItems] = useState([]);

  const itemsPerPage = 10;


  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
  const screen_name = '/distributors';
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);


  const filteredScreen = useMemo(
    () =>
      distributor.distributors?.distributor
        ? distributor.distributors.distributor.filter((item) =>
            item.name != null
              ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
              : "No Name"
          )
        : [],
    [distributor.distributors?.distributor, searchQuery]
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = useMemo(
    () => filteredScreen.slice(indexOfFirstItem, indexOfLastItem),
    [filteredScreen, indexOfFirstItem, indexOfLastItem]
  );

  const totalPages = Math.ceil(filteredScreen.length / itemsPerPage);

  const handlePageChange = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);
  const handleCheckboxChange = (e, itemId) => {
    const isChecked = e.target.checked;
  
    if (isChecked) {
     
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, itemId]);
    } else {
      
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((id) => id !== itemId)
      );
    }
  };
  // useEffect(() => {

  //   if (distributor.distributors && distributor.distributors.distributor) {
  //     const allPublish = distributor.distributors.distributor.every(
  //       (item) => item.publish
  //     );

  //     // setPublish(allPublish);

  //   }
  // }, [distributor, dispatch]);




  const handleSavePublish = async () => {


    if (selectedItems.length)
    {
        setCircularProgress(true);
      await postApiData("product/publish_all", { value: "publish" ,selectedItems});
      
      
      setCircularProgress(false);


    }






    

    



  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    dispatch(distributorData());
  }, [showModal]);

  const handleUpdateClick = async (item) => {
    setdistributorState(item);

    setShowModal(true);
  };
  const handleSelectAll = (selectAll) => {
    if (selectAll) {
      // Select all items
      const allItemIds = distributor.distributors?.distributor.map(
        (item) => item._id
      );
      setSelectedItems(allItemIds);
    } else {
      // Select none
      setSelectedItems([]);
    }
  };
  
  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };


  return (
<>

   { checkRights && checkRights == true ? ( <>
    


      <Toaster
        toastOptions={{
          duration: 1000,
          className: "",
          success: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
            },
          },
          error: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
              color: "#f5621c",
            },
          },
        }}
        position="top-center"
        reverseOrder="false"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    className="close-icon"
                    onClick={() => {
                      toast.dismiss(t.id);
                    }}
                  ></button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
      <PermsTab></PermsTab>
      <Header></Header>
      <MenuHeader></MenuHeader>

   
      { CircularProgress == true ? 


<CircularLoader></CircularLoader>:<></>

}

      {showModal ? (
        <DistributorUpdate
          distributorState={distributorState}
          props={setShowModal}
          distributorData={distributorData}
          isCustomer={false}
        ></DistributorUpdate>
      ) : null}

      <div className="mx-2">
        
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
             {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}
              </Tooltip>
            </div>
            </div>


        <div className="flex flex-col min-h-auto bg-transparent">
          <div className="p-4">
            <div className="flex  justify-normal">
              <button
                onClick={() => handleSelectAll(true)}

                className="flex items-center justify-center w-full sm:w-auto  text-xs md:text-sm sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-3 sm:mb-0 hover:bg-maincolor hover-text-white"
              >
                Select All For Publish
                <GiCheckMark></GiCheckMark>
              </button>
              <button
                  onClick={() => handleSelectAll(false)}
                className="flex items-center justify-center w-full sm:w-auto text-xs md:text-sm sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-3 sm:mb-0 hover:bg-maincolor hover-text-white"
              >
                Select None For Publish
                <GiCrossMark className="ml-2"></GiCrossMark>
              </button>

              <button
                onClick={handleSavePublish}
                className="flex  font-dmsans items-center justify-center  text-xs md:text-sm w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Save
                <GiCheckMark></GiCheckMark>
              </button>
            </div>
          </div>
        </div>

        <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
          <div className="absolute flex-shrink-0 items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Distributor"
                className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
              </div>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
          <div className="p-8 md:p-14">
            <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
              Distributor List
            </span>

            <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Publish
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>

                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Cost Per Uplift
                    </th>

                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Total Products
                    </th>
                    <th className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentItems.map((item) => (
                    <tr key={item._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                        <input
  type="checkbox"
  value={item._id}
  onChange={(e) => handleCheckboxChange(e, item._id)}
  checked={selectedItems.includes(item._id)}
/>

                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.name}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {moment(item.updated).format("YYYY-MM-DD HH:mm:ss")}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 flex items-center">
                          {item.cost_per_uplift} {item.currency}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 flex items-center">
                          {item.count}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() => {
                            handleUpdateClick(item);
                          }}
                          className="text-extra-color hover:extra-color"
                        >
                          <AiOutlineEdit />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className=" flex justify-end mb-10">
          <div className="flex flex-col ">
            <div className="flex flex-1 mt-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
              >
                <AiFillCaretLeft />
              </button>
              <div className=" ">{getPages()}</div>

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
              >
                <AiFillCaretRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      </> ):
      
      checkRights  ? 
      <ScreenRights></ScreenRights> : <></>}

                        
      </>

  );
};

export default Distributor;
