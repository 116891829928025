import React, { useState } from "react";

const AlphabeticFilters = ({ selectedFilter, onFilterChange }) => {
  const alphabets = [...Array(26)].map((_, i) => String.fromCharCode(65 + i));

  return (
    <div className="flex flex-wrap justify-start mb-4">
    {alphabets.map((alphabet) => (
      <button
        key={alphabet}
        className={`mx-1  mb-1 px-2 py-1 rounded  font-normal font-dmsans ${
          selectedFilter === alphabet
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
        
        onClick={() => onFilterChange(alphabet)}
      >
        {alphabet}
      </button>
    ))}
  </div>
  
  );
};

export default AlphabeticFilters;
