import {configureStore} from '@reduxjs/toolkit';
import authSlice from './AuthStore/authSlice';
import menuSlice from './MenuStore/menuSlice';
import screenSlice from './ScreenStore/screenSlice';
import userSlice from './UserStore/userSlice';
import authUserSlice  from './UserStore/authUserSlice';
import productSlice from './AuthStore/productSlice';
import categorySlice from './AuthStore/categorySlice';
import distributorSlice from './AuthStore/distributorSlice';
import markupSlice  from './AuthStore/markupSlice';
import brandSlice from './AuthStore/brandSlice';
import customerMarkupSlice from './AuthStore/customerMarkupSlice';
import productPricingSlice from './AuthStore/productPricingSlice';
import rmarkupSlice from './AuthStore/rmarkupSlice';
import resellerSlice from './AuthStore/resellerSlice';
import plightSlice from './AuthStore/plightSlice';
import productHistorySlice from './AuthStore/productHistorySlice';
import googleSlice from './AuthStore/googleSlice';
import pageSettingSlice from './SettingStore/pageSettingSlice';
import menuSettingSlice from './MenuStore/menuSettingSlice';
import metricsSlice from './AuthStore/metricsSlice';
const authStore  = configureStore({
    
    reducer:{
        auth:authSlice,
        menu:menuSlice,
        screen:screenSlice,
        user:userSlice,
        authuser:authUserSlice,
        product:productSlice,
        category:categorySlice,
        distributor:distributorSlice,
        markup:markupSlice,
        brand:brandSlice,
        customer_markup:customerMarkupSlice,
        product_pricing:productPricingSlice,
        rmarkup:rmarkupSlice,
        reseller:resellerSlice,
        plight:plightSlice,
        productHistory:productHistorySlice,
        googleProducts:googleSlice,
        pageSetting:pageSettingSlice,
        menuSettings:menuSettingSlice,
        metrics:metricsSlice,


    }

});

export default authStore;