import React, { useLayoutEffect } from "react";
import StaffImage from "../../assets/user-icon.png";
import WholeSellerImage from "../../assets/wholesale.png";
import AdminImage from "../../assets/admin.png";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    return () => {
      if (localStorage.getItem("user_data")) {
        navigate("/dashboard", { replace: true  });
      }
    };  
  });
  return (
    <React.Fragment>
      <div className="bg-maincolor text-white py-4 px-8 flex items-center">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="h-fit w-fit text-gray-300 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          ></img>
        </Link>
      </div>

      <div className="flex flex-col justify-center p-8 md:p-14 ">
        <span className="mb-3 text-4xl font-bold font-dmsans text-subheading-400  text-maincolor">
          Login in Stock Channel
        </span>
        <span className="py-4 text-md  text-center text-gray-500 font-dmsans">
          Choose one Option
        </span>
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="hero container max-w-screen-lg mx-auto pb-24 flex justify-center items-center">
              <img
                src={StaffImage}
                alt="User Admin"
                className="inline-table w-fit h-auto rounded-full mr-4"
              />
            </div>

            <div className=" items-center mb-4">
              <h2 className="text-2xl font-normal text-center justify-center font-dmsans hover:text-maincolor">
                <Link to="/login" state="Staff">
                  Staff Login
                </Link>
              </h2>
            </div>
          </div>
          <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="hero container max-w-screen-lg mx-auto pb-10 flex justify-center items-center">
             
              <img
                src={WholeSellerImage}
                alt="Whole Sellers"
                className="inline-table w-fit h-auto mr-4"
              />
            </div>
            <div className=" items-center mb-4">
              <h2 className="text-2xl font-normal text-center justify-center font-dmsans hover:text-maincolor">
                <Link to="/login" state="Whole Seller">
                  Whole Sale Login
                </Link>
              </h2>
            </div>
          </div>
          <div className=" rounded-lg shadow-lg p-8 ">
          <div className="hero container max-w-screen-lg mx-auto pb-32 flex justify-center items-center">
             
              <img
                src={AdminImage}
                alt="Admin"
                className=" inline-table  h-auto   "
              />
            </div>

            <div className=" relative items-center  mb-auto">
              <h2 className="text-2xl font-normal text-center justify-center font-dmsans hover:text-maincolor ">
                <Link to="/login" state="Admin">
                  Admin
                </Link>

              </h2>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Home;
