import React, { useState, useMemo } from "react";

import useToggle from "../utils/useToggle";
import {ValidationAuthPageSettingsCreate} from '../utils/validateAPI';
import GetApiData from "../helpers/getApiData";
import { useEffect } from "react";
import { useFormik } from "formik";
import "quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const CreatepageSettings = ({ props, UserState }) => {
  const [options, setOptions] = useState([]);
  const [optionsScreen, setOptionsScreen] = useState([]);

  const [userName, setuserName] = useState("");
  const [screenName, setScreenName] = useState("");
  const [desc, setDesc] = useState("");
  const [info, setInfo] = useState("");
  const [t,sett] = useState("");
  
  const formik = useFormik({
    initialValues: {
      title:t,
      desc:"",
      info:"",
      screen_name: screenName == "" ? "" : screenName,

    },
   validate: ValidationAuthPageSettingsCreate,


    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      props(false);
      return false;
    },
  });
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];
  
  useEffect(()=>{


    formik.setValues({
  
          desc:desc,
          info:info,
          
          screen_name:screenName,
          title:t
,          
    })

  },[desc,info])
  useEffect(() => {
    async function fetchData() {
      const data = await GetApiData("auth/get_user", "");
      let results = [];

      data.user.map((value) => {
        results.push({
          key: value._id,
          value: value.username,
        });
      });

      setOptions([...results]);

      const screendata = await GetApiData("screen/get_screen", "");
      const screenResults = [];

      screendata.screen.map((value) => {
        screenResults.push({
          key: value._id,
          value: value.screen_name,
        });
      });

      setOptionsScreen([...screenResults]);
    }

    fetchData();
  }, [UserState]);
  useToggle(false);

  const optionElements = useMemo(() => {
    if (options[0]) {
      setuserName(options[0].value);

      formik.values.username = options[0].value;
    }

    return options.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [options, userName]);

  const optionElementScreen = useMemo(() => {
    if (optionsScreen[0]) {
      setScreenName(optionsScreen[0].value);

      formik.values.screen_name = optionsScreen[0].value;
    }

    return optionsScreen.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsScreen, screenName]);

  return (
    <React.Fragment>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="container mx-auto">
          <div className="flex items-center  justify-center min-h-User  bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
              <div className="flex flex-col justify-center p-8 md:p-14 ">
                <div className="flex items-end  justify-end  p-0 ">
                  <button
                    className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => {
                      props(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor ">×</span>
                  </button>
                </div>

                <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                  Create Settings of Page
                </span>

                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="py-4">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Select Screen
                    </span>
                    <select
                      {...formik.getFieldProps("screen_name")}
                      value={formik.values.screen_name}
                      id="screen_name"
                      className="bg-gray-50 border focus:outline-none focus:ring-0 border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      {optionElementScreen}
                    </select>
                  </div>
                  <div className="py-4 ">
  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
    Title
  </span>
  <input
  
    id="title"
    type="text"
    onChange={(e) => {
      
      sett(e.target.value);
    
    }}
    placeholder="Screen Title"
    className="w-full placeholder:font-dmsans border-b p-2 focus:outline-none text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
  />
  <label className="relative left-0 top-1 cursor-text"></label>
</div>

                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Description
                    </span>

                    <ReactQuill
                      theme="snow"
                    
                      id="desc"
                      type="text"
                      
                      onChange={setDesc}
                      
                      modules={modules}
                      formats={formats}
                      placeholder="Descriptiion"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      style={{ height: "" }}
                    ></ReactQuill>

                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Information About Page
                    </span>

                    <ReactQuill
                      theme="snow"

                      id="info"
                      type="text"
                      onChange={setInfo}

                      modules={modules}
                      formats={formats}
                      placeholder="Information"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      style={{ height: "" }}
                    ></ReactQuill>

                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="py-4 ">
                    <button
                      type="submit"
                      className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                    >
                      Create Settings
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </React.Fragment>
  );
};

export default CreatepageSettings;
