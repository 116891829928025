import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const menuSettingsData = createAsyncThunk(
  "menuSettings",

  async (payload, { rejectWithValue }) => {
    try {
    

    const request = await axios.get(env.API_URL + "menu/get_menu");
    
    const response = await request.data;
    return response;
    

   
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message); 
    }
  }
);

const menuSettingSlice = createSlice({
  name: "menuSettings",
  initialState: {
    menus : [],
    loading: false,
    error: null,
  },
  reducers: {
    addMenuSettings: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(menuSettingsData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(menuSettingsData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(menuSettingsData.fulfilled, (state, action) => {
          state.loading = false;
          state.menus = action.payload;
          
        }
      );
  },
});
export const { addMenuSettings } = menuSettingSlice.actions;
export default menuSettingSlice.reducer;


