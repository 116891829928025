import React, { useEffect,useRef } from "react";

import { useMemo, useState, useCallback } from "react";
import { useFormik } from "formik";
import { CreateCategoryValidator } from "../utils/validateAPI";
import GetApiData from "../helpers/getApiData";

import Select from "react-select";
import getApiData from "../helpers/getApiData";
import { useNavigate } from "react-router-dom";

const CategoryAdd = ({
  props,
  modal,
  optionsRoot,
  updateProp,
  proper,
  root_id,
}) => {
  const [selectedOptions, setSelectedOptions] = useState();
  const [selectedRoot, setselectedRoot] = useState(optionsRoot[0].label);
  const [optionsSub, setoptionsSub] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [retain, setRetain] = useState(false);
  const useRetain = useRef();
  const selectInputRef = useRef(null);
  const [selectKey, setSelectKey] = useState(Date.now()); 

const navigate = useNavigate();


  const formik = useFormik({
    initialValues: {
      category: "",
      bid: "root",
    },

    

    validate: CreateCategoryValidator,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      modal(false);
      updateProp(!proper);

      return false;
    },
  });

  
  
  useEffect(()=>{const role = JSON.parse(localStorage.getItem("user_data")).role;if (role == "reseller" || role == "user") {navigate("/dashboard")}},[]);

  useEffect(() => {
    async function fetchData() {
  
      const dataSub = await GetApiData(
        "product/get_filtered_category" + "/" + selectedRoot,
        ""
      );


      
      const resultsSub = [];
      dataSub &&
        dataSub.cat_list.map((value) => {
          return resultsSub.push({
            value: value._id,
            label: value.name,
          });
        });

      setoptionsSub([...resultsSub]);
    }
    fetchData();
  }, [selectedRoot]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDark ? "#1f2937" : "white",
      borderColor: "#d1d5db",
      borderRadius: "4px",
      fontSize: "14px",
      padding: "1px",
      boxShadow: state.isFocused ? "0 0 0 0 #f5621c" : "none",
      "&:hover": {
        borderColor: "#f5621c",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#f5621c", // imported color string
        height: "50px",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f5621c" : "white",
      color: state.isFocused ? "white" : "black",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "#f5621c",
      borderRadius: "4px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
      fontSize: "14px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "white",
      "&:hover": {
        backgroundColor: "#f99567",
        color: "white",
      },
    }),
  };

  const formikNew = useFormik({
    initialValues: {
      root: selectedRoot,
      bid: "sub",
      child: selectedOptions,
      retain: "",
    
     
    },
    validate: CreateCategoryValidator,
    validateOnBlur: false,
    validateOnChange: false,
    
    onSubmit: async (values) => {

 
      values = await Object.assign(values);

      
      modal(false);

      updateProp(!proper);

      return false;
    },
  });




  function handleSelectRoot(event) {
    setselectedRoot(event.target.value);
    setSelectedOptions([]);
    setSelectKey(Date.now()); 
   

    root_id(event.target.value);
  }

  function handleSelect(selected) {
    setSelectedOptions(selected);

  }

  useEffect(() => {
    formikNew.setFieldValue("child", selectedOptions);
    formikNew.setFieldValue("root", selectedRoot);
    
    
  }, [selectedOptions, selectedRoot]);

  const optionElements = useMemo(() => {
    return (
      optionsRoot &&
      optionsRoot.map((option) => (
        <option
          key={option.value}
          className="scrollbar-thumb-maincolor"
          value={option.label}
        >
          {option.label}
        </option>
      ))
    );
  }, [optionsRoot]);

  const optionElementsSub = useMemo(() => {
    return (
      optionsSub &&
      optionsSub.map((option) => (
        <option
          key={option.value}
          className="scrollbar-thumb-maincolor "
          value={option.label}
        >
          {option.label}
        </option>
      ))
    );
  }, [optionsSub]);

  const handleConfirmation = useCallback(
     (confirmed) => {
      if (confirmed) {

        setRetain((prevRetain) => true);
        
     
          useRetain.current = true;
   
         formikNew.setFieldValue("retain", true);
         formikNew.setValues("retain", true);
        
         formikNew.values.retain = true;

        
        setShowConfirmation(false);
      } else {

        
        setRetain(false);

        
        formikNew.setFieldValue("retain", false); 
        formikNew.values.retain = false;
        formikNew.handleSubmit();

        setShowConfirmation(false);
      }


      formikNew.handleSubmit();


    }
  );

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();


    const values = [];

for(let i=0;selectedOptions && i<selectedOptions.length;i++) {
        
        const val = await GetApiData("product/has_parent/"+selectedOptions[i].value,"");
        
         values.push(val.value);

    }


    const hasTrueValue = values.some((value) => value === true);
    
    
  if (hasTrueValue) {
 
    setShowConfirmation(true);
  
  
  } else {
    setRetain(false);   
   
  formikNew.handleSubmit();
  return;
  }

  }, [selectedOptions]);

  return (
    <>
      {showConfirmation && (
        <div className="justify-center z-50 items-center scrollbar scrollbar-thumb-maincolor overflow-y-auto fixed inset-0  outline-none focus:outline-none">
          <div className="container mx-auto">
            <div className="flex items-center  justify-center min-h-screen  bg-transparent">
              <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
                <div className="flex flex-col justify-center p-8 md:p-14 ">
                  <button
                    className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => {
                      setShowConfirmation(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor ">×</span>
                  </button>

                  <div className="  z-auto flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg">
                      <p className="mb-4  font-dmsans text-maincolor">
                        You want to change the markup or place global markup?
                      </p>
                      <div className="flex justify-end">
                        <button
                          className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                          onClick={() => handleConfirmation(true)}
                        >
                          Yes
                        </button>
                        <button
                          className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                          onClick={() => handleConfirmation(false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="justify-center items-center scrollbar scrollbar-thumb-maincolor overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
        <div className="container mx-auto">
          <div className="flex items-center  justify-center min-h-screen  bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
              <div className="flex flex-col justify-center p-8 md:p-14 ">
                <button
                  className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                  onClick={() => {
                    modal(false);
                  }}
                >
                  <span className="bg-transparent text-maincolor ">×</span>
                </button>




                <div className="flex items-start  justify-start  p-0 ">
                  <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                    Add Category
                  </span>
                </div>
                {props == false ? (
                  <form className="py-1" onSubmit={formik.handleSubmit}>
                    <div className="py-4 ">
                      <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                        Category Name
                      </span>
                      <input
                        {...formik.getFieldProps("name")}
                        id="name"
                        name="name"
                        type="text"
                        className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                        placeholder="Category Name"
                      ></input>
                      <label className="relative left-0 top-1 cursor-text"></label>
                    </div>
                    <div className="py-4 ">
                      <button
                        type="submit"
                        className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                      >
                        Add Category
                      </button>
                    </div>
                  </form>
                ) : (
                  <>
                    <form className="py-1" onSubmit={handleSubmit}>
                      <div className="py-4 ">
                        <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                          Category Name
                        </span>

                        <select
                          {...formikNew.getFieldProps("root")}
                          value={selectedRoot}
                          onChange={handleSelectRoot}
                          onSelect={handleSelectRoot}
                          onSelectCapture={handleSelectRoot}
                          onLoad={handleSelectRoot}
                          selected={selectedRoot}
                          
                          id="root"
                          name="root"
                          className="bg-gray-50 border  border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500 scrollbar scrollbar-thumb-maincolor"
                        >
                          {optionElements}
                        </select>
                      </div>
                      <div className="py-4 ">
                        <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                          Sub Category Name
                         
                        </span>
                        <Select
                          key={selectKey}
                          options={optionsSub}
                          isMulti
                          selectedOptions={selectedOptions}
                          onSelect = {handleSelect}
                          onChange={handleSelect}
                          styles={customStyles}
                          className=" rounded-full  focus:ring-maincolor focus:border-maincolor 
                  font-dmsans  block w-full p-2.5
                  
                   dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400
                    dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0 
                      dark:focus:border-blue-500  scrollbar-thumb-blue-500"
                        ></Select>
                      </div>

                      <button
                        type="submit"
                        className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                      >
                        Add Category
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryAdd;
