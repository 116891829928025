import React, { useState, useEffect } from "react";
import Tree from "./Tree";
import CategoryAdd from "./CategoryAdd";

import ToasterAlert from "../utils/ToasterAlert";
import GetApiData from "../helpers/getApiData";
import {CircularLoader} from "../Container/CircularLoader";
const CategoryMain = ({ categoryState,updateProp,proper }) => {
  const [showModal, setShowModal] = useState();
  const [subCat, setsubCat] = useState(false);
  const [treeData, setTreeData] = useState();
  const [optionsRoot, setOptions] = useState([]);
  const [optionsSub, setoptionsSub] = useState([]);

  const [selectedRoot,setselectedRoot]  = useState();
const [CircularProgress,setCircularProgress] = useState();


  useEffect(() => {
    async function fetchData() {
      const data = await GetApiData("product/get_categories", "");

      const results = [];
      data.category.map((value) => {
       return results.push({
          value: value._id,
          label: value.name,
        });
      });

      setOptions([...results]);
    

      const v = await GetApiData("product/get_tree_cat", "");
      
      setTreeData([...v.val]);
    }

    fetchData();
  }, [showModal]);

  useEffect(()=>{
   
    const fetchData = async function () {
      if (selectedRoot != undefined )
      {


      const dataSub = await GetApiData("product/get_filtered_category" + "/" + selectedRoot,"" );
          
      const resultsSub = [];
      dataSub && dataSub.cat_list.map((value) => {
        return resultsSub.push({
          value: value._id,
          label: value.name,
        });
      });

      setoptionsSub([...resultsSub]);
      
    }
      

    };
    fetchData();
  },[selectedRoot])


  useEffect(()=>
  {    async function fetchData() {

      setCircularProgress(true);
    const v = await GetApiData("product/get_tree_cat", "");
      
      setCircularProgress(false);
      setTreeData([...v.val]);
    }

    fetchData();


  },[proper]);
  const handleTreeClick = (event) => {
    
  };

  return (
    <>

<ToasterAlert></ToasterAlert>
      
      {CircularProgress == true ? <CircularLoader></CircularLoader>:<></>}
    
{ CircularProgress == false? 
      <div>
        {showModal ? (
          <CategoryAdd
            props={subCat}
            updateProp = {updateProp}
            proper = {proper}
            modal={setShowModal}
            optionsRoot={optionsRoot}
          
            root_id = {setselectedRoot}
          ></CategoryAdd>
        ) : null}


        <div className="flex min-h-auto bg-transparent border-2 border-maincolor     ">
          <div className="p-4">
            <div className="block justify-normal">
              <button
                onClick={() => {
                  setsubCat(false);
                  setShowModal(true);
                }}
                className="flex mx-2 font-dmsans w-auto items-center justify-center  border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Add Root Category
              </button>

              <button
                onClick={() => {
                  setsubCat(true);
                  setShowModal(true);
                }}
                className="flex mx-2 font-dmsans items-center justify-center w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Add Sub Category
              </button>

              <div></div>
            </div>
          </div>
        </div>

        <div className=" p-2 border-2 border-maincolor  w-fit">
          <Tree onClick={handleTreeClick} treeData={treeData && treeData} />

        </div>
      </div>:<></>
}
    </>
  );
};

export default CategoryMain;
