import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const resellerData = createAsyncThunk(
  "reseller",
  async (payload, { rejectWithValue }) => {
    try {

      const user_role = JSON.parse(localStorage.getItem("user_data")).role;
      const user_id = JSON.parse(localStorage.getItem("user_data"))._id;

      const queryString = encodeURIComponent(JSON.stringify(payload));
      const request = await axios.get(`${env.API_URL}product/reseller_get_all_products_records/${queryString}`);
    
      
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const resellerSlice = createSlice({
  name: "reseller",
  initialState: {
    resellers: [],
    loading: false,
    resellerCount:0,
    error: null,
  },
  reducers: {
    addReseller: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resellerData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(resellerData.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(resellerData.fulfilled, (state, action) => {
        state.loading = false;
        state.resellers = action.payload;
        
      });
  },
});

export const { addReseller } = resellerSlice.actions;
export default resellerSlice.reducer;
