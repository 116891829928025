import React, { useState, useMemo, useEffect } from "react";

import { useDrag, useDrop } from "react-dnd";
import { DndProvider } from "react-dnd";
import { toast, Toaster, ToastBar } from "react-hot-toast";

import { HTML5Backend } from "react-dnd-html5-backend";

import GetApiData from "../helpers/getApiData";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import postApiData from "../helpers/postApiData";
const DraggableElement = ({ id, name, index, moveElement }) => {
  const [, drag] = useDrag({
    type: "TABLE_ELEMENT",
    item: { id, index },
  });

  const [, drop] = useDrop({
    accept: "TABLE_ELEMENT",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveElement(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      className="flex items-center mx-auto align-middle justify-center whitespace-nowrap uppercase  max-w-md font-dmsans"
    >
      {name}
    </div>
  );
};

const TableSettings = () => {
  const [screenName, setScreenName] = useState();
  const [optionsScreen, setOptionsScreen] = useState([]);
  const [tableSettings, setTableSettings] = useState([]);
  const [screenId, setScreenId] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const screendata = await GetApiData(
        "page_settings/get_pageSettings_table",
        ""
      );
      const screenResults = [];
      screenResults.push({ key: 0, value: "" });
      screendata.pageSetting.map((value) => {
        screenResults.push({
          key: value._id,
          value: value.PageSettings.screen_name,
        });
      });

      setOptionsScreen([...screenResults]);
    }

    fetchData();
  }, []);

  const handleUpdateTable = async (e) => {
    const updateTable = await postApiData(
      "page_settings/update_table_settings",
      { _id: screenId, table: tableSettings }
    );
  };
  const optionElementScreen = useMemo(() => {
    return optionsScreen.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsScreen, screenName]);

  const handleChangeScreen = async (e) => {
    const tabledata = await GetApiData(
      "page_settings/get_pageSettings_table_value/" + e.target.value,
      ""
    );
    console.log(tabledata);
    if (tabledata && tabledata.pageSetting) {
      setTableSettings(tabledata.pageSetting[0].table);
      setScreenId(tabledata.pageSetting[0]._id);
    
    }
  };

  const moveElement = (fromIndex, toIndex) => {
    const updatedTableSettings = [...tableSettings];
    const [removedElement] = updatedTableSettings.splice(fromIndex, 1);
    updatedTableSettings.splice(toIndex, 0, removedElement);
    setTableSettings(updatedTableSettings);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        <Toaster
          toastOptions={{
            duration: 1000,
            className: "",
            success: {
              style: {
                border: "2px solid #f5621c",
                padding: "16px",
              },
            },
            error: {
              style: {
                border: "2px solid #f5621c",
                padding: "16px",
                color: "#f5621c",
              },
            },
          }}
          position="top-center"
          reverseOrder="false"
        >
          {(t) => (
            <ToastBar toast={t}>
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading" && (
                    <button
                      className="close-icon"
                      onClick={() => {
                        toast.dismiss(t.id);
                      }}
                    ></button>
                  )}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>

        <Header></Header>
        <MenuHeader></MenuHeader>
        <div className="container mx-auto ">
          <div className="py-4">
            <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
              Select Screen
            </span>
            <select
              id="screen_name"
              onChange={handleChangeScreen}
              className="bg-gray-50 border focus:outline-none focus:ring-0 border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {optionElementScreen}
            </select>
          </div>
        </div>
        <div>
  
        {tableSettings ? (
          <>
            <div className="p-4 mb-2">
              <button
                onClick={handleUpdateTable}
                className="flex items-center mx-auto   justify-center w-auto border  h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Update Table
              </button>
            </div>
            <div className="p-4 mb-2">
            {tableSettings.map((element, index) => (
              <div key={index} >
                <div className="flex  items-center mx-auto align-middle font-semibold  text-gray-500  border justify-center h-12 mt-1    whitespace-nowrap uppercase  border-maincolor      max-w-md   font-dmsans  ">
                  <DraggableElement
                    id={index}
                    name={element.name}
                    index={index}
                    moveElement={moveElement}
                  />
                </div>
              </div>
            ))}

              </div>


          </>

        ) : (
          <></>
        
        )
        

        }
        
        

        
        </div>
      </div>
    </DndProvider>
  );
};

export default TableSettings;
