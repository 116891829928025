import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";

import { metricsData } from "../helpers/AuthStore/metricsSlice";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useMemo } from "react";

import { useNavigate } from "react-router-dom";

import Datepicker from "react-tailwindcss-datepicker";

import { CircularLoader } from "../Container/CircularLoader";

import { Tooltip } from "react-tooltip";

import GetApiData from "../helpers/getApiData";

import "./Products.css";

import { customStyleToolTip } from "../utils/customStyleToolTip";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Tabs, Tab } from "./Tab";

import { AiOutlineSearch } from "react-icons/ai";

import ScreenRights from "../utils/ScreenRights";

import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import usePageSetting from "../utils/usePageSetting";

import ToasterAlert from "../utils/ToasterAlert";
import postApiData from "../helpers/postApiData";
import RejectedProducts from "./RejectedProducts";

import { AiFillInfoCircle } from "react-icons/ai";

const GoogleDataMetrics = () => {
  const dispatch = useDispatch();
  let metrics = useSelector((state) => state.metrics);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [optionsFilter, setOptionsFilter] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuerySupplier, setSearchQuerySupplier] = useState("");
  const [searchQuerySupplierTitle, setSearchQuerySupplierTitle] = useState("");
  const [OptionsBrandMetrics, setOptionsBrandMetrics] = useState();
  const [OptionsCategoryL1Metrics, setOptionsCategoryL1Metrics] = useState();
  const [OptionsCategoryL2Metrics, setOptionsCategoryL2Metrics] = useState();
  const [OptionsCategoryL3Metrics, setOptionsCategoryL3Metrics] = useState();

  const [OptionsBrand, setOptionsBrand] = useState();

  const [OptionsBrandProducts, setOptionsBrandProducts] = useState();
  const [OptionsCategoryL1Products, setOptionsCategoryL1Products] = useState();
  const [OptionsCategoryL2Products, setOptionsCategoryL2Products] = useState();
  const [OptionsCategoryL3Products, setOptionsCategoryL3Products] = useState();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [CircularProgress, setCircularProgress] = useState(false);

  const [updateBit, setUpdateBit] = useState(false);
  const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
  const screen_name = "/metrics";
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);
  const [selectedFilters, setSelectedFilters] = useState({
    filtered: "",
    date1: "",
    brand1: "",
    categoryL1Metrics: "",
    categoryL2Metrics: "",
    categoryL3Metrics: "",
    date2: "",
    brand2: "",

    date3: "",
    brand3: "",
    categoryL1Products: "",
    categoryL2Products: "",
    categoryL3Products: "",

    inventoryStatus: "",
  });
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const filterOptions = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 2,
      name: "No",
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleDatePerformanceFilter = (date2) => {
    setValue(date2);

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      date2,
    }));
  };

  
  const handleDateFilterProducts = (date3) => {
    setValue(date3);

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      date3,
    }));
  };



  


  const handleDateFilter = (date1) => {
    setValue(date1);

    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      date1,
    }));
  };

  const handleCategoryL1FilterMetrics = (categoryL1Metrics) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL1Metrics,
    }));
  };

  const handleCategoryL2FilterMetrics = (categoryL2Metrics) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL2Metrics,
    }));
  };

  const handleCategoryL3FilterMetrics = (categoryL3Metrics) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL3Metrics,
    }));
  };

  const handleBrandFilterMetrics = (brand1) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand1,
    }));
  };

  const handleCategoryL1FilterProducts = (categoryL1Products) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL1Products,
    }));
  };

  const handleCategoryL2FilterProducts = (categoryL2Products) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL2Products,
    }));
  };

  const handleCategoryL3FilterProducts = (categoryL3Products) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      categoryL3Products,
    }));
  };


  const handleBrandFilterPerformance = (brand2) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand2,
    }));
  };


  const handleBrandFilterProducts = (brand3) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand3,
    }));
  };


  useEffect(() => {
    async function fetchData() {
      const dataBrandMetrics = await GetApiData(
        "product/get_metrics_data_filter",
        ""
      );

      let resultsBrandMetrics = [];

      resultsBrandMetrics.push({ key: 0, value: "" });
      dataBrandMetrics.metricsBrands.map((value) => {
        return resultsBrandMetrics.push({
          key: value.segments.offerId,
          value: value.segments.brand,
        });
      });

      let uniqueResultsBrandMetrics = resultsBrandMetrics.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsBrandMetrics = [...uniqueResultsBrandMetrics];
      setOptionsBrandMetrics(resultsBrandMetrics);

      let resultsCategoryL1Metrics = [];

      resultsCategoryL1Metrics.push({ key: 0, value: "" });
      dataBrandMetrics.metricsBrands.map((value) => {
        return resultsCategoryL1Metrics.push({
          key: value.segments.offerId,
          value: value.segments.categoryL1,
        });
      });

      let uniqueResultsCategoryL1Metrics = resultsCategoryL1Metrics.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL1Metrics = [...uniqueResultsCategoryL1Metrics];
      setOptionsCategoryL1Metrics(resultsCategoryL1Metrics);

      let resultsCategoryL2Metrics = [];

      resultsCategoryL2Metrics.push({ key: 0, value: "" });
      dataBrandMetrics.metricsBrands.map((value) => {
        return resultsCategoryL2Metrics.push({
          key: value.segments.offerId,
          value: value.segments.categoryL2,
        });
      });

      let uniqueResultsCategoryL2Metrics = resultsCategoryL2Metrics.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL2Metrics = [...uniqueResultsCategoryL2Metrics];
      setOptionsCategoryL2Metrics(resultsCategoryL2Metrics);

      let resultsCategoryL3Metrics = [];

      resultsCategoryL3Metrics.push({ key: 0, value: "" });
      dataBrandMetrics.metricsBrands.map((value) => {
        return resultsCategoryL3Metrics.push({
          key: value.segments.offerId,
          value: value.segments.categoryL3,
        });
      });

      let uniqueResultsCategoryL3Metrics = resultsCategoryL3Metrics.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL3Metrics = [...uniqueResultsCategoryL3Metrics];
      setOptionsCategoryL3Metrics(resultsCategoryL3Metrics);

      let resultsBrand = [];

      resultsBrand.push({ key: 0, value: "" });

      
      dataBrandMetrics.performanceBrands.map((value,index) => {
        return resultsBrand.push({
          key: index,
          value: value.brand.name,
        });
      });

      let uniqueResultsBrand = resultsBrand.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsBrand = [...uniqueResultsBrand];

      setOptionsBrand(resultsBrand);






      
      let resultsBrandProducts = [];

      resultsBrandProducts.push({ key: 0, value: "" });
      dataBrandMetrics.performanceData.map((value,index) => {
        return resultsBrandProducts.push({
          key: index,
          value: value.productCluster.brand,
        });
      });

      let uniqueResultsBrandProducts = resultsBrandProducts.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsBrandProducts = [...uniqueResultsBrandProducts];
      setOptionsBrandProducts(resultsBrandProducts);

      let resultsCategoryL1Products = [];

      resultsCategoryL1Products.push({ key: 0, value: "" });
      dataBrandMetrics.performanceData.map((value,index) => {
        return resultsCategoryL1Products.push({
          key: index,
          value: value.productCluster.categoryL1,
        });
      });

      let uniqueResultsCategoryL1Products = resultsCategoryL1Products.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL1Products = [...uniqueResultsCategoryL1Products];
      setOptionsCategoryL1Products(resultsCategoryL1Products);

      let resultsCategoryL2Products = [];

      resultsCategoryL2Products.push({ key: 0, value: "" });
      dataBrandMetrics.performanceData.map((value,index) => {
        return resultsCategoryL2Products.push({
          key: index,
          value: value.productCluster.categoryL2,
        });
      });

      let uniqueResultsCategoryL2Products = resultsCategoryL2Products.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL2Products = [...uniqueResultsCategoryL2Products];
      setOptionsCategoryL2Products(resultsCategoryL2Products);

      let resultsCategoryL3Products = [];

      resultsCategoryL3Products.push({ key: 0, value: "" });
      dataBrandMetrics.performanceData.map((value,index) => {
        return resultsCategoryL3Products.push({
          key: index,
          value: value.productCluster.categoryL3,
        });
      });

      let uniqueResultsCategoryL3Products = resultsCategoryL3Products.filter(
        (obj, index, self) =>
          index === self.findIndex((o) => o.value === obj.value)
      );
      resultsCategoryL3Products = [...uniqueResultsCategoryL3Products];
      setOptionsCategoryL3Products(resultsCategoryL3Products);







    }

    fetchData();
  }, [metrics.metrics.metrics]);

  const optionElementsBrandsMetrics = useMemo(() => {
    return (
      OptionsBrandMetrics &&
      OptionsBrandMetrics.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsBrandMetrics]);

  const optionElementsCategoryL1Metrics = useMemo(() => {
    return (
      OptionsCategoryL1Metrics &&
      OptionsCategoryL1Metrics.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL1Metrics]);

  const optionElementsCategoryL2Metrics = useMemo(() => {
    return (
      OptionsCategoryL2Metrics &&
      OptionsCategoryL2Metrics.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL2Metrics]);

  const optionElementsCategoryL3Metrics = useMemo(() => {
    return (
      OptionsCategoryL3Metrics &&
      OptionsCategoryL3Metrics.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL3Metrics]);



  

  



  const optionElementsBrands = useMemo(() => {
    return (
      OptionsBrand &&
      OptionsBrand.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsBrand]);


  const optionElementsCategoryL3Products = useMemo(() => {
    return (
      OptionsCategoryL3Products &&
      OptionsCategoryL3Products.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL3Products]);






  const optionElementsCategoryL2Products = useMemo(() => {
    return (
      OptionsCategoryL2Products &&
      OptionsCategoryL2Products.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL2Products]);


  
  const optionElementsCategoryL1Products = useMemo(() => {
    return (
      OptionsCategoryL1Products &&
      OptionsCategoryL1Products.map((element, index) => (
        <option key={index} value={element.value}>
          {element.value}
        </option>
      ))
    );
  }, [OptionsCategoryL1Products]);


const optionElementsBrandsProducts = useMemo(() => {
  return (
     OptionsBrandProducts &&
     OptionsBrandProducts.map((element, index) => (
      <option key={index} value={element.value}>
        {element.value}
      </option>
    ))
  );
}, [OptionsBrandProducts]);














  let totalPages = Math.ceil(metrics.metrics.productCount / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    setCircularProgress(true);

    dispatch(
      metricsData({
        page: currentPage,
        searchQuery: searchQuery,
        searchQuerySupplier: searchQuerySupplier,
        searchQuerySupplierTitle: searchQuerySupplierTitle,
        selectedFilters: selectedFilters,
      })
    );

    setCircularProgress(false);
  }, [
    dispatch,
    currentPage,
    searchQuery,
    searchQuerySupplier,
    searchQuerySupplierTitle,
    updateBit,
    selectedFilters,
  ]);

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;
    endPage = isNaN(endPage) == true ? 0 : endPage;
    startPage = isNaN(startPage) == true ? 0 : startPage;
    totalPages = isNaN(totalPages) == true ? 0 : totalPages;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };

  const handlePublish = async (id, suggested_price) => {
    setCircularProgress(true);
    // await postApiData()

    await postApiData("product/update_products_by_google", {
      id: id,
      price: suggested_price,
    });
    setCircularProgress(false);
  };

  return (
    <>
      {checkRights && checkRights == true ? (
        <>
          {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

          <ToasterAlert />
          <Header></Header>
          <MenuHeader></MenuHeader>

          {showModal ? (
            <RejectedProducts
              props={setShowModal}
              updateBit={setUpdateBit}
              className="overflow-y-auto "
            ></RejectedProducts>
          ) : null}

          <div className="mx-2 ">
            <div>
              <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                {pageSettings && pageSettings.title}
              </span>

              <div className="flex grid-col-2 gap-2">
                <div className="    ">
                  <span
                    className="py-4  text-base text-left text-gray-500 font-dmsans"
                    dangerouslySetInnerHTML={{
                      __html: pageSettings && pageSettings.desc,
                    }}
                  ></span>
                </div>

                <a
                  data-tooltip-id="info_tooltip"
                  data-tip
                  data-tooltip-place="top"
                  className="  whitespace-normal font-dmsans "
                >
                  <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
                </a>

                <Tooltip
                  id="info_tooltip"
                  style={customStyleToolTip}
                  className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
                >
                  {pageSettings && pageSettings.info ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: pageSettings.info }}
                    />
                  ) : (
                    <p></p>
                  )}
                </Tooltip>
              </div>
            </div>

            <div className="flex flex-col min-h-screen bg-transparent mt-2">
              {/* <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
                <div className="absolute flex-shrink-0 items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>

                  <div className=" flex grid-col-3  gap-4">
                    <div className="relative">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search Google Products by Name"
                        className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute  inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        value={searchQuerySupplier}
                        onChange={(e) => setSearchQuerySupplier(e.target.value)}
                        placeholder="Search Supplier's Name"
                        className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute  inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>

                    <div className="relative">
                      <input
                        type="text"
                        value={searchQuerySupplierTitle}
                        onChange={(e) =>
                          setSearchQuerySupplierTitle(e.target.value)
                        }
                        placeholder="Search Google's Product Title"
                        className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute  inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>

                    <div className="relative">
                      <button
                        onClick={async () => {
                          setShowModal(true);
                        }}
                        type="submit"
                        className="w-fit border  bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg  hover:bg-maincolor hover-text-white"
                      >
                        Show Rejected Products
                      </button>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="relative mx-2">
                <div className="flex flex-col">
                  <label className="text-sm mb-1 text-gray-500">
                    Filter for Higher Selling Price:
                  </label>
                  <select
                    value={selectedFilters.filtered}
                    onChange={(e) => handleFilter(e.target.value)}
                    className="scrollbar scrollbar-thumb-maincolor block w-full sm:w-56 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-maincolor focus:border-maincolor"
                  >
                    {optionElementsFilter}
                  </select>
                </div>
              </div>
 */}

              <Tabs className="flex w-full ">
                <Tab label="Metrics">
                  <div className="py-4 ">
                    <div className="mx-auto flex flex-row">
                      <div className="grid grid-row-1 sm:grid-row-1 gap-2 ">
                        <div className="font-dmsans border border-solid md:p-6 p-2  shadow-md  ">
                          <div className="flex flex-col">
                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Date:
                            </label>

                            <div className="font-dmsans flex border-solid border-2 rounded-lg   border-maincolor ">
                              <Datepicker
                                value={value}
                                primaryColor={"amber"}
                                className="font-dmsans w-48 border-maincolor border-solid rounded-xl border-2   "
                                onChange={handleDateFilter}
                              />
                            </div>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Brand:
                            </label>

                            <select
                              value={selectedFilters.brand1}
                              onChange={(e) =>
                                handleBrandFilterMetrics(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar   scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsBrandsMetrics}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans mt-2">
                              Category L1:
                            </label>

                            <select
                              value={selectedFilters.categoryL1Metrics}
                              onChange={(e) =>
                                handleCategoryL1FilterMetrics(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL1Metrics}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Category L2:
                            </label>

                            <select
                              value={selectedFilters.categoryL2Metrics}
                              onChange={(e) =>
                                handleCategoryL2FilterMetrics(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL2Metrics}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Category L3:
                            </label>

                            <select
                              value={selectedFilters.categoryL3Metrics}
                              onChange={(e) =>
                                handleCategoryL3FilterMetrics(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL3Metrics}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                      <div className="p-8 md:p-14">
                        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                          Metrics List
                        </span>

                        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  title
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  brand
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  category
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  clicks
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  impressions
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  date
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  ctr
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  conversions
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    shipped Items
                                  </th>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {metrics.metrics.metrics &&
                                metrics.metrics.metrics.map((item, index) => (
                                  <tr key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        <div className="font-dmsans  text-maincolor  font-normal">
                                          {item.segments.title}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        <div className="font-dmsans  text-maincolor  font-normal">
                                          {item.segments.brand}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {item.segments.categoryL1 +
                                          "/" +
                                          item.segments.categoryL2 +
                                          "/" +
                                          item.segments.categoryL3}
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {item.metrics.clicks}
                                      </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {Number.isNaN(item.metrics.impressions)
                                          ? " "
                                          : item.metrics.impressions}
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        <div className="font-dmsans  text-maincolor  font-normal">
                                          {item.segments.date.day +
                                            "-" +
                                            item.segments.date.month +
                                            "-" +
                                            item.segments.date.year}
                                        </div>
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {isNaN(item.metrics.ctr)
                                          ? " "
                                          : item.metrics.ctr}
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {isNaN(item.metrics.conversions)
                                          ? " "
                                          : item.metrics.conversions}
                                      </div>
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap">
                                      <div className="text-sm font-medium text-gray-900">
                                        {isNaN(item.metrics.shippedItems)
                                          ? " "
                                          : item.metrics.shippedItems}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab label="Popular Brands">
                  <div className="py-4">
                    <div className="mx-auto flex flex-row">
                      <div className="grid grid-row-1 sm:grid-row-1 gap-2 ">
                        <div className="font-dmsans border border-solid md:p-6 p-2  shadow-md  ">
                          <div className="flex flex-col">
                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Date:
                            </label>




                            <div className="font-dmsans flex border-solid border-2 rounded-lg    border-maincolor ">
                              <Datepicker
                                value={value}
                                primaryColor={"amber"}
                                className="font-dmsans w-48 border-maincolor border-solid rounded-xl border-2   "
                                onChange={handleDatePerformanceFilter}
                              />
                            </div>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Brands
                            </label>
                            
                            <select
                              value={selectedFilters.brand2}
                              onChange={(e) =>
                                handleBrandFilterPerformance(e.target.value)
                              }

                              className="bg-gray-50 border  border-gray-300 scrollbar   scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsBrands}
                            </select>




                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                      <div className="p-8 md:p-14">
                        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                          Popular Brands
                        </span>

                        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Brand's Name
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  report Date
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Report Granularity
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  rank
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  previous rank
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {metrics.metrics.performanceBrands &&
                                metrics.metrics.performanceBrands.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-maincolor  font-normal">
                                            {item.brand.name}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="text-sm font-medium text-gray-900">
                                            {item.bestSellers.reportDate.day +
                                              "-" +
                                              item.bestSellers.reportDate
                                                .month +
                                              "-" +
                                              item.bestSellers.reportDate.year}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.reportGranularity}
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.rank}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.previousRank}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab label="Popular Products">
                  <div className="py-4">

                    <div className="mx-auto flex flex-row">
                      <div className="grid grid-row-1 sm:grid-row-1 gap-2 ">
                        <div className="font-dmsans border border-solid md:p-6 p-2  shadow-md  ">
                          <div className="flex flex-col">
                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Date:
                            </label>

                            <div className="font-dmsans flex border-solid border-2 rounded-lg   border-maincolor ">
                              <Datepicker
                                value={value}
                                primaryColor={"amber"}
                                className="font-dmsans w-48 border-maincolor border-solid rounded-xl border-2   "
                                onChange={handleDateFilterProducts}
                              />
                            </div>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Brand:
                            </label>

                            <select
                              value={selectedFilters.brand3}
                              onChange={(e) =>
                                handleBrandFilterProducts(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar   scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsBrandsProducts}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans mt-2">
                              Category L1:
                            </label>

                            <select
                              value={selectedFilters.categoryL1Products}
                              onChange={(e) =>
                                handleCategoryL1FilterProducts(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL1Products}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Category L2:
                            </label>

                            <select
                              value={selectedFilters.categoryL2Products}
                              onChange={(e) =>
                                handleCategoryL2FilterProducts(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL2Products}
                            </select>

                            <label className="text-sm mb-1 text-gray-500 font-dmsans  mt-2">
                              Category L3:
                            </label>

                            <select
                              value={selectedFilters.categoryL3Products}
                              onChange={(e) =>
                                handleCategoryL3FilterProducts(e.target.value)
                              }
                              className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                            >
                              {optionElementsCategoryL3Products}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    



                    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                      <div className="p-8 md:p-14">
                        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                          Popular Products
                        </span>

                        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  title
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  brand
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  category
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  inventory Status
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  report date
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Report Granularity
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  rank
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  previous rank
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  relative demand
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {metrics.metrics.performanceData &&
                                metrics.metrics.performanceData.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-maincolor  font-normal">
                                            {item.productCluster.title}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-maincolor  font-normal">
                                            {item.productCluster.brand}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.productCluster.categoryL1 +
                                            "/" +
                                            item.productCluster.categoryL2 +
                                            "/" +
                                            item.productCluster.categoryL3}
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-maincolor  font-normal">
                                            {
                                              item.productCluster
                                                .inventoryStatus
                                            }
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="text-sm font-medium text-gray-900">
                                            {item.bestSellers.reportDate.day +
                                              "-" +
                                              item.bestSellers.reportDate
                                                .month +
                                              "-" +
                                              item.bestSellers.reportDate.year}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.reportGranularity}
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.rank}
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.previousRank}
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          {item.bestSellers.relativeDemand}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>

                <Tab label="Competitors">
                  <div className="py-4">
                    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                      <div className="p-8 md:p-14">
                        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                          Competitors
                        </span>

                        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  domain
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  Category
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  is your domain
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  trafic source
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  rank
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  page overlap rate
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  higher position rate
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                  relative Visibility
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {metrics.metrics.competitors &&
                                metrics.metrics.competitors.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility
                                              ? item.competitiveVisibility
                                                  .domain
                                              : ""}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans text-gray-900  font-normal">
                                            {"Electronics"}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility
                                              .isYourDomain == true
                                              ? "True"
                                              : "False"}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans text-gray-900  font-normal">
                                            {
                                              item.competitiveVisibility
                                                .trafficSource
                                            }
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility.rank}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans text-gray-900  font-normal">
                                            {(
                                              item.competitiveVisibility
                                                .pageOverlapRate * 100
                                            ).toFixed(2)}
                                            {"%"}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900 font-normal">
                                            {(
                                              item.competitiveVisibility
                                                .higherPositionRate * 100
                                            ).toFixed(2)}
                                            {"%"}
                                          </div>
                                        </div>
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans text-gray-900  font-normal">
                                            {
                                              item.competitiveVisibility
                                                .relativeVisibility
                                            }
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>







                <Tab label="Competitiveness">
                  <div className="py-4">
                    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                      <div className="p-8 md:p-14">
                        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                        Competitiveness
                        </span>

                        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                          <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                traffic Source
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                your Domain Visibility Trend
                                </th>

                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                category Benchmark Visibility Trend
                                </th>

                              </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                              {metrics.metrics.competitiveness &&
                                metrics.metrics.competitiveness.map(
                                  (item, index) => (
                                    <tr key={index}>
                                      
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility.yourDomainVisibilityTrend}
                                          </div>
                                        </div>
                                      </td>
                                  
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility.categoryBenchmarkVisibilityTrend}
                                          </div>
                                        </div>
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm font-medium text-gray-900">
                                          <div className="font-dmsans  text-gray-900  font-normal">
                                            {item.competitiveVisibility.trafficSource}
                                          </div>
                                        </div>
                                      </td>



                                    </tr>
                                  )
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>

              {/* <div className=" flex justify-end mb-10">
                <div className="flex flex-col ">
                  <div className="flex flex-1 mt-2">
                    <button

                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                      className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
                    >
                      <AiFillCaretLeft />
                    </button>
                    <div className=" ">{getPages()}</div>


                    <button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                      className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
                    >
                      <AiFillCaretRight />
                    </button>
                  </div>    
                </div>
              </div>
               */}
            </div>
          </div>
        </>
      ) : checkRights ? (
        <ScreenRights></ScreenRights>
      ) : (
        <></>
      )}
    </>
  );
};

export default GoogleDataMetrics;
