import React, { useState, useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import getApiData from "../helpers/getApiData";
import { menuData } from "../helpers/MenuStore/menuSlice";


const Header = () => {
  const [activeLink, setActiveLink] = useState("");
  const [role, setRole] = useState("");
const [menu,setMenu] = useState([]);
const dispatch = useDispatch();
let menuSliceData = useSelector((state) => state.menu);

const fetchData = async () => {
  try {
 
    const newdata = await getApiData("menu/get_menu/header", "");

    setMenu(newdata);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {

  
  fetchData();
  if (localStorage.getItem("user_data")) {
    setRole(JSON.parse(localStorage.getItem("user_data")).role);
  }

}, []);


useEffect(() => {
  dispatch(menuData({type:"header"}));
}, [dispatch]);



  const location = useLocation();
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };
  React.useEffect(() => {

    setActiveLink(location.pathname);
  }, [location]);

  const memoizedMenu = useMemo(() => menu, [menu]);


 const MenuItem =({ menu, childMenus ,role}) => (
  <li className="relative group w-full sm:w-auto mr-2">

  

    {menu && (

      <div className="flex flex-col items-center ml-auto text-sm">
        <Link
          to={menu.ScreenDetails?.screen_url || "#"}
          className={`flex p-4 ${
            activeLink === menu.menu_name ? "text-white bg-maincolor" : ""
          }  dark:hover:bg-gray-200 w-64  font-dmsans  font-semibold   hover:bg-maincolor hover:text-white  justify-center`}
          onClick={() => handleLinkClick(menu.menu_name)}
        >


          <p className="text-sm font-dmsans">
          
{         menu   && menu.menu_name}
</p>

        </Link>
        {childMenus && childMenus.length > 0 && (
     <ul className="sub-menu hidden w-64 absolute h-max shadow-lg z-20 top-12 md:left-0 text-gray-800 bg-white border  group-hover:block full-width dark:hover:text-maincolor justify-center">
     {childMenus.map((childMenu, index) => (
       (childMenu.user && role === 'user') ||
       (childMenu.admin && role === 'admin') ||
       (childMenu.reseller && role === 'reseller')
       
       ? (
         <li key={index} className="p-2 hover:bg-maincolor hover:text-white w-full">

           <Link
             to={childMenu?.ScreenDetails?.screen_url || "#"}
             className="font-dmsans p-2  cursor-pointer rounded text-base  hover:bg-maincolor bg-opacity-75 hover:text-white w-full"
             onClick={() => handleLinkClick(childMenu?.menu_name)}
           >
             {childMenu?.menu_name}
           </Link>
         </li>
       ) : null
     ))}
   </ul>


        )}
      </div>
    )}
  </li>
);




const MainMenu = ({menuArray, userRole }) => (

  


  <ul className="flex md:flex-wrap flex-wrap text-sm font-medium w-full text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 border border-solid">
   


    {menuArray && menuArray.map((menuItem, index) => (
      
      (

        
        <MenuItem key={index} menu={menuItem.parentMenu} childMenus={menuItem.childMenus}  role = {userRole}/>
      )

    ))}
  </ul>
);
  return (
    <>

    {/* <ul className="flex   md:flex-wrap  flex-wrap text-sm font-medium w-full text-gray-500 border-b border-gray-200 dark:border-gray-700 dark:text-gray-400 border border-solid ">
      {role == "admin" ? (
        <li className="w-full sm:w-auto mr-2 ">
          <Link
            to="/product_details"
            className={`flex p-4 ${
              activeLink === "/product_details" ? "text-white   bg-maincolor" : ""
            }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-cen justify-center`}
            onClick={() => handleLinkClick("product_details")}
          >
            Product List
          </Link>
        </li>
      ) : (
        <li className="w-full sm:w-auto mr-2">
          <Link
            to="/product_list"
            className={`flex p-4 ${
              activeLink === "/product_list" ? "text-white   bg-maincolor" : ""
            }  hover:text-gray-600 hover:bg-gray-600  dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
            onClick={() => handleLinkClick("product_list")}
          >
            Product List
          </Link>
        </li>
      )}

      {role == "admin" ? (
        <>
          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/distributors"
              className={`flex p-4 ${
                activeLink === "/distributors"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("distributors")}
            >
              Distributor
            </Link>
          </li>
          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/category_list"
              className={`flex p-4 ${
                activeLink === "/category_list"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("category_list")}
            >
              Category
            </Link>
          </li>
          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/markup"
              className={`flex p-4 ${
                activeLink === "/markup" ? "text-white   bg-maincolor" : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("markup")}
            >
              Mark Up
            </Link>
          </li>
          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/cat_detail"
              className={`flex p-4 ${
                activeLink === "/cat_detail" ? "text-white   bg-maincolor" : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("cat_detail")}
            >
              Category Detail
            </Link>
          </li>
          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/brand"
              className={`flex p-4 ${
                activeLink === "/brand" ? "text-white   bg-maincolor" : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("brand")}
            >
              Brand
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/product_price"
              className={`flex p-4 ${
                activeLink === "/product_price"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("product_price")}
            >
              
              Product Price Rule
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/r_markup"
              className={`flex p-4 ${
                activeLink === "/r_markup" ? "text-white   bg-maincolor" : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("r_markup")}
            >
              Reseller Pricing Rules
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/reseller_products"
              className={`flex p-4 ${
                activeLink === "/reseller_products"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("r_markup")}
            >
              Reseller Data
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/update_product"
              className={`flex p-4 ${
                activeLink === "/update_product"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("update_product")}
            >
              Update Product
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/publish_product"
              className={`flex p-4 ${
                activeLink === "/publish_product"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("publish_product")}
            >
              Published Products
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/search_products"
              className={`flex p-4 ${
                activeLink === "/search_products"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("search_products")}
            >
              Search Products
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/search_pattern"
              className={`flex p-4 ${
                activeLink === "/search_pattern"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("search_pattern")}
            >
              Search by Name
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/product_history"
              className={`flex p-4 ${
                activeLink === "/product_history"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("product_history")}
            >
              Product History
            </Link>
          </li>

          <li className="w-full sm:w-auto mr-2">
            <Link
              to="/google_products"
              className={`flex p-4 ${
                activeLink === "/google_products"
                  ? "text-white   bg-maincolor"
                  : ""
              }  hover:text-gray-600 hover:bg-gray-50 dark:hover:bg-gray-800 dark:hover:text-gray-300 justify-center`}
              onClick={() => handleLinkClick("google_products")}
            >
              Google Products
            </Link>
          </li>
        </>
      ) : (
        <></>
      )}

    </ul>
     */}
    {menuSliceData ? (
        <MainMenu menuArray={menuSliceData.menus.menuArray} userRole={role} />
      ) : (
        <></>
      )}

    
    

    </>



  );
};

export default Header;
