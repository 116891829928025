import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
export const productHistoryData = createAsyncThunk(
    "productHistory",
    async (payload, { rejectWithValue }) => {
      try {

      
        const user_role = JSON.parse(localStorage.getItem("user_data")).role;
        const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
        
      
        const queryString = encodeURIComponent(JSON.stringify(payload));


            if(user_role == "admin"){

              
            
        const request = await axios.get(`${env.API_URL}product/get_product_history/${queryString}`);


        const response = await request.data;

        return response;

            }
         


        

      } catch (error) {
        console.log(error);
        return rejectWithValue(error.message);
      }
    }
  );


const productHistorySlice = createSlice({
  name: "productHistory",
  initialState: {
    productHistory : [],
    loading: false,
    productHistoryCount:0,
    error: null,
  },
  reducers: {
    addproductHistory: (state, action) => {
        
    },
  },
  extraReducers: (builder) => {
    
    builder
      .addCase(productHistoryData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(productHistoryData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(productHistoryData.fulfilled, (state, action) => {
          state.loading = false;
      
          state.productHistory = action.payload;

            
        }
      );
  },
});
export const { addproductHistory } = productHistorySlice.actions;
export default productHistorySlice.reducer;


