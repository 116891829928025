import React, { useLayoutEffect } from "react";
import { useMemo } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { brandData } from "../helpers/AuthStore/brandSlice";

import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { GiCrossMark } from "react-icons/gi";
import { IoIosArrowDropdown } from "react-icons/io";
import getApiData from "../helpers/getApiData";

import { TbLayoutNavbarExpandFilled } from "react-icons/tb";

import { IoMdInformationCircleOutline } from "react-icons/io";
import usePageSetting from "../utils/usePageSetting";

import ProductBox from "../Products/ProductBox";
import ToasterAlert from "../utils/ToasterAlert";

import AlphabeticFilters from "./AlphabeticFilters";
import { useNavigate } from "react-router-dom";
import { GiCheckMark } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";
import postApiData from "../helpers/postApiData";
import { CircularLoader } from "../Container/CircularLoader";
import { customStyleToolTip } from "../utils/customStyleToolTip";

import ScreenRights from "../utils/ScreenRights";
import {AiFillInfoCircle} from 'react-icons/ai';
import { Tooltip } from "react-tooltip";

import useAuthScreenCheck from "../utils/useAuthScreenCheck";

const Brand = () => {
  const dispatch = useDispatch();
  let brand = useSelector((state) => state.brand);
  const [selectedFilter, setSelectedFilter] = useState("");

  const [searchQuery, setSearchQuery] = useState("");

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [publishStates, setPublishStates] = useState({});

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [CircularProgress, setCircularProgress] = useState(false);
  const [accordion, setAccordion] = useState(true);

  const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
  const screen_name = "/brand";
  const pageSettings = usePageSetting(screen_name);

  const checkRights = useAuthScreenCheck(user_id, screen_name);

  const handleSearch = async (brand_name) => {
    setCircularProgress(true);

    const filteredProducts = await getApiData(
      "product/get_products_with_brand",
      brand_name
    );

    setSearchResults(filteredProducts.products);

    setCircularProgress(false);
  };

  const navigate = useNavigate();
  const filteredScreen = useMemo(
    () =>
      brand.brands?.brands
        ? brand.brands.brands.filter((item) =>
            item.name != null
              ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
              : "No Name"
          )
        : [],
    [brand.brands?.brands, searchQuery]
  );

  useEffect(() => {
    if (brand.brands?.brands) {
      const newPublishStates = {};

      for (const loadBrands of brand.brands.brands) {
        newPublishStates[loadBrands._id] = loadBrands.publish;
      }
      setPublishStates(newPublishStates);
    }
  }, [brand.brands]);

  const filteredBrands = useMemo(() => {
    if (selectedFilter === "") {
      return filteredScreen || [];
    } else {
      return (
        filteredScreen?.filter((item) => {
          const brandName = item.name ? item.name.toLowerCase() : "";
          return brandName.startsWith(selectedFilter.toLowerCase());
        }) || []
      );
    }
  }, [filteredScreen, selectedFilter]);

  const filteredSelectNonSelectedBrands = useMemo(() => {
    if (selectedBrands === "") {
      return filteredBrands || [];
    } else {
      return filteredBrands;
      if (selectedBrands) {
        return filteredBrands?.filter((item) => item.publish === true) || [];
      } else {
        return filteredBrands?.filter((item) => item.publish === false) || [];
      }
    }
  }, [filteredBrands, selectedFilter, selectedBrands]);
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  useEffect(() => {
    dispatch(brandData());
  }, [dispatch]);

  const handleSelectAll = (value) => {
    if (value == "publish") {
      setPublishStates((prevState) => {
        const updatedStates = {};
        for (const brandnew of filteredScreen) {
          updatedStates[brandnew._id] = true;
        }
        return updatedStates;
      });
    } else {
      setPublishStates((prevState) => {
        const updatedStates = {};
        for (const brand of filteredScreen) {
          updatedStates[brand._id] = false;
        }
        return updatedStates;
      });
    }
  };

  const handleCheckboxChange = async (brandId, publishValue) => {
    try {
      setCircularProgress(true);

      await postApiData("product/publish_brands", {
        publish: publishValue,
        id: brandId,
      });
      setCircularProgress(false);
    } catch (err) {
      console.error(err);
    }

    setPublishStates((prevState) => ({
      ...prevState,
      [brandId]: !prevState[brandId],
    }));
  };

  return (
    <>
      
      <Header></Header>
      <MenuHeader></MenuHeader>
      {checkRights && checkRights == true ? (
        <>
          <ToasterAlert></ToasterAlert>

          {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

          <div className="mx-2 ">
            
          <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}
              </Tooltip>
            </div>
            </div>


            <div className="flex flex-col min-h-auto bg-transparent">
       
              <div className="p-4">
                <div className="flex flex-col sm:flex-row sm:justify-start">
                  <button
                    onClick={() => {
                      handleSelectAll("publish");
                    }}
                    className="flex items-center justify-center w-full sm:w-auto text-sm md:text-base sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-3 sm:mb-0 hover:bg-maincolor hover-text-white"
                  >
                    Select All For Publish
                    <GiCheckMark className="ml-2" />
                  </button>

                  <button
                    onClick={() => {
                      handleSelectAll("unpublish");
                    }}
                    className="flex items-center justify-center w-full sm:w-auto text-sm md:text-base sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-3 sm:mb-0 hover:bg-maincolor hover-text-white"
                  >
                    Select None For Publish
                    <GiCrossMark className="ml-2" />
                  </button>

                  <button
                    onClick={() => setSelectedBrands(true)}
                    className="flex items-center justify-center w-full sm:w-auto text-sm md:text-base sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-3 sm:mb-0 hover:bg-maincolor hover-text-white"
                  >
                    Show Selected
                  </button>

                  <button
                    onClick={() => setSelectedBrands(false)}
                    className="flex items-center justify-center w-full sm:w-auto text-sm md:text-base sm:text-base lg:text-base font-dmsans border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg hover:bg-maincolor hover-text-white"
                  >
                    Show Unselected
                  </button>
                </div>
              </div>
            </div>

            <div className="flex flex-col min-h-screen bg-transparent mt-2">
              <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
                <div className="absolute flex-shrink-0 items-center">
                  <label htmlFor="simple-search" className="sr-only">
                    Search
                  </label>

                  <div className="relative">
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      placeholder="Search Brand"
                      className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                      <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="container mx-auto px-4">
                <div className="flex justify-start mb-10">
                  <div className="flex flex-wrap">
                    <div className="w-full md:w-auto flex justify-center mt-2">
                      <AlphabeticFilters
                        selectedFilter={selectedFilter}
                        onFilterChange={handleFilterChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
                <div className="p-8 md:p-14">
                  <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                    Brand List
                  </span>

                  <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">

                        <tr>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Publish
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        {brand &&
                          brand.brands &&
                          brand.brands?.brands &&
                          filteredSelectNonSelectedBrands
                            .filter((item) => {
                              const publishState = publishStates[item._id];
                              if (selectedBrands) {
                                return publishState === true;
                              } else {
                                return publishState === false;
                              }
                            })
                            .map((item) => (
                              <tr key={item._id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        handleCheckboxChange(
                                          item._id,
                                          e.target.checked
                                        )
                                      }
                                      checked={publishStates[item._id]}
                                    />
                                  </div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    {item.name}
                                  </div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                  <div className="text-sm font-medium text-gray-900">
                                    <button>
                                      <div className="flex grid-col-2 gap-2">
                                        <TbLayoutNavbarExpandFilled
                                          onClick={() => {
                                            handleSearch(item.name);
                                          }}
                                          className="text-lg font-dmsans font-semibold "
                                        />
                                      </div>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                      </tbody>
                    </table>
                    <div className="flex   h-fit bg-transparent ">
                      <div className="container mx-auto px-4">
                        {searchResults && searchResults.length > 0 ? (
                          <ProductBox
                            currentItems={searchResults && searchResults}
                          ></ProductBox>
                        ) : (
                          <>
                            
                            <span className="py-4 text-sm text-left text-maincolor font-dmsans">
                              
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    {/*                     
<div className="flex items-left justify-left h-fit bg-transparent mt-44">
<div className="container mx-auto px-4">
  {searchResults && searchResults.length > 0 ? (
    <ProductBox
      currentItems={searchResults && searchResults}
    ></ProductBox>
  ) : (
    <>            <span className="py-4 text-sm text-left text-maincolor font-dmsans">No Products Found</span>
    </>
  )}
</div>
</div> */}
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </>
      ) : checkRights ? (
        <ScreenRights></ScreenRights>
      ) : (
        <></>
      )}
    </>
  );
};

export default Brand;
