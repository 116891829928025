import React from 'react'

import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { plightData } from "../helpers/AuthStore/plightSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState, useMemo } from "react";
import {CircularLoader} from '../Container/CircularLoader';
import { AiFillInfoCircle } from "react-icons/ai";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import GetApiData from "../helpers/getApiData";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import AuthScreen from "../utils/AuthScreen";
import ScreenRights from "../utils/ScreenRights";
import { useNavigate } from "react-router-dom";
import usePageSetting from "../utils/usePageSetting";

import UpdateProductDetails from "./UpdateProductDetails";


const UpdateProducts = () => 

        {
  const dispatch = useDispatch();
  let plight = useSelector((state) => state.plight);
  
  const [role, setrole] = useState("admin");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const [showFilters, setShowFilters] = useState(false);
  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
  const screen_name = '/update_product';
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  
const pageSettings = usePageSetting(screen_name);



  const [selectedFilters, setSelectedFilters] = useState({
    category: "",
    distributor: "",
    status: "",
    brand: "",
    stock: "",
    range: "",
  });


  const itemsPerPage = 100;
 



    


  let totalPages = Math.ceil(plight.plights.plightCount / itemsPerPage);
  
  const [options, setOptions] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsBrand, setOptionsBrand] = useState([]);
  const [optionsStock, setOptionsStock] = useState([]);
  const [optionRange, setOptionRange] = useState([]);
const [userid,setUserID] = useState();

  const stockOptions = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 2,
      name: "No",
    },
  ];

  const priceRange = [
    {
      id: 1,
      name: "0-500",
    },
    {
      id: 2,
      name: "500-1000",
    },

    {
      id: 3,
      name: "1000-1500",
    },

    {
      id: 4,
      name: "1500-100000",
    },
  ];


  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

 
  const optionElementsBrand = useMemo(() => {
    return optionsBrand.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsBrand]);

  const optionElementsStock = useMemo(() => {
    return optionsStock.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsStock]);

  const optionElementsRange = useMemo(() => {
    return optionRange.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionRange]);

  const optionElements = useMemo(() => {
    return options.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [options]);
  const optionElementsCategory = useMemo(() => {
    return optionsCategory.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsCategory]);

  const optionElementsStatus = useMemo(() => {
    return optionsStatus.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsStatus]);

  useEffect(() => {
    async function fetchData() {
      const data = await GetApiData("product/get_distributor_list", "");

      const results = [];
      results.push({ key: 0, value: "" });

      data.dist_list.map((value) => {
        return results.push({
          key: value._id,
          value: value._id,
        });
      });

      setOptions([...results]);






      const dataCategory = await GetApiData("product/get_categories", "");

      const resultsCategory = [];
      resultsCategory.push({ key: 0, value: "" });

      dataCategory.category.map((value) => {
        return resultsCategory.push({
          key: value._id,
          value: value.name,
        });
      });

      setOptionsCategory([...resultsCategory]);

      const dataStatus = await GetApiData(
        "product/get_product_status_list",
        ""
      );

      const resultsStatus = [];

      resultsStatus.push({ key: 0, value: "" });
      let idnew = 0;
      dataStatus.status.map((value) => {
        idnew += 1;
        resultsStatus.push({
          key: value + idnew,
          value:
            value._id == true
              ? "true"
              : value._id == false
              ? "false"
              : value._id,
        });
      });

      setOptionsStatus([...resultsStatus]);

      const dataBrand = await GetApiData("product/get_brands", "");

      const resultsBrand = [];
      resultsBrand.push({ key: 0, value: "" });
      dataBrand.brands.map((value) => {
        resultsBrand.push({
          key: value._id,
          value: value.name,
        });
      });

      setOptionsBrand([...resultsBrand]);

      const resultsStock = [];
      resultsStock.push({ key: 0, value: "" });
      stockOptions.map((value) => {
        resultsStock.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionsStock([...resultsStock]);

      const resultsRange = [];
      resultsRange.push({ key: 0, value: "" });
      priceRange.map((value) => {
        resultsRange.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionRange([...resultsRange]);
    }


    fetchData();
  }, [plight.plights.plights]);

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1

            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;

    endPage = isNaN(endPage) == true ? 0 : endPage;
    startPage = isNaN(startPage) == true ? 0 : startPage;
    totalPages = isNaN(totalPages) == true ? 0 : totalPages;


    

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };

  const handleColumnSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  
  

  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);
  };

  const handleStatusFilter = (status) => {
  
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
  };

  const handleCategoryFilter = (category) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      category,
    }));
  };
  const handleDistributorFilter = (distributor) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      distributor,
    }));
  };

  const handleBrandFilter = (brand) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand,
    }));
  };

  const handleStockFilter = (stock) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      stock,
    }));
  };

  const handleRangeFilter = (range) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      range,
    }));
  };

  const clearFilters = () => {
    setSelectedFilters({
      category: "",
      distributor: "",
      status: "",
      brand: "",
      stock: "",
      range: "",
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useLayoutEffect(() => {
    return () => {

      if(localStorage.getItem('user_data'))
      {
  setrole( JSON.parse(localStorage.getItem('user_data')).role);
  setUserID(JSON.parse(localStorage.getItem('user_data'))._id);
      }

      if (location.state) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          category: location.state,
        }));
      }
    };
  });

  useEffect(() => {

    dispatch(plightData({page:currentPage,searchQuery:searchQuery,selectedFilters:selectedFilters,sortColumn:sortColumn,sortDirection:sortDirection}));
  }, [dispatch,currentPage,searchQuery,selectedFilters,sortColumn,sortDirection]);


  
  useEffect(()=>{

      setCurrentPage(1);
      
      },[plight.plights.plightCount])
  
      


  useEffect(() => {
  
    if (plight.plights.length > 0) {
      toast.success("Product data loaded successfully!");
    }
},[ plight.plights.plights]);

  const isLoading = !plight.plights.plights;

  return (
    <>{ checkRights && checkRights == true ? ( <>
    
      <>
        {isLoading ? (
          <div className="text-center ">
          <CircularLoader></CircularLoader>
        </div>
        ) : (
          <>{/* Render the product list */}</>
        )}
      </>

      <Toaster
        toastOptions={{
          duration: 1000,
          className: "",
          success: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
            },
          },
          error: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
              color: "#f5621c",
            },
          },
        }}
        position="top-center"
        reverseOrder="false"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    className="close-icon"
                    onClick={() => {
                      toast.dismiss(t.id);
                    }}
                  ></button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>

      <Header></Header>
      <MenuHeader></MenuHeader>


      <div className="mx-2 ">
   
      
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}      
 
              </Tooltip>
            </div>
            </div>


        <div className=" flex  mx-8 mt-20 mb-2 items-end justify-end sm:justify-end ">
          <div className="absolute flex-shrink-0 items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative ">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Product"
          
                className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
              </div>
            </div>
          </div>
        </div>

 
<div className="grid grid-cols-1 sm:grid-cols-5 gap-2">


  <div className={`w-full col-span-4 ${showFilters ? 'col-span-5' : 'col-span-5'}`}>
  {  
    plight.plights.plights ? 
  <UpdateProductDetails
      handleColumnSort={handleColumnSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      currentItems={plight.plights.plights}
      role = {role}
      userid = {userid}
      optionsCategory = {optionsCategory}
     
    ></UpdateProductDetails>:<></>
  }


  </div>
</div>



        <div className=" flex justify-end mb-10">
          <div className="flex flex-col ">
            <div className="flex flex-1 mt-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
              >
                <AiFillCaretLeft />
              </button>
              <div className=" ">{getPages()}</div>

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
              >


                <AiFillCaretRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      </> ):
      
      checkRights  ? 
      <ScreenRights></ScreenRights> : <></>}

                        
      </>
  );
};
   


export default UpdateProducts