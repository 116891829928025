import { useEffect, useState } from "react";
import axios from "axios";

import env from "react-dotenv";

const usePageSetting = (screen_name) => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = { screen_name: screen_name };

        const queryString = encodeURIComponent(JSON.stringify(payload));

        const response = await axios.get(
          `${env.API_URL}page_settings/get_pageSetting_id_by_name`,
          {
            params: payload,
          }
        );

        setSettings(response.data.PageSetting);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [screen_name]);

  return settings;
};

export default usePageSetting;
