import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { rmarkUpData } from "../helpers/AuthStore/rmarkupSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState, useMemo } from "react";
import { TiTick } from "react-icons/ti";
import ResellerMarkupAdd from "./ResellerMarkupAdd";
import { MdDelete } from "react-icons/md";
import GetApiData from "../helpers/getApiData";
import { createContext } from "react";
import { useFormik } from "formik";
import { IoIosCreate } from "react-icons/io";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import AuthScreen from "../utils/AuthScreen";
import ScreenRights from "../utils/ScreenRights";
import { useNavigate } from "react-router-dom";
import { AiFillInfoCircle } from "react-icons/ai";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";
import { AiOutlineSearch } from "react-icons/ai";
import { CreateCustomerGroupValidator } from "../utils/validateAPI";
import MarkupCustomerGroup from "./MarkupCustomerGroup";
import usePageSetting from "../utils/usePageSetting";

import postApiData from "../helpers/postApiData";
import PermsTab from "./PermsTab";
import ToasterAlert from "../utils/ToasterAlert";
export const GroupContext = createContext("");

const ResellerMarkup = () => {
  const dispatch = useDispatch();
  let rmarkup = useSelector((state) => state.rmarkup);
  
  const [showModal, setShowModal] = useState(false);
  
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [stockThreshold, setstockThreshold] = useState();
  const [globalMarkup, setglobalMarkupd] = useState();
  const [showCustomerGroupModal, setshowCustomerGroupModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [optionCustomer, setOptionCustomer] = useState();

  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
const screen_name = '/r_markup';
const checkRights = useAuthScreenCheck(user_id, screen_name);


const pageSettings = usePageSetting(screen_name);



  const [option, setOptions] = useState({});


  const itemsPerPage = 100;
  const [MarkupState, SetMarkupState] = useState({});
  const filteredScreen = rmarkup
    ? rmarkup.rmarkups.reseller_markup
      ? rmarkup.rmarkups.reseller_markup.filter((item) =>
          item.name != null
            ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
            : "No Name"
        )
      : []
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredScreen.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredScreen.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useLayoutEffect(() => {
    return () => {
    };
  }, [rmarkup.rmarkups.reseller_markup]);

  const optionElements = useMemo(() => {
    return (
      optionCustomer &&
      optionCustomer.map((option) => (
        <option
          key={option.key}
          className="scrollbar-thumb-maincolor"
          value={option.value}
        >
          {option.value}
        </option>
      ))
    );
  }, [optionCustomer, showCustomerGroupModal]);

  useEffect(() => {
    
    dispatch(rmarkUpData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(rmarkUpData());
  }, [showModal, showModalCreate]);


  
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validate: CreateCustomerGroupValidator,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);

      return false;
    },
  });
  const handleDeleteButton = async (id, name) => {
  
    const val = { reseller_rule_name: name, id: id };
    await postApiData("product/reseller_delete_markup", val);
    dispatch(rmarkUpData());
  };

  useEffect(() => {
    const fetchData = async function () {
      const data = await GetApiData("product/reseller_get_product_stock", "");
    
      setstockThreshold(data[0].reseller_stock_threshold);

      setglobalMarkupd(data[0].reseller_markup);

      const new_data = await GetApiData("auth/get_resellers", "");
  
      const results = [];
      if (new_data) {
        new_data.user.map((value) => {
          results.push({
            value: value._id,
            label: value.name,
          });
        });

        setOptions([...results]);
      }
    };

    fetchData();
  }, []);

  const handleSavePublish = async (event) => {
    event.preventDefault();

    const stockThreshold = event.target.elements.stockThreshold.value;
    const globalMarkup = event.target.elements.globalMarkup.value;
    const globalType = "global";
    const sign = "percentage";

    let value = await postApiData("product/reseller_post_markups_global", {
      markup: parseFloat(globalMarkup),
      stock_threshold: parseFloat(stockThreshold),
      sign: sign,
      type: globalType,
    });
  };

  return (
    <>{ checkRights && checkRights == true ? ( <>
    


    <ToasterAlert></ToasterAlert>
    
        <PermsTab></PermsTab>

      <Header></Header>
      <MenuHeader></MenuHeader>

      {showModalCreate ? (
        <ResellerMarkupAdd
          props={setShowModalCreate}
          markupState={MarkupState}
          isCustomer={false}
        ></ResellerMarkupAdd>
      ) : null}

      <div className="mx-2">

      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}  
 
              </Tooltip>
            </div>
            </div>
        <form className="flex flex-row" onSubmit={handleSavePublish}>
   
        

          <div>
        


            <label
              htmlFor="stockThreshold"
              className="mb-3  font-dmsans font-semibold text-subheading-400 text-maincolor"
            >
              Stock Threshold
            </label>
            <input
              id="stockThreshold"
              name="stockThreshold"
              type="number"
              value={stockThreshold}
              onChange={(e) => setstockThreshold(e.target.value)}
              className=" mx-2 w-20 placeholder:font-dmsans border-b p-2 focus:outline-none text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
            />
          </div>
          <div>
            <label
              htmlFor="globalMarkup"
              className="mb-3 font-dmsans font-semibold text-subheading-400 text-maincolor"
            >
              Global Markup
            </label>
            <input
              id="globalMarkup"
              name="globalMarkup"
              type="number"
              min ={0}
              onChange={(e) => setglobalMarkupd(e.target.value)}
              value={globalMarkup}
              className="w-20 placeholder:font-dmsans border-b p-2 focus:outline-none text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
            />
          </div>


        


          <button
            type="submit"
            className="mx-2 flex font-dmsans items-center justify-center w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
          >
            Save
            <TiTick />
          </button>
        </form>

        <div className="p-0">
          <button
            onClick={() => {
              setShowModalCreate(true);
            }}
            className="flex items-center justify-center w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
          >
            <IoIosCreate className="mr-2" />
            Add Rule
          </button>
        </div>

        <div className="flex flex-col min-h-screen bg-transparent">
          <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
            <div className="absolute flex-shrink-0 items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>

              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Rules..."
                  className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
            <div className="p-8 md:p-14">
              <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                Reseller Mark Up Rules
              </span>

              <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name of Rule
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sign
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Value
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Reseller
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>

                      <th className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentItems.map((item) => (
                      <tr key={item._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.name}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.valueType}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.valuePriceSign}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.value}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.nameofcatdis}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.reseller}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() =>
                              handleDeleteButton(item._id, item.name)
                            }
                            href="#"
                            className="text-extra-color hover:extra-color"
                          >
                            <MdDelete />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
      </> ):
      
      checkRights  ? 
      <ScreenRights></ScreenRights> : <></>}

                        
      </>
  );
};

export default ResellerMarkup;
