import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const metricsData = createAsyncThunk(
  "metrics",
  async (payload, { rejectWithValue }) => {
    try {
   const queryString = encodeURIComponent(JSON.stringify(payload));
      
      const request = await axios.get( `${env.API_URL}product/get_metrics_data/${queryString}`);
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const metricsSlice = createSlice({
  name: "metrics",
  initialState: {
    metrics: [],
    performanceBrands :[],
    performanceData:[],
    competitiveness :[],
    competitors : [],


    loading: false,
    error: null,
  },
  reducers: {
    addMetrics: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(metricsData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(metricsData.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(metricsData.fulfilled, (state, action) => {
        state.loading = false;
        state.metrics = action.payload;
      });
  },
});

export const { addMetrics } = metricsSlice.actions;
export default metricsSlice.reducer;
