import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import getApiData from "../helpers/getApiData";
import { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ProductBox from "../Products/ProductBox";
import { AiFillInfoCircle } from "react-icons/ai";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";
import ScreenRights from "../utils/ScreenRights";
import usePageSetting from "../utils/usePageSetting";


import { useNavigate } from "react-router-dom";
import ToasterAlert from "../utils/ToasterAlert";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
const SearchProducts = () => {
  const [optionsValue, setOptionsValue] = useState([]);
  const [selectedOption, setSelectedOptions] = useState();

  const user_id = JSON.parse(localStorage.getItem("user_data"))._id;
  const screen_name = "/search_products";
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);

  const navigate = useNavigate();
  const options = [
    {
      id: 1,
      name: "description",
    },
    {
      id: 2,
      name: "sku",
    },

    {
      id: 3,
      name: "name",
    },
  ];

  const optionElements = useMemo(() => {
    return optionsValue.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsValue]);

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [role, setRole] = useState();

  const fetchData = async () => {
    try {
      const results = [];
      results.push({ key: 0, value: "" });
      options.map((value) => {
        results.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionsValue([...results]);

      const response = await getApiData("product/get_products", "");

      setAllProducts(response);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useLayoutEffect(() => {
    fetchData();

    return () => {
      if (localStorage.getItem("user_data")) {
        setRole(JSON.parse(localStorage.getItem("user_data")).role);
      }
    };
  }, []);

  const memoizedProductBox = useMemo(
    () => <ProductBox currentItems={searchResults && searchResults} />,
    [searchResults]
  );

  const handleSelected = (selected) => {
    setSelectedOptions(selected);
  };

  useEffect(() => {
    if (searchText && searchText.length >= 2) {
      const filteredResults =
        allProducts &&
        allProducts.filter(
          (product) =>
            (product.name.toLowerCase().includes(searchText.toLowerCase()) &&
              selectedOption == "name") ||
            (product.sku &&
              selectedOption == "sku" &&
              product.sku.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.description &&
              selectedOption == "description" &&
              product.description
                .toLowerCase()
                .includes(searchText.toLowerCase()))
        );

      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [searchText, allProducts, selectedOption]);

  return (
    <>
      {checkRights && checkRights == true ? (
        <>
          <div>
            <ToasterAlert></ToasterAlert>

            <Header></Header>
            <MenuHeader></MenuHeader>

            <div className="mx-2 ">
            <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}        
 
              </Tooltip>
            </div>
            </div>



              <div className="z-50 translate-y-1/3">
                <div className="relative w-full max-w-lg">
                  <form>
                    <div className="flex justify-between overflow-hidden rounded-md bg-white shadow shadow-black/20">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        type="search"
                        className="bg-gray-50 border flex-shrink-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                        placeholder="Search product... (Name or by SKU)"
                      />
                    </div>
                  </form>
                </div>

                <div className="flex flex-col mb-5 mt-7">
                  <label className="text-sm mb-1 text-gray-500">
                    Search Type
                  </label>
                  <select
                    onChange={(e) => handleSelected(e.target.value)}
                    className="scrollbar scrollbar-thumb-maincolor block w-full sm:w-56 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-maincolor focus:border-maincolor"
                  >
                    {optionElements}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center h-fit bg-transparent">
              <div className="container mx-auto px-4">
                {searchText && memoizedProductBox}
              </div>
            </div>
          </div>
        </>
      ) : checkRights ? (
        <ScreenRights></ScreenRights>
      ) : (
        <></>
      )}
    </>
  );
};

export default SearchProducts;
