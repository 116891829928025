import React from "react";

import { markUpData } from "../helpers/AuthStore/customerMarkupSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState,useMemo } from "react";
import MarkupAdd from "./MarkupAdd";
import { MdDelete } from "react-icons/md";
import { IoIosCreate } from "react-icons/io";

import { useFormik } from "formik";
import { AiOutlineEdit } from "react-icons/ai";

import { toast, Toaster, ToastBar } from "react-hot-toast";
import { AiOutlineSearch } from "react-icons/ai";
import { CreateCustomerGroupValidator} from '../utils/validateAPI';


import postApiData from "../helpers/postApiData";
import MarkupCustomerGroupUpdate from "./MarkupCustomerGroupUpdate";

const MarkupCustomerGroup = (customerGroup) => {

  const dispatch = useDispatch();
  let customer_markup = useSelector((state) => state.customer_markup);
  
  const [showModal, setShowModal] = useState(false);
  const [showModalCreate, setShowModalCreate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  
  const [optionCustomer, setOptionCustomer] = useState();

  const [option, setOptions] = useState({});
  
  const itemsPerPage = 100;
  const [MarkupState, SetMarkupState] = useState({});
  const filteredScreen = customer_markup
    ? customer_markup.markups.markup
      ? customer_markup.markups.markup.filter((item) =>
          item.name != null
            ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
            : "No Name"
        )
      : []
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredScreen.slice(indexOfFirstItem, indexOfLastItem);


  useLayoutEffect(() => {
    return () => {

    };
  }, [customer_markup.markups]);




  useEffect(() => {
    dispatch(markUpData());
  }, [showModal, showModalCreate]);
  const formik = useFormik({
    initialValues: {
      name:""
    },
    validate: CreateCustomerGroupValidator,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      
      
   
      return false;
    },
  });


  const handleUpdate = async (id, item) => {
    SetMarkupState(item);
    setShowModal(true);

  };


  const handleDeleteButton = async (id, name) => {
  
    const val = { rule_name: name, id: id };
    await postApiData("customer/delete_rules", val);
    dispatch(markUpData());
  };


  return (
    <>
      <Toaster
        toastOptions={{
          duration: 1000,
          className: "",
          success: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
            },
          },
          error: {
            style: {
              border: "2px solid #f5621c",
              padding: "16px",
              color: "#f5621c",
            },
          },
        }}
        position="top-center"
        reverseOrder="false"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== "loading" && (
                  <button
                    className="close-icon"
                    onClick={() => {
                      toast.dismiss(t.id);
                    }}
                  ></button>
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>


      {showModalCreate ? (
        <MarkupAdd
          props={setShowModalCreate}
          markupState={MarkupState}
          isCustomer ={true}
        ></MarkupAdd>
      ) : null}



{showModal ? (
        <MarkupCustomerGroupUpdate
       
          markupState={MarkupState}
          isCustomer={true}
          props={setShowModal}
        ></MarkupCustomerGroupUpdate>

      ) : null}


      <div className="container mx-auto mt-5 ">


        <div className="flex flex-col min-h-screen bg-transparent">
          <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
            <div className="absolute flex-shrink-0 items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>

              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Rules..."
                  className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
            <div className="p-8 md:p-14">
              <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                Mark Up Rules for Customers
              </span>

              
        <div className="p-0">
          <button
            onClick={() => {
              setShowModalCreate(true);
            }}
            className="flex items-center justify-center w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
          >
            <IoIosCreate className="mr-2" />
            Add Rule
          </button>
        </div>

              <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name of Rule
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Type
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Sign
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Value
                      </th>


                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                       
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Customer Group 
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>

                      <th className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentItems.map((item) => (
                      <tr key={item._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.name}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.valueType}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.valuePriceSign}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.value}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.nameofcatdis}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.customerGroupInfo.name}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() =>
                              handleDeleteButton(item._id, item.name)
                            }
                            href="#"
                            className="text-extra-color hover:extra-color"
                          >
                            <MdDelete />
                          </button>
                        </td>


                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button
                            onClick={() =>
                              handleUpdate(item._id, item)
                            }
                            href="#"
                            className="text-extra-color hover:extra-color"
                          >
                            <AiOutlineEdit />
                          </button>
                        </td>


                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
    </div>
    </div>
    
    </>
  )
}

export default React.memo(MarkupCustomerGroup)