import React, { useState, useEffect, useCallback } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import logo from "../../src/assets/logo.png";

import postApiData from "../helpers/postApiData";
import ProductBox from './ProductBox';
import usePageSetting from "../utils/usePageSetting";

const ListPage = React.memo(() => {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [value, setValue] = useState({});
  const [data ,setdata] = useState();
  const [code, setCode] = useState("");
  const [tet, setT] = useState({});
  const [showResults, setShowResults] = useState(false);
  
  const fetchData = useCallback(async () => {
    if (searchText) {
      let response = await postApiData("product/search_product", { value: searchText });
     
      setSearchResults(response.data);
    } else {
      setSearchResults([]);
    }
  }, [searchText]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearchResult = async (product) => {
    setValue(product.name);
    setCode(product.new_code);
    setSearchResults([]);
    setShowResults(false);
    if (product) {

      let response = await postApiData("product/product_model", { productName: product.new_code, name: product.name });
   
      setdata(response);

    }
  };

  return (
    <>
      <div>
        <nav className="bg-maincolor">
        
<nav className="bg-maincolor">
<div className="mx-auto max-w-7xl">
  <div className="relative flex h-16 items-center justify-between">
    <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
      <div className="flex flex-shrink-0 items-center rounded-full">
        <img
          className="block h-8 w-auto lg:hidden"
          src={logo}
          alt="Sip Desk"
        />
        <img
          className="hidden h-8 w-auto lg:block"
          src={logo}
          alt="Sip Disk"
        />
      </div>
    </div>

    <div className="flex flex-1 items-start justify-start sm:justify-start">
      <div className="flex flex-shrink-0 items-center">
        <label htmlFor="simple-search" className="sr-only">
          Search
        </label>
        <div className="absolute w-32 lg:w-72 sm:w-72 md:w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
          </div>
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="search"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
            placeholder="Search product..."
          />
        </div>
        {searchResults && searchResults.length > 0 && !showResults && (
      <div className="absolute z-10 w-full mt-96 scroll scrollbar-thumb-maincolor max-h-80 overflow-y-auto bg-white rounded-md shadow-lg sm:w-auto">
        {searchResults.map((product) => (
          <div
            key={product._id}
            className="px-4 scrollbar scrollbar-thumb-maincolor  py-2 cursor-pointer hover:bg-gray-100"
            onClick={() => handleSearchResult(product)}
          >
            <span className="font-dmsans font-medium">{product.name}</span>
          </div>
        ))}
      </div>
    )}

      </div>
    </div>
  </div>
</div>
</nav>

        </nav>
    
        {searchResults && searchResults.length > 0 && (
          <div className="absolute z-10 w-full mt-96 scroll scrollbar-thumb-maincolor max-h-80 overflow-y-auto bg-white rounded-md shadow-lg sm:w-auto">
            {searchResults.map((product) => (
              <div
                key={product._id}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleSearchResult(product)}
              >
                <img
                  src={product.image_url}
                  alt={product.name}
                  className="w-8 h-8 mr-2"
                />
                <span className="font-dmsans font-medium">{product.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className=" flex items-center  justify-center min-h-auto  bg-transparent">
        <div className="flex flex-col justify-center  ">
          <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
            List Product
          </span>
<ProductBox currentItems={data && data.products.length>0 && data.products}></ProductBox>


</div>
</div>
    </>
  );
});

export default ListPage;
