import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import { useState, useEffect,useMemo } from "react";
import postApiData from "../helpers/postApiData";

const ResellerProductDetail = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,
}) => {
  
  

  return (
    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
      <div className="p-8 md:p-14">
        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
          Product List
        </span>

        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  STOCK
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Image
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("name")}
                    className="flex items-center space-x-1"
                  >
                    NAME
                    {sortColumn === "name" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <button
                    onClick={() => handleColumnSort("price")}
                    className="flex items-center space-x-1"
                  >
                    PRICE
                    {sortColumn === "price" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>

          

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("description")}
                    className="flex items-center space-x-1"
                  >
                    DESCRIPTION
                    {sortColumn === "description" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("category")}
                    className="flex items-center space-x-1"
                  >
                    CATEGORY
                    {sortColumn === "category" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  DISTRIBUTOR
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Weight
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Brand
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  MARK UP
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Price After Mark Up
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Mark Up Type
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Reseller Name
                </th>


                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Stock Thresh Hold
                </th>
                <th className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {
                currentItems &&
                currentItems.map((item) => (
                  <tr key={item._id}>
                  

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.stock}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        <img src={item.image_url}></img>
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.price && item.price.toFixed(2)} {item.price >=0 ? "£":""}
                      </div>
                    </td>

                
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.description}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.status}
                      </div>
                    </td>

                    
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.category}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {item.distributor}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.weight}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.brand}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      {item.filteredProducts.reseller_markupPriceSign == "%" ? "%" : "£"}
                        
                        {item.filteredProducts.reseller_markup}
                      
                        
                      </div>
                    </td>



                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      {item.markup_price >=0 ? "£":""}
                        
                        {item.filteredProducts.reseller_markup_price && item.filteredProducts.reseller_markup_price.toFixed(2)}
                        
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.filteredProducts.reseller_markupType}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.userData.username}
                      </div>
                    </td>
                    

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.stock_threshold}
                      </div>
                    </td>

                    
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ResellerProductDetail;
