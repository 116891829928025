import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { categoryData } from "../helpers/AuthStore/categorySlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState } from "react";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import usePageSetting from "../utils/usePageSetting";


import { Tooltip } from "react-tooltip";
import ScreenRights from "../utils/ScreenRights";
import { customStyleToolTip } from "../utils/customStyleToolTip";

import { AiFillInfoCircle } from "react-icons/ai";



import { useNavigate } from "react-router-dom";
import { CircularLoader } from "../Container/CircularLoader";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import { AiOutlineSearch } from "react-icons/ai";
import CategoryUpdate from "./CategoryUpdate";
import ToasterAlert from "../utils/ToasterAlert";
import postApiData from "../helpers/postApiData";
import AuthScreen from "../utils/AuthScreen";

const Category = () => {
  const dispatch = useDispatch();
  let category = useSelector((state) => state.category);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  const [categoryState, setCategoryState] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [CircularProgress, setCircularProgress] = useState(false);

  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
  const screen_name = '/category_list';
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);



  const filteredScreen = category.categories.category
    ? category.categories.category.filter((item) =>
        item.name != null
          ? item.name.toLowerCase().includes(searchQuery.toLowerCase())
          : "No Name"
      )
    : [];

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredScreen.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredScreen.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    if (category.categories.length <= 0) {
      dispatch(categoryData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (category.categories.length <= 0) {
      dispatch(categoryData());
    }
  }, [showModal]);

  const handleUpdateClick = async (item) => {
    setCategoryState(item);

    setShowModal(true);
  };

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };

  return (
    <>
    {checkRights == true ? ( <>
    
      {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

      <ToasterAlert />

      <Header></Header>
      <MenuHeader></MenuHeader>

      {showModal ? (
        <CategoryUpdate
          categoryState={categoryState}
          props={setShowModal}
          categoryData={categoryData}
        ></CategoryUpdate>
      ) : null}

      <div className="mx-2">
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}    
 
              </Tooltip>
            </div>
            </div>



        <div className="flex flex-col min-h-screen bg-transparent mt-2">
          <button
            onClick={async () => {
              setCircularProgress(true);

              await postApiData("product/push_category", "");

              setCircularProgress(false);
            }}
            type="submit"
            className="w-fit border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
          >
            Push Categories Provu
          </button>

          <div className="flex mx-8 mt-10 items-end justify-end sm:justify-end ">
            <div className="absolute flex-shrink-0 items-center">
              <label htmlFor="simple-search" className="sr-only">
                Search
              </label>

              <div className="relative">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search Category"
                  className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                />
                <div className="absolute  inset-y-0 right-0 flex items-center pr-3 pointer-events-none z-10">
                  <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                </div>
              </div>
            </div>
          </div>

          <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
            <div className="p-8 md:p-14">
              <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                Category List
              </span>

              <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Name
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Image
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Thumb Image
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Publish
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Hide in Navigation
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Menu Title
                      </th>

                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Menu Description
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Distributor
                      </th>

                      <th className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {currentItems.map((item) => (
                      <tr key={item._id}>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.name}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            <img className="w-20" src={item.image_url}></img>
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            <img className="w-20" src={item.thumb_image}></img>
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.publish == false
                              ? "Not Published"
                              : "Published"}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.hide_in_navigation == false
                              ? "Not Hidden"
                              : " Hidden"}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.menu_title}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.menu_desc}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.desc}
                          </div>
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                            {item.distributor}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className=" flex justify-end mb-10">
            <div className="flex flex-col ">
              <div className="flex flex-1 mt-2">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
                >
                  <AiFillCaretLeft />
                </button>
                <div className=" ">{getPages()}</div>

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
                >
                  <AiFillCaretRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </>):
   
      
   checkRights  ? 
   <ScreenRights></ScreenRights> : <></>}

           
           </>
  );
};

export default Category;
