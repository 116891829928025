import { useEffect, useState } from "react";
import axios from "axios";

import env from "react-dotenv";

const useTabSetting = (screen_name) => {
  const [name, setHead] = useState(null);
  const [key, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = { url: screen_name };

        const queryString = encodeURIComponent(JSON.stringify(payload));

        const response = await axios.get(
          `${env.API_URL}page_settings/get_table_by_url`,
          {
            params: payload,
          }
        );

        
        setHead(response.data.name);
        setData(response.data.key);

      } catch (error) {
        console.error("Error fetching key:", error);
      }
    };

    fetchData();
  }, [screen_name]);

  return [name,key];
};

export default useTabSetting;
