import React, { useLayoutEffect, useState } from "react";
import CategoryMain from "./CategoryMain";
import CategoryUpdate from "./CategoryUpdate";
import { createContext } from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { Link } from "react-router-dom";

import ScreenRights from "../utils/ScreenRights";
import usePageSetting from "../utils/usePageSetting";

import { AiFillInfoCircle } from "react-icons/ai";

import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";


import useAuthScreenCheck from "../utils/useAuthScreenCheck";
export const CategoryContext = createContext();

const CategoryDetail = () => {
  const [categoryState, setCategoryState] = useState({});


  const [props, setprops] = useState(false);

  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
  const screen_name = '/cat_detail';
  const checkRights = useAuthScreenCheck(user_id, screen_name);
  const pageSettings = usePageSetting(screen_name);



  return (
    <>
     {checkRights == true ? (<>
      <Header />
      <MenuHeader />

      <div className="mx-2">
     
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}  
 
              </Tooltip>
            </div>
            </div>



        <div className="flex flex-col  bg-transparent mt-2">
          <span className="py-4 text-xl text-left text-gray-500 font-dmsans">
            To view or edit products within this category,
            <Link to="/product_list" state={categoryState.name}>
              click here
            </Link>
          </span>
        </div>

        <div className="flex flex-col min-h-screen bg-transparent mt-2">
          <div className="grid grid-cols-1 gap-2 md:grid-cols-5">
            <div className="w-full md:w-44">
              <CategoryContext.Provider
                value={{ categoryState, setCategoryState }}
              >
                <CategoryMain
                  updateProp={setprops}
                  proper={props}
                  categoryState={categoryState}
                />
              </CategoryContext.Provider>
            </div>
            <div className="container mx-auto col-span-1 h-fit relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl w-full  lg:w-full md:w-auto    md:col-span-4">
              <div className="p-8 md:p-14">
                <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
                  Category List
                </span>

                <div className="container mx-auto">
                  <div className="overflow-auto scrollbar-thin scrollbar-thumb-maincolor max-h-96">
                    <CategoryUpdate
                      categoryState={categoryState}
                      setCategoryState={setCategoryState}
                      sproper={setprops}
                      proper={props}
                      onChange={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> </>) : 
      
      checkRights  ? 
      <ScreenRights></ScreenRights> : <></>}

             
      

    </>
  );
};

export default CategoryDetail;
