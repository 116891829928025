import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";

import { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ProductBox from "../Products/ProductBox";
import getApiData from "../helpers/getApiData";
import ProductDetail from "./ProductDetail";
import ToasterAlert from "../utils/ToasterAlert";
import { CircularLoader } from "../Container/CircularLoader";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
const ProductSimilarSearch = () => {
  const [selectedOption, setSelectedOptions] = useState();

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [CircularProgress,setCircularProgress] = useState("");

  const [role, setRole] = useState();
  const [rules, setRules] = useState();
  const fetchData = async () => {
    try {
      const response = await getApiData("product/get_rules", "");

      setRules(response.rules);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useLayoutEffect(() => {
    fetchData();

    return () => {
      if (localStorage.getItem("user_data")) {
        setRole(JSON.parse(localStorage.getItem("user_data")).role);
      }
    };
  }, []);

  const memoizedProductBox = useMemo(
    () => <ProductDetail currentItems={searchResults && searchResults} />,
    [searchResults]
  );

  const handleSelected = (selected) => {
    setSelectedOptions(selected);
  };

  const handleSearch = async () => {
    if (searchText && searchText.length >= 2) {
      const searchTextEncoded = encodeURIComponent(searchText);

        setCircularProgress(true);

      const filteredProducts = await getApiData(
        "product/get_products_similar",
        searchTextEncoded
      );



      setSearchResults(filteredProducts.product);

      setCircularProgress(false);
        

    } else {
      setSearchResults([]);
    }
  };

  return (
    <div>
      <ToasterAlert></ToasterAlert>
      <Header></Header>
      <MenuHeader></MenuHeader>

      {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

      <div className="mb-7 items-center justify-center lg:mr-44 md:mr-15 sm:mr-10 xl:mr-44 2xl:mr-44 ml-20 ">
        <div className="z-50 translate-y-1/3 ">
          <div className="items-center mx-auto container my-2 ">
            <span className="py-4 text-xl text-left text-gray-500 font-dmsans">
              Product Similar Search by Name
            </span>
          </div>

          <div className="items-center mx-auto container my-2 0">
            
            <span className="py-4 text-sm text-left text-maincolor font-dmsans">
              EAN similarity Search (%): {rules && rules.ean_similar_search}
            </span>
          </div>
          <div className="items-center mx-auto container my-2 0">
            
            <span className="py-4 text-sm text-left text-maincolor font-dmsans">
              Name similarity Search (%): {rules && rules.name_similar_search}
            </span>
          </div>
          <div className="items-center mx-auto container my-2 0">
            
            <span className="py-4 text-sm text-left text-maincolor font-dmsans">
              SKU similarity Search (%): {rules && rules.sku_similar_search}
            </span>
          </div>
          <div className="items-center mx-auto container my-2 0">
            
            <span className="py-4 text-sm text-left text-maincolor font-dmsans">
              Description similarity Search (%):
              {rules && rules.desc_similar_search}
            </span>
          </div>

          <div className="items-center mx-auto container my-2 0">

          <div className="relative w-full max-w-lg">
            <form>
              <div className="flex justify-between overflow-hidden rounded-md bg-white shadow shadow-black/20">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                </div>
                <input
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  type="search"
                  className="bg-gray-50 border flex-shrink-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                  placeholder="Search product... by Name"
                />
              </div>
            </form>
          </div>

          <button
            onClick={handleSearch}
            className="flex mt-2 font-dmsans items-center justify-center w-auto border h-12 bg-maincolor text-white border-gray-300 text-md p-3 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
          >
            Search Products
          </button>
          </div>
        </div>
      </div>
      
      <div className="flex items-left justify-left h-fit bg-transparent mt-44">
        <div className="container mx-auto px-4">
          {searchResults && searchResults.length > 0 ? (
            <ProductBox
              currentItems={searchResults && searchResults}
            ></ProductBox>
          ) : (
            <>            <span className="py-4 text-sm text-left text-maincolor font-dmsans">No Products Found</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductSimilarSearch;
