import { toast } from "react-hot-toast";
import env from "react-dotenv";
import axios from "axios";





export async function ValidationLogin(values) {
  const errors = verify({}, values);

  return errors;
}

function verify(error = {}, values) {
  
  if (!values.password) {
    error.password = toast.error("Password Required");
  }
  if (values.password.includes(" ")) {
    error.password = toast("Invalid Password");
  }

  if (!values.username) {
    error.username = toast.error("User Name Required");
  }

  const userValues = { username: values.username, password: values.password };

  axios
    .post(env.API_URL + "auth/login", userValues)
    .then((response) => {
      if (response.status === 200) {
        error.success = toast.success("Login Successful!");

   

      }
    })
    .catch((err) => {

      if (err.response && err.response.status === 503) {
        error.option = toast.error(
          "Server is currently unavailable. Please try again later."
        );
      } else if (err.response && err.response.status === 401) {
        error.username = toast.error("Invalid ID/password");
      } else if (err.code === "ECONNREFUSED" || err.code === "ERR_NETWORK") {
        error.option = toast.error(
          "Server is currently unavailable!"
        );
      } else {
        error.option = toast.error("An error occurred while fetching data.");
      }
    });

  return error;
}
