import React from "react";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AiOutlineArrowUp } from "react-icons/ai";
import { useState, useMemo } from "react";
import postApiData from "../helpers/postApiData";
import { GrDocumentUpdate } from "react-icons/gr";

import { CircularLoader } from "../Container/CircularLoader";
import { AiOutlinePlus } from "react-icons/ai";
const UpdateProductDetails = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,
  role,
  userid,
  optionsCategory,
}) => {
  const [CircularProgress, setCircularProgress] = useState(false);


  const [imageSrcArray, setImageSrcArray] = useState(
    currentItems.map((item) => item.image_url) // Initialize with existing image URLs
  );
  const [descArray, setDescArray] = useState(
    currentItems.map((item) => item.description) // Initialize with existing descriptions
  );
  const [stockArray, setStockArray] = useState(
    currentItems.map((item) => item.stock.toString()) // Initialize with existing stock values as strings
  );

const [nameArray, setNameArray] = useState(
    currentItems.map((item) => item.name.toString()) // Initialize with existing stock values as strings
  );
  
  const [categoryArray, setCategoryArray] = useState(
    currentItems.map((item) => item.category == null || !item.category  ? "" :item.category.toString()), // Initialize with existing stock values as strings

  );

  const [skuArray, setSkuArray] = useState(
    currentItems.map((item) => item.sku == null || !item.sku  ? "" :item.sku.toString()), // Initialize with existing stock values as strings

  );
  

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const newImageSrcArray = [...imageSrcArray];
        newImageSrcArray[index] = e.target.result;
        setImageSrcArray(newImageSrcArray);
      };
      reader.readAsDataURL(file);
    }
  };

  const optionElementsCategory = useMemo((initalVal) => {
    return optionsCategory.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));

  }, [optionsCategory]);

  const handleCheckboxChange = async (
    productId,
    desc,
    index,
    stock,
    category
  ) => {
    try {
      setCircularProgress(true);
      const formData = new FormData();
      formData.append("stock", stockArray[index]); // Use stockArray value at the given index
      formData.append("ID", productId);
      formData.append("description", descArray[index]); // Use descArray value at the given index
      formData.append("avatar", imageSrcArray[index]);
      // formData.append("avatar", imageSrcArray[index]);


      formData.append("category", categoryArray[index]);
      formData.append("name", nameArray[index]);
      
      await postApiData("product/update_product_profile", formData);

      setCircularProgress(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
      <div className="p-8 md:p-14">
        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
          Product List
        </span>

        {CircularLoader == true ? <CircularLoader></CircularLoader> : <></>}

        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {role == "admin" ? (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Update Product
                  </th>
                ) : (
                  <></>
                )}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  STOCK
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  sku
                </th>
                
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("name")}
                    className="flex items-center space-x-1"
                  >
                    NAME
                    {sortColumn === "name" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <button
                    onClick={() => handleColumnSort("description")}
                    className="flex items-center space-x-1"
                  >
                    DESCRIPTION
                    {sortColumn === "description" && (
                      <span
                        className={`${
                          sortDirection === "asc"
                            ? "text-gray-600"
                            : "text-maincolor dark:text-maincolor"
                        }`}
                      >
                        {sortDirection === "asc" ? (
                          <AiOutlineArrowUp />
                        ) : (
                          <AiOutlineArrowDown />
                        )}
                      </span>
                    )}
                  </button>
                </th>

                <th className="relative px-6 py-3">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems &&
                currentItems.map((item, index) => (
                  <tr key={index}>
                    {role == "admin" ? (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          <button
                            className="flex items-center justify-center w-auto border h-12 bg-white  text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                            onClick={(e) =>
                              handleCheckboxChange(
                                item._id,
                                item.description,
                                index,
                                item.stock,
                                item.category
                              )
                            }
                          >
                            <span className="  text-maincolor mx-2">
                              Update
                            </span>
                            <GrDocumentUpdate className="text-maincolor " />
                          </button>
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        <input
                          id={`stock_${index}`}

                          type="number"
                          min={0}

                          defaultValue={item.stock}
                          onChange={(e) => {
                            const newStockArray = [...stockArray];
                            newStockArray[index] = e.target.value;

                            setStockArray(newStockArray);
                          }}
                          className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                        ></input>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{item.sku}</td>


                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="py-4 flex justify-center items-center space-x-2">
                        <label className="cursor-pointer bg-transparent  text-base  leading-5 font-normal  font-dmsans  text-textColor  py-2 px-4 rounded-lg">
                          <AiOutlinePlus></AiOutlinePlus>
                          <input
                            type="file"
                            className="hidden"
                            onChange={(e) => handleImageChange(e, index)}
                            name={`avatar_${index}`} // Ensure each input has a unique name
                            id={`avatar_${index}`} // Ensure each input has a unique ID
                            accept="image/*"
                          />
                        </label>
                      </div>

                      <div className="text-sm font-medium text-gray-900 whitespace-normal w-16 h-16">
                        <img
                          src={
                            imageSrcArray[index]
                              ? imageSrcArray[index]
                              : item.image_url
                          }
                          alt={`Product ${index + 1}`}
                        />
                      </div>
                    </td>



                    <td className="px-6 py-4 whitespace-nowrap">
                   
                      <select
                      id={`category${index}`}
                          
                        value={categoryArray[index]}
                        defaultValue={item.category}
                        selected = {item.category}
                        onChange={(e) => {
                          const newCategoryArray = [...categoryArray];
                          newCategoryArray[index] = e.target.value;
                          
                          setCategoryArray(newCategoryArray);
                        }}
                        className="scrollbar scrollbar-thumb-maincolor block w-full sm:w-56 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-maincolor focus:border-maincolor"
                      >
                        {optionElementsCategory}
                      </select>
                    </td>


                    <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        <textarea
                          id={`desc_${index}`}
                          type="text"
                          defaultValue={item.description}
                          onChange={(e) => {
                            const newDescArray = [...descArray];
                            newDescArray[index] = e.target.value;
                            setDescArray(newDescArray);
                          }}
                          className="w-fit placeholder:font-dmsans overflow:auto scrollbar scrollbar-thumb-maincolor border-b p-2 focus:outline-none text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
                          placeholder="Description"
                        ></textarea>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UpdateProductDetails;
