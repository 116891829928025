import React, { useContext, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CategoryContext } from './CategoryDetail';
import {AiOutlineMinus} from 'react-icons/ai';

function Tree({ treeData }) {
  return (
    <ul>
     
      {treeData && treeData && treeData.map((node) => (
        
        <TreeNode node={node}  key={node._id} />
      ))}
    </ul>
  );
}

function TreeNode({ node }) {
  const { child, name } = node;
  const [showChildren, setShowChildren] = useState(false);
  const { setCategoryState } = useContext(CategoryContext);

  const handleClick = () => {
    setShowChildren(!showChildren);
    setCategoryState(node);
  };

  return (
    <li>
      <div
        className="font-dmsans container mx-auto font-medium flex items-center justify-between space-x-2 cursor-pointer"
        onClick={handleClick}
        style={{ marginBottom: '10px' }}
      >
        <div className="flex items-center space-x-2">
          {child && (
            <AiOutlinePlus className=" text-maincolor text-sm" />
          )  }
  
  

          {
            child  ? 
            
          <span className=" whitespace-normal text-sm  font-semibold text-maincolor ">{name}</span>
          :
         <span className=" whitespace-normal text-sm  mx-7  ">{name}

          
          </span>

          
          }

        </div>  
      </div>
      {showChildren && child && (
       
       <Tree className="mx-20 whitespace-nowrap " treeData={child} />
      )}
    </li>
  );
}

export default Tree;
