
export const customStyleToolTip = {
    backgroundColor: "#f5621c",
    fontFamily: "DMSans, sans-serif",
    flexWrap: "wrap",
    whiteSpace: "normal",
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    flex: 1,
    flexWrap: "wrap",
    whiteSpace: "wrap", 
    maxWidth: "400px", 
  };
  
  