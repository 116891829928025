import React from "react";

import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";
import noProduct from "../assets/noproduct.png";

const ProductBox = ({ currentItems }) => {
  const customStyleToolTip = {
    backgroundColor: "#f5621c",
    fontFamily: "DMSans, sans-serif",
    flexWrap: "wrap",
    whiteSpace: "normal",
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    flex: 1,
    flexWrap: "wrap",
    whiteSpace: "wrap",
    maxWidth: "400px",
  };

  return (
    <>
      <div className="w-auto relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
        <div className="p-8 md:p-14">
          <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
         
          </span>

          <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
            <table className="w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    STOCK
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Image
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    NAME
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    PRICE
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    SKU
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    EAN
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    DESCRIPTION
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    CATEGORY
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    DISTRIBUTOR
                  </th>

                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Brand
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    MARK UP
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Price After Mark Up
                  </th>

                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Mark Up Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Stock Thresh Hold
                  </th>

                  <th className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {currentItems &&
                  currentItems.length > 0 &&
                  currentItems.map((item) => (
                    <tr key={item._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.stock}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        <img
                          src={item.image_url ? item.image_url : noProduct}
                        ></img>

                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                          {item.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.price}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                          {item.sku}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                          {item.ean}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                          <div className="flex grid-row-2 gap-2">
                            <div>{item.description}</div>
                            {item.description ? (
                              <div>
                                <a
                                  data-tooltip-id="main_desc_tooltip"
                                  data-tooltip-content={item.main_desc}
                                  data-tooltip-place="top"
                                  className="bg-red-500  whitespace-normal font-dmsans "
                                >
                                  {item.main_desc ? (
                                    <AiFillInfoCircle className="w-6 h-6  text-maincolor"></AiFillInfoCircle>
                                  ) : (
                                    <></>
                                  )}


                                </a>
                                <Tooltip
                                  id="main_desc_tooltip"
                                  style={customStyleToolTip}
                                  className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
                                ></Tooltip>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>

                      {/* <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900 whitespace-normal">
                      {item.main_desc}
                    </div>
                  </td> */}
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500 whitespace-normal">
                          {item.status}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500 whitespace-normal">
                          {item.category}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {item.distributor}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.brand}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.markup}
                          {item.markupPriceSign == "percentage" ? "%" : ""}
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                        {item.markup_price >= 0 ? "£" : ""}
                          
                          {item.markup_price && item.markup_price.toFixed(2)}
                          
                        </div>
                      </td>

                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.markupType}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.stock_threshold}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBox;
