import React, {  useLayoutEffect ,useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import useToggle from "../../utils/useToggle";
import { VscEye, VscEyeClosed } from "react-icons/vsc";
import logo from "../../assets/logo.png";
import { toast, Toaster, ToastBar } from "react-hot-toast";
import ToasterAlert from "../../utils/ToasterAlert";
import { useLocation } from "react-router-dom";
import { ValidationLogin } from "../../utils/validateLogin";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";

import { loginUser } from "../../helpers/AuthStore/authSlice";

import "./Login.css";

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      navigate: navigate,
    },
    validate: ValidationLogin,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      


      try {
        dispatch(loginUser(values)).then((result) => {
        
          if (result.payload.message=="Successfully Login") {

            navigate("/dashboard");
          }


        });
      } catch (err) {
        console.log(err);
      }

      return false;
    },
  });


  const [isPasswordHideShow, setPasswordHideShow] = useToggle(false);
  

  useEffect(() => {


      if (localStorage.getItem("user_data")) {
        navigate("/dashboard", { replace: true, state: location.state });
      }
    
  },[]);

  
  useToggle(false);

  const ChangePasswordHideShow = () => {
    setPasswordHideShow();
  };

  return (
    <React.Fragment>
  <ToasterAlert></ToasterAlert>

      <div className="bg-maincolor text-white py-4 px-8 flex items-center">
        <img
          src={logo}
          alt="logo"
          className="h-fit w-fit text-gray-300 mr-2"
          viewBox="0 0 20 20"
          fill="currentColor"
        ></img>
      </div>
      <div className="container mx-auto">
        <div className="flex items-center  justify-center min-h-screen  bg-transparent">
          <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
            <div className="flex flex-col justify-center p-8 md:p-14 ">
              <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor ">
                Login in Stock Channel               
              </span>

              <span className="py-4 text-xl  text-center text-gray-500 font-dmsans">
                Explore More by connecting with as Stock Channel Admin.
              </span>
              <form className="py-1" onSubmit={formik.handleSubmit}>
                <div className="py-4 ">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    User Name
                  </span>
                  <input
                    {...formik.getFieldProps("username")}
                    id="username"
                    type="text"
                    className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                    placeholder="admin@gmail.com"
                  ></input>
                  <label className="relative left-0 top-1 cursor-text"></label>
                </div>

                <div className="py-4 ">
                  <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                    Password
                  </span>

                  <div className="my-6  rounded-xl relative">
                    <input
                      {...formik.getFieldProps("password")}
                      id="password"
                      type={isPasswordHideShow === false ? "password" : "text"}
                      className="w-full pr-10 placeholder:font-dmsans border-b appearance-none bg-white focus:outline-none focus:ring-0 text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
                    />

                    <i className="absolute top-1/2 right-2 transform -translate-y-1/2 text-gray-700 cursor-pointer">
                      {isPasswordHideShow === true ? (
                        <VscEye
                          className=" w-6   "
                          onClick={ChangePasswordHideShow}
                        ></VscEye>
                      ) : (
                        <VscEyeClosed
                          className=" w-6 "
                          onClick={ChangePasswordHideShow}
                        ></VscEyeClosed>
                      )}
                    </i>
                  </div>

                  <button
                    type="submit"
                    className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                  >
                    Login
                  </button>


                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
