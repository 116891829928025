import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";
export const googleData = createAsyncThunk(
    "google",
    async (payload, { rejectWithValue }) => {
      try {


        
        const queryString = encodeURIComponent(JSON.stringify(payload));

        
        const request = await axios.get(`${env.API_URL}auth/get_google_products/${queryString}`);

        const response = await request.data;
        
    

        return response;
      } catch (error) {
      
        return rejectWithValue(error.message);
      }
    }
  );


const googleSlice = createSlice({
  name: "google",
  initialState: {
    googleProducts : [],
    productCount:0.0,
    loading: false,
    error: null,
  },
  reducers: {
    addGoogle: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(googleData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(googleData.rejected, (state, action) => {
  
        state.loading = true;
      })
      .addCase(googleData.fulfilled, (state, action) => {
          state.loading = false;
          
          state.googleProducts = action.payload;

         
        }
      );
  },
});
export const { addGoogle } = googleSlice.actions;
export default googleSlice.reducer;


