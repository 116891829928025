import React, { useEffect, useMemo } from "react";
import { brandData } from "../helpers/AuthStore/brandSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
const BrandFilters = ({ selectedFilter, onFilterChange }) => {
  const dispatch = useDispatch();
  const brand = useSelector((state) => state.brand);

  useEffect(() => {
    dispatch(brandData());
  }, []);

  return (
    <div className="flex flex-wrap  leading-tight font-dmsans justify-start mb-4">
      {brand &&
        brand.brands &&
        brand.brands?.brands &&
        brand.brands.brands.map((brand, index) => (
         <button
            key={index}
            className={`mx-1  mb-1 px-2 py-1 rounded  font-semibold  text-md  font-dmsans ${
              selectedFilter.brand == brand.name
                ? "bg-maincolor text-white"
                : "bg-gray-200 text-gray-600"
            }`}
            onClick={() => onFilterChange(brand.name)}
          >
            <span className="font-dmsans font-normal ">{brand.name}</span>
          </button>
        

        ))}
    </div>
  );
};

export default BrandFilters;
