import React, { useEffect } from 'react'
import { useState } from 'react';
import Header from '../Container/Header';
import MenuHeader from '../Container/MenuHeader';
import ToasterAlert from '../utils/ToasterAlert';
import { useFormik } from 'formik';
import {CreatePriceRulesValidator} from '../utils/validateAPI';
import getApiData from '../helpers/getApiData';

const Setting = () => {
const [rules,setRules] = useState();
const formik = useFormik({
  initialValues: {
       
    hardstop:"",
    formula:"",
    globalMarkup :"",
    resellerGlobalMarkup :"",
    google_hardstop: "",
    google_search_suppliers : "",
    ean_similar_search:"",
    sku_similar_search:"",
    name_similar_search:"",
    desc_similar_search:"",
    google_hardstop_pound:"",
    
    



  },

  
  validate: CreatePriceRulesValidator,
  validateOnBlur: false,
  validateOnChange: false,
  onSubmit: async (values) => {
    values = await Object.assign(values);

    return false;
  },
});


useEffect(()=>{
  async function fetchData(){

  const data = await getApiData("product/get_rules","");
  try
  {
    setRules(data.rules);
    formik.setValues({
      ...formik.values,
    
    hardstop:data.rules.hardstop,
 
    globalMarkup :data.rules.globalMarkup,
    resellerGlobalMarkup :data.rules.resellerGlobalMarkup,
    google_hardstop: data.rules.google_hardstop,
    google_search_suppliers : data.rules.google_search_suppliers,
    ean_similar_search:data.rules.ean_similar_search,
    sku_similar_search:data.rules.sku_similar_search,
    name_similar_search:data.rules.name_similar_search,
    desc_similar_search:data.rules.desc_similar_search,
    google_hardstop_pound:data.google_hardstop_pound,
    store_threshold:data.rules.store_threshold,

    });
  }
  catch(err)
  {
      console.log(err);
  }


  }
  fetchData();
},[]);


  return (
  <>

<ToasterAlert></ToasterAlert>
      <Header></Header>
      <MenuHeader></MenuHeader>



      <div className="container mx-auto">
      <div className="flex items-center  justify-center min-h-screen  bg-transparent">
      <div className="relative flex flex-col m-6 p-20 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">

    <div className=" flex items-start   justify-center min-h-auto  bg-transparent mt-10">
        <div className="flex flex-col justify-center  ">
          <span className="mb-3  text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
            Product Rule Settings
          </span>

          <form className="py-4"   onSubmit={formik.handleSubmit}>
           

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Hard Stop Rule For Product (%)
              </span>
              <input
                id="hardstop"
                type="number"
                {...formik.getFieldProps("hardstop")}
                value={formik.values.hardstop}
                onChange={formik.handleChange("hardstop")} // Handle change with Formik

            defaultValue={formik.values.hardstop}
            initialValues={formik.values.hardstop}
                  min ={0}
                  max={100}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Hard Stop Rule for Google Search Products(%)
              </span>
              <input
                id="desc"
                type="number"
                {...formik.getFieldProps("google_hardstop")}
                value={formik.values.google_hardstop}

                  min ={0}
                  max={100}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>

            
         

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
              Hard Stop in (£)
              </span>
              <input
                id="google_hardstop_pound"
                type="number"
                
                {...formik.getFieldProps("google_hardstop_pound")}
                value={formik.values.google_hardstop_pound} 
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>


{/* 

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Description Similar Probability Percentage (%)
              </span>
              <input
                id="desc_similar_search"
                type="number"
                {...formik.getFieldProps("desc_similar_search")}
                value={formik.values.desc_similar_search}
                  min ={70}
                  max={100}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Name Similar Probability Percentage (%)
              </span>
              <input
                id="name_similar_search"
                type="number"     
                  {...formik.getFieldProps("name_similar_search")}
                value={ formik.values.name_similar_search}
                
                  min ={70}
                  max={100}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>


            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                EAN Similar Probability Percentage (%)
              </span>
              <input
                id="ean"
                type="number"
                {...formik.getFieldProps("ean_similar_search")}
                value={formik.values.ean_similar_search}              
                min ={70}
                max={100}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>

 */}

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                SKU Similar Probability Percentage (%)
              </span>
              <input
                id="sku"
                type="number"
                min ={10}
                max={100} 
                {...formik.getFieldProps("sku_similar_search")}
                value={formik.values.sku_similar_search}
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>



            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Number of Stores
              </span>
              <input
                id="store_threshold"
                type="number"
                min ={0}
                max={100} 
                {...formik.getFieldProps("store_threshold")}
                value={formik.values.store_threshold}
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>


            

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Google Search Similar Suppliers (Seprated by Comma)
              </span>
              <input
                id="google_search_suppliers"
                type="text"
                {...formik.getFieldProps("google_search_suppliers")}
                value={formik.values.google_search_suppliers}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="provu,nuvais"
              ></input>
            </div>



            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
               Resller Global Markup
              </span>
              <input
                id="resellerGlobalMarkup"
                type="number"
                min ={0}
                max = {100}
                {...formik.getFieldProps("resellerGlobalMarkup")}
                value={formik.values.resellerGlobalMarkup}
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>
         

         

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
              Global Markup
              </span>
              <input
                id="globalMarkup"
                type="number"
                min ={0}
                max = {100}
                {...formik.getFieldProps("globalMarkup")}
                value={formik.values.globalMarkup} 
                
                className=" w-fit placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="%"
              ></input>
            </div>





         
            
         
         


            <div className="py-4 ">
              <button
                type="submit"
                className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Set Rules
              </button>
            </div>
          </form>
        </div>
      </div>
</div>
</div>
</div>
  </>
  )
}

export default Setting