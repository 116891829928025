import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { productData } from "../helpers/AuthStore/productPricingSlice";
import { useSelector, useDispatch, } from "react-redux";
import { useEffect, useState, useMemo,useLayoutEffect } from "react";
import GetApiData from "../helpers/getApiData";
import { AiOutlineUpload } from "react-icons/ai";
import { exportExcel, exportExcelHeaders } from "../helpers/excelUtils"; // Import the helper function for exporting Excel
import { AiOutlineDownload } from "react-icons/ai"; // Import the download icon from the react-icons library
import * as XLSX from "xlsx";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import Dropzone from "react-dropzone";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiArrowUpSFill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import ScreenRights from "../utils/ScreenRights";
import ProductDetailPricing from "./ProductDetailPricing";
import postApiData from "../helpers/postApiData";
import PermsTab from "./PermsTab";

import { AiFillInfoCircle } from "react-icons/ai";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";
import usePageSetting from "../utils/usePageSetting";



const ProductPricing = () => {
  const dispatch = useDispatch();
  let product_pricing = useSelector((state) => state.product_pricing);

  const [searchQuery, setSearchQuery] = useState("");
  const [accordion, setAccordion] = useState(true);

  const [selectedFilters, setSelectedFilters] = useState({
    account_group: "",
  });
  const [optionGroup, setoptionGroup] = useState([]);


  const user_id = JSON.parse(localStorage.getItem('user_data'))._id;
const screen_name = '/product_price';
const checkRights = useAuthScreenCheck(user_id, screen_name);

const pageSettings = usePageSetting(screen_name);



  const itemsPerPage = 100;
  const filteredproduct =
    product_pricing &&
    product_pricing.products &&
    product_pricing.products.product
      ? product_pricing.products.product.filter((item) => {
          const isNameMatch = item.name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
          const isGroupMatch =
            selectedFilters.account_group === "" ||
            (item.customerGroup &&
              item.customerGroup.toLowerCase() ===
                selectedFilters.account_group.toLowerCase());

          return isNameMatch && isGroupMatch;
        })
      : [];

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [data, setData] = useState([]);

  const handleUpload = (acceptedFiles) => {
    const fileReader = new FileReader();
    fileReader.onload = function (e) {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "buffer" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const result = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      setData(result);
    };


    fileReader.readAsArrayBuffer(acceptedFiles[0]);

    if(data.length > 0)
    {
      postApiData("product/uplift_data",{data:data});

    }


  };

  const handleDownload = () => {
    const dataToExport = filteredproduct.map((item) => ({
      ID: item._id,
      SKU: item.sku,
      Name: item.name,
      Brand: item.brand,
      "Account Group": item.customerGroup,
      "Customer Pricing": item.customer_group_price,
      "Auto Price": item.price,
      Rule: item.customer_rule_name,
    }));

    exportExcel(dataToExport, "Product_List.xlsx"); // Call the exportExcel function with the data and file name
  };


  useEffect(() => {
    const fetchData = async () => {
      const data = await GetApiData("customer/get_customer_group", "");

      const results = [];
      results.push({ key: 0, value: "" });

      data.customerGroupValue.map((value) => {
        return results.push({
          key: value._id,
          value: value.name,
        });
      });

      setoptionGroup([...results]);
    };
    fetchData();
    dispatch(productData());
  }, [dispatch]);

  const optionElements = useMemo(() => {
    return (
      optionGroup &&
      optionGroup.map((option) => (
        <option
          key={option.key}
          className="scrollbar-thumb-maincolor"
          value={option.value}
        >
          {option.value}
        </option>
      ))
    );
  }, [optionGroup]);

  const handleColumnSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleSample = () => {
    const dataToExport = [
      "PRODUCT NAME",
      "MARK UP",
      "CUSTOMER GROUP",
      "RULE NAME",
      "SKU",
    ];

    exportExcelHeaders(dataToExport, "product_list.xlsx");
  };
  const handleGroupFilter = (account_group) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      account_group,
    }));
  };

  const sortedItems = filteredproduct.sort((a, b) => {
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortColumn === "description") {
      return sortDirection === "asc"
        ? a.description.localeCompare(b.description)
        : b.description.localeCompare(a.description);
    } else if (sortColumn === "category") {
      return sortDirection === "asc"
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    }
    return 0;
  });

  useEffect(() => {
    dispatch(productData());
  }, [dispatch]);

  const isLoading = !product_pricing.products;

  return (
    <> 
    
    
    { checkRights && checkRights == true ? ( <>
    
    <PermsTab></PermsTab>
      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="mx-2 ">
        
            
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}  
 
              </Tooltip>
            </div>
            </div>




        <div className=" flex  mx-8 mt-20 items-end justify-end sm:justify-end ">
          <div className="absolute flex-shrink-0 items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>
            <div className="relative">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Product"
                className="bg-gray-50 border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-row-1 sm:grid-row-1 gap-2">
          <div className="flex flex-col w-fit ">
            <label className="text-sm mb-1 text-gray-500">Customer Group:</label>
            <select
              value={selectedFilters.account_group}
              onChange={(e) => {
                handleGroupFilter(e.target.value);
              }}
              id="root"
              name="root"
              className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0 dark:focus:border-blue-500 scrollbar scrollbar-thumb-maincolor"
            >
              {optionElements}
            </select>
          </div>
          <div className="flex justify-start">
            <div className="flex  grid-flow-col grid-cols-2 gap-2">
              <button
                onClick={handleDownload}
                className="flex items-center space-x-1 bg-maincolor text-white px-3 py-2 rounded-lg"
              >
                <AiOutlineDownload />
                Download
              </button>

              <Dropzone onDrop={handleUpload} accept=".xls, .xlsx, .csv">
                {({ getRootProps, getInputProps }) => (
                  <div
                    {...getRootProps()}
                    className="flex items-center space-x-1 bg-maincolor text-white px-3 py-2 rounded-lg"
                  >
                    <input {...getInputProps()} />
                    <AiOutlineUpload></AiOutlineUpload> <p>Upload</p>
                  </div>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="accordion ">
            <div className="accordion-item">
              
              {accordion === true ? (
                <button
                  className="accordion-toggle w-fit "
                  onClick={() => {
                    setAccordion(false);
                    
                  }}
                  id="item1-btn"
                >
                  <IoMdArrowDropdown></IoMdArrowDropdown>
                </button>
              ) : (
                <>
                  <button
                    onClick={() => {
                      setAccordion(true);
                      
                    }}
                    className="accordion-toggle w-fit"
                    id="open-btn"
                  >
                    <RiArrowUpSFill></RiArrowUpSFill>
                  </button>

                  <div className="flex flex-col">
                    <table className="w-full text-xs text-white uppercase font-dmsans bg-maincolor-light dark:bg-gray-700 dark:text-gray-400">
                      <thead className="text-xs text-white uppercase font-dmsans bg-maincolor-light dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-1 py-3">
                            Product Name
                          </th>
                          <th scope="col" className="px-1 py-3">
                            Mark Up Value
                          </th>
                          <th scope="col" className="px-1 py-3">
                            Customer Group
                          </th>
                          <th scope="col" className="px-1 py-3">
                            Rule Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            SKU
                          </th>
                       
                   
                          <th scope="col" className="px-6 py-3">
                            <button
                              onClick={handleSample}
                              className="  bg-maincolor-light text-white  rounded-lg"
                            >
                              <AiOutlineDownload></AiOutlineDownload>
                            </button>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </>
              )}
              <p className="font-dmsans text-maincolor">
                You can view Fields to Add Markup
              </p>
            </div>
          </div>
        </div>

        <div className="w-full col-span-4">
          <ProductDetailPricing
            handleColumnSort={handleColumnSort}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            currentItems={sortedItems}
          ></ProductDetailPricing>
        </div>
      </div></>):
      
      checkRights  ? 
      <ScreenRights></ScreenRights> : <></>}

                        
      </>

  );
};

export default ProductPricing;
