import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import env from "react-dotenv";

export const rmarkUpData = createAsyncThunk(
  "rmarkup",
  async (payload, { rejectWithValue }) => {
    try {
      const request = await axios.get(env.API_URL + "product/reseller_get_all_markups");
      const response = await request.data;

      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.message);
    }
  }
);

const rmarkupSlice = createSlice({
  name: "rmarkup",
  initialState: {
    rmarkups: [],
    loading: false,
    error: null,
  },
  reducers: {
    addRMarkup: (state, action) => {

    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rmarkUpData.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(rmarkUpData.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(rmarkUpData.fulfilled, (state, action) => {
        state.loading = false;
        state.rmarkups = action.payload;
      });
  },
});

export const { addRMarkup } = rmarkupSlice.actions;
export default rmarkupSlice.reducer;
