import React from "react";
import Header from "../Container/Header";
import MenuHeader from "../Container/MenuHeader";
import { productData } from "../helpers/AuthStore/productSlice";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect, useEffect, useState, useMemo } from "react";
import { BsFillFilterSquareFill } from "react-icons/bs";
import ToasterAlert from "../utils/ToasterAlert";
import { AiFillCaretRight, AiFillCaretLeft } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineClear } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import GetApiData from "../helpers/getApiData";
import useAuthScreenCheck from "../utils/useAuthScreenCheck";
import ProductDetail from "./ProductDetail";
import { AiFillInfoCircle } from "react-icons/ai";
import { customStyleToolTip } from "../utils/customStyleToolTip";
import { Tooltip } from "react-tooltip";
import usePageSetting from "../utils/usePageSetting";
import { IoFilterSharp } from "react-icons/io5";
import { BsFilterCircle, BsFilterCircleFill } from "react-icons/bs";
import { FcClearFilters } from "react-icons/fc";

const Product = () => {
  const dispatch = useDispatch();
  let product = useSelector((state) => state.product);
  const [role, setrole] = useState("admin");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQueryEAN, setSearchQueryEAN] = useState("");
  const [searchQuerySKU, setSearchQuerySKU] = useState("");

  const location = useLocation();
  const [showFilters, setShowFilters] = useState(false);
const [publistUpdate, setPublistUpdate] = useState(0);
const [expanded, setExpanded] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    category: "",
    distributor: "",
    status: "",
    brand: "",
    stock: "",
    range: "",
  });

  const itemsPerPage = 100;
 


  const filteredproduct = product.products
    ? product.products.filter((item) => {
      
      if(!product.products) return [];
        const isNameMatch = item.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const isCategoryMatch =
          selectedFilters.category === "" ||
          (item.category &&
            item.category.toLowerCase() ===
              selectedFilters.category.toLowerCase());
        const isDistributorMatch =
          selectedFilters.distributor === "" ||
          (item.distributor &&
            item.distributor.toLowerCase() ===
              selectedFilters.distributor.toLowerCase());
        const isStatusMatch =
          selectedFilters.status === "" ||
          (item.status &&
            item.status.toString() === selectedFilters.status);

        const isBrandMatch =
          selectedFilters.brand === "" ||
          !selectedFilters.brand ||
          (item.brand &&
            item.brand.toString() === selectedFilters.brand.toLowerCase());
        const isStockMatch =
          selectedFilters.stock === "" ||
          !selectedFilters.stock ||
          (item.stock && selectedFilters.stock === "Yes"
            ? item.stock > 0
            : item.stock <= 0);
        const isRangeMatch =
          selectedFilters.range === "" ||
          !selectedFilters.range ||
          (selectedFilters.range === "0-500"
            ? item.price >= 0 && item.price <= 500
            : selectedFilters.range === "500-1000"
            ? item.price >= 500 && item.price <= 1000
            : selectedFilters.range === "1000-1500"
            ? item.price >= 1000 && item.price <= 1500
            : selectedFilters.range === "1500-"
            ? item.price >= 1500
            : "");

        return (
          isNameMatch &&
          isCategoryMatch &&
          isDistributorMatch &&
          isStatusMatch &&
          isBrandMatch &&
          isStockMatch &&
          isRangeMatch
        );
      })
    : [];




  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredproduct.slice(indexOfFirstItem, indexOfLastItem);

  let totalPages = Math.ceil(filteredproduct.length / itemsPerPage);
  const [options, setOptions] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsBrand, setOptionsBrand] = useState([]);
  const [optionsStock, setOptionsStock] = useState([]);
  const screen_name = '/product_list';

  const pageSettings = usePageSetting(screen_name);

  const [optionRange, setOptionRange] = useState([]);
const [userid,setUserID] = useState();

  const stockOptions = [
    {
      id: 1,
      name: "Yes",
    },
    {
      id: 2,
      name: "No",
    },
  ];

  const priceRange = [
    {
      id: 1,
      name: "0-500",
    },
    {
      id: 2,
      name: "500-1000",
    },

    {
      id: 3,
      name: "1000-1500",
    },

    {
      id: 4,
      name: "1500-",
    },
  ];
  const clearSearch = () => {
    setSearchQuery("");
    setSearchQueryEAN("");
    setSearchQuerySKU("");
  };

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const NfilteredProduct = useMemo(() => {
    if (!product.products) return [];

    return currentItems.filter((item) => {
      const isCategoryMatch =
        selectedFilters.category === "" ||
        (item.category &&
          item.category.toLowerCase() ===
            selectedFilters.category.toLowerCase());
      const isDistributorMatch =
        selectedFilters.distributor === "" ||
        (item.distributor &&
          item.distributor.toLowerCase() ===
            selectedFilters.distributor.toLowerCase());
      const isStatusMatch =
        selectedFilters.status === "" ||
        (item.status &&
          item.status.toString().toLowerCase() === selectedFilters.status.toLowerCase());
          
      const isBrandMatch =
        selectedFilters?.brand === "" ||
        !selectedFilters.brand ||
        (item.brand &&
          item.brand.toString() === selectedFilters.brand.toLowerCase());

      const isStockMatch =
        selectedFilters.stock === "" ||
        !selectedFilters.stock ||
        (selectedFilters.stock === "Yes" ? item.stock > 0 : item.stock <= 0);
      const isRangeMatch =
        selectedFilters.range === "" ||
        (selectedFilters.range === "0-500"
          ? item.price >= 0 && item.price <= 500
          : selectedFilters.range === "500-1000"
          ? item.price >= 500 && item.price <= 1000
          : selectedFilters.range === "1000-1500"
          ? item.price >= 1000 && item.price <= 1500
          : selectedFilters.range === "1500-"
          ? item.price >= 1500
          : false);

      return (
        isCategoryMatch &&
        isDistributorMatch &&
        isStatusMatch &&
        isBrandMatch &&
        isStockMatch &&
        isRangeMatch
      );
    });
  }, [currentItems]);

  const optionElementsBrand = useMemo(() => {
    return optionsBrand.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsBrand]);

  const optionElementsStock = useMemo(() => {
    return optionsStock.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsStock]);

  const optionElementsRange = useMemo(() => {
    return optionRange.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionRange]);

  const optionElements = useMemo(() => {
    return options.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [options]);
  const optionElementsCategory = useMemo(() => {
    return optionsCategory.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsCategory]);

  const optionElementsStatus = useMemo(() => {
    return optionsStatus.map((option) => (
      <option key={option.key} value={option.value}>
        {option.value}
      </option>
    ));
  }, [optionsStatus]);

  useEffect(() => {
    async function fetchData() {

      const data = await GetApiData("product/get_distributor_list", "");

      const results = [];
      results.push({ key: 0, value: "" });

      data.dist_list.map((value) => {
        return results.push({
          key: value._id,
          value: value._id,
        });
      });

      

      setOptions([...results]);

      const dataCategory = await GetApiData("product/get_categories", "");

      const resultsCategory = [];
      resultsCategory.push({ key: 0, value: "" });

      dataCategory.category.map((value) => {
        return resultsCategory.push({
          key: value._id,
          value: value.name,
        });
      });

      setOptionsCategory([...resultsCategory]);

      const dataStatus = await GetApiData(
        "product/get_product_status_list",
        ""
      );

      const resultsStatus = [];

      resultsStatus.push({ key: 0, value: "" });
      let idnew = 0;
      dataStatus.status.map((value) => {
        idnew += 1;
        resultsStatus.push({
          key: value + idnew,
          value:
            value._id == true
              ? "true"
              : value._id == false
              ? "false"
              : value._id,
        });
      });

      setOptionsStatus([...resultsStatus]);

      const dataBrand = await GetApiData("product/get_brands", "");

      const resultsBrand = [];
      resultsBrand.push({ key: 0, value: "" });
      dataBrand.brands.map((value) => {
        resultsBrand.push({
          key: value._id,
          value: value.name,
        });
      });

      setOptionsBrand([...resultsBrand]);

      const resultsStock = [];
      resultsStock.push({ key: 0, value: "" });
      stockOptions.map((value) => {
        resultsStock.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionsStock([...resultsStock]);

      const resultsRange = [];
      resultsRange.push({ key: 0, value: "" });
      priceRange.map((value) => {
        resultsRange.push({
          key: value.id,
          value: value.name,
        });
      });

      setOptionRange([...resultsRange]);
    }


    fetchData();
  }, [product.products.product]);

  const getPages = () => {
    const maxVisiblePages = 5;
    const sidePages = Math.floor((maxVisiblePages - 3) / 2);
    const pages = [];

    pages.push(
      <button
        onClick={() => handlePageChange(1)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === 1
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        1
      </button>
    );

    let startPage = Math.max(2, currentPage - sidePages);
    let endPage = Math.min(totalPages - 1, currentPage + sidePages);
    let addStartDots = false;
    let addEndDots = false;

    if (endPage < totalPages - 1) {
      addEndDots = true;
    }

    for (let page = startPage; page <= endPage; page++) {
      pages.push(
        <button
          key={page}
          onClick={() => handlePageChange(page)}
          className={` px-3 py-1 font-bold rounded font-dmsans  ${
            currentPage === page
              ? "bg-maincolor text-white"
              : "bg-gray-200 text-gray-600"
          }`}
        >
          {page}
        </button>
      );
    }

    if (addEndDots) {
      pages.push(
        <span key="end-dots" className="px-0 py-1 text-gray-600">
          ...
        </span>
      );
    }

    pages.push(
      <button
        key={totalPages}
        onClick={() => handlePageChange(totalPages)}
        className={`px-3 py-1 font-bold rounded font-dmsans mx-1 ${
          currentPage === totalPages
            ? "bg-maincolor text-white"
            : "bg-gray-200 text-gray-600"
        }`}
      >
        {totalPages}
      </button>
    );

    return pages;
  };

  const handleColumnSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const sortedItems = NfilteredProduct.sort((a, b) => {
    if (sortColumn === "name") {
      return sortDirection === "asc"
        ? a.name.localeCompare(b.name)
        : b.name.localeCompare(a.name);
    } else if (sortColumn === "description") {
      return sortDirection === "asc"
        ? a.description.localeCompare(b.description)
        : b.description.localeCompare(a.description);
    } else if (sortColumn === "category") {
      return sortDirection === "asc"
        ? a.category.localeCompare(b.category)
        : b.category.localeCompare(a.category);
    }
    return 0;
  });

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleStatusFilter = (status) => {
  
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      status,
    }));
  };

  const handleCategoryFilter = (category) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      category,
    }));
  };
  const handleDistributorFilter = (distributor) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      distributor,
    }));
  };

  const handleBrandFilter = (brand) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      brand,
    }));
  };

  const handleStockFilter = (stock) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      stock,
    }));
  };

  const handleRangeFilter = (range) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      range,
    }));
  };

  const clearFilters = () => {
    setSelectedFilters({
      category: "",
      distributor: "",
      status: "",
      brand: "",
      stock: "",
      range: "",
    });
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  useLayoutEffect(() => {
    return () => {

      if(localStorage.getItem('user_data'))
      {
  setrole( JSON.parse(localStorage.getItem('user_data')).role);
  setUserID(JSON.parse(localStorage.getItem('user_data'))._id);
      }

      if (location.state) {
        setSelectedFilters((prevFilters) => ({
          ...prevFilters,
          category: location.state,
        }));
      }
    };
  });

  useEffect(() => {
    dispatch(productData({




    }));
  }, [ dispatch,
    
  ]);



  const isLoading = !product.products;

  return (
    <>
      <>
        {isLoading ? (
          <div className="text-center mt-10">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden font-dmsans">Loading...</span>
            </div>
          </div>
        ) : (
          <>{/* Render the product list */}</>
        )}
      </>

   <ToasterAlert/>

      <Header></Header>
      <MenuHeader></MenuHeader>

      <div className="mx-2 ">
        

              
      <div>
            <span className="mb-3       text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
              {pageSettings && pageSettings.title}
            </span>

            <div className="flex grid-col-2 gap-2">
              <div className="    ">
                <span
                  className="py-4  text-base text-left text-gray-500 font-dmsans"
                  dangerouslySetInnerHTML={{
                    __html: pageSettings && pageSettings.desc,
                  }}
                ></span>
              </div>


              <a
                data-tooltip-id="info_tooltip"
                data-tip
                data-tooltip-place="top"
                className="  whitespace-normal font-dmsans "
              >
                <AiFillInfoCircle className="w-6 h-6   text-maincolor"></AiFillInfoCircle>
              </a>

              <Tooltip
                id="info_tooltip"
                style={customStyleToolTip}
                className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
              >
                
                {pageSettings && pageSettings.info ? (
            <div dangerouslySetInnerHTML={{ __html: pageSettings.info }} />
          ) : (
            <p></p>
          )}   
 
              </Tooltip>
            </div>
            </div>





            <div className=" flex  mx-8 mt-20 mb-2 items-end justify-end sm:justify-start  font-dmsans text-gray-400 ">
          <div className="flex grid-col-2 gap-2 ">
            Filters:
            <button
              onClick={() => setShowFilters(!showFilters)}
              className=" text-2xl font-medium text-maincolor   justify-start "
            >
              {showFilters ? (
                <BsFillFilterSquareFill></BsFillFilterSquareFill>
              ) : (
                <IoFilterSharp></IoFilterSharp>
              )}
            </button>
           
          </div>

          <div className="absolute flex-shrink-0 items-center">
            <label htmlFor="simple-search" className="sr-only">
              Search
            </label>

            <div className=" flex grid-col-3  gap-4"></div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-5 gap-2  z-50 ">
          <div
            className={`col-span-5  md:col-span-5 lg:col-span-5  xl:col-span-1 2xl:col-span-1 mt-6  w-full   z-auto flex flex-col ${
              showFilters
                ? "bg-white shadow-2xl rounded-2xl p-2 md:p-7 h-fit"
                : "hidden"
            }`}
          >
            <div className="mx-auto flex flex-row">
              <div className="grid grid-row-1 sm:grid-row-1 gap-2 ">
                <span className="mt-7 font-dmsans text-subheading-400 text-maincolor">
                  <div className="flex justify-between items-center">
                    <div className="justify-start">Search</div>
                    <div className="flex justify-end">
                      <button
                        onClick={clearSearch}
                        className="block w-10 h-10 font-medium text-maincolor rounded-md hover:bg-opacity-80 focus:outline-none"
                      >
                        <AiOutlineClear className="w-8 h-8" />
                      </button>
                    </div>
                  </div>
                </span>
                <div className="font-dmsans border border-solid md:p-6 p-2  shadow-md  ">
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Search:
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Product Name"
                        className="bg-gray-50 border border-gray-300 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>
                    <div className="relative mt-3">
                      <input
                        type="text"
                        value={searchQueryEAN}
                        onChange={(e) => setSearchQueryEAN(e.target.value)}
                        placeholder="EAN"
                        className="bg-gray-50 border border-gray-300 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>
                    <div className="relative mt-3">
                      <input
                        type="text"
                        value={searchQuerySKU}
                        onChange={(e) => setSearchQuerySKU(e.target.value)}
                        placeholder="SKU"
                        className="bg-gray-50 border border-gray-300 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                      />
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                        <AiOutlineSearch className="w-5 h-5 text-maincolor dark:text-maincolor" />
                      </div>
                    </div>
                  </div>
                </div>
                <span className="mt-7 font-dmsans text-subheading-400 text-maincolor">
                  <div className="flex justify-between items-center">
                    <div className="justify-start">Filters</div>
                    <div className="flex justify-end">
                      <button
                        onClick={clearFilters}
                        className="block w-10 h-10 font-medium text-white rounded-md hover:bg-opacity-80 focus:outline-none"
                      >
                        <FcClearFilters className="w-8 h-8" />
                      </button>
                    </div>
                  </div>
                </span>
                <div className="border border-1 p-2 md:px-2  shadow-md ">
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Category:
                    </label>
                    <select
                      value={selectedFilters.category}
                      onChange={(e) => handleCategoryFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar  scrollbar-thumb-maincolor   text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElementsCategory}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Status:
                    </label>
                    <select
                      value={selectedFilters.status}
                      onChange={(e) => handleStatusFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar scrollbar-thumb-maincolor  text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElementsStatus}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Distributor:
                    </label>
                    <select
                      value={selectedFilters.distributor}
                      onChange={(e) => handleDistributorFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar scrollbar-thumb-maincolor  text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElements}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Brand:
                    </label>
                    <select
                      value={selectedFilters.brand}
                      onChange={(e) => handleBrandFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar scrollbar-thumb-maincolor  text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElementsBrand}
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Stock:
                    </label>
                    <select
                      value={selectedFilters.stock}
                      onChange={(e) => handleStockFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar scrollbar-thumb-maincolor  text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElementsStock}
                    </select>
                  </div>
                  <div className="flex flex-col mb-5">
                    <label className="text-sm mb-1 text-gray-500 font-dmsans">
                      Price Range:
                    </label>
                    <select
                      value={selectedFilters.range}
                      onChange={(e) => handleRangeFilter(e.target.value)}
                      className="bg-gray-50 border border-gray-300 scrollbar scrollbar-thumb-maincolor  text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-10 pr-10 p-2.5 focus:outline-none focus:ring-0"
                    >
                      {optionElementsRange}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>



  <div className={`w-full col-span-4 ${showFilters ? 'col-span-4' : 'col-span-5'}`}>
    <ProductDetail
      handleColumnSort={handleColumnSort}
      sortColumn={sortColumn}
      sortDirection={sortDirection}
      currentItems={sortedItems}
      role = {role}
      userid = {userid}
      published= {false}
     
    ></ProductDetail>
  </div>
</div>



        <div className=" flex justify-end mb-10">
          <div className="flex flex-col ">
            <div className="flex flex-1 mt-2">
              <button
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
                className="font-dmsans  px-3 py-1 font-medium rounded bg-gray-200  md:mb-0 mr-2"
              >
                <AiFillCaretLeft />
              </button>
              <div className=" ">{getPages()}</div>

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="font-dmsans px-3 py-1 font-medium rounded bg-gray-200 "
              >


                <AiFillCaretRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
