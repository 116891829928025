import React, { useState } from "react";
import { useEffect, useMemo, useContext } from "react";
import { useFormik } from "formik";

import { ValidateCreatMarkup } from "../utils/validateAPI";
import { GroupContext } from "./Markup";

import GetApiData from "../helpers/getApiData";
const MarkupAdd = ({ props, markupState, isCustomer }) => {
  const { groupName } = useContext(GroupContext);

  const [type, setType] = useState();
  const [optionsCategory, setOptionsCategory] = useState();
  const [optionsBrand, setOptionsBrand] = useState();
  const [options, setOptions] = useState([]);
  const [optionsProduct, setOptionsProduct] = useState([]);
  const [data, setnewData] = useState([]);
  const [value, setValue] = useState();

  async function fetchData() {
    const data = await GetApiData("product/get_distributor", "");

    const results = [];
    results.push({ key: 0, value: "" });

    data.distributor.map((value) => {
      results.push({
        key: value._id,
        value: value.name,
      });
    });
    setOptions([...results]);

    const dataCategory = await GetApiData("product/get_categories", "");

    const resultsCategory = [];
    resultsCategory.push({ key: 0, value: "" });

    dataCategory.category.map((value) => {
      resultsCategory.push({
        key: value._id,
        value: value.name,
      });
    });

    setOptionsCategory([...resultsCategory]);



    const dataBrand = await GetApiData("product/get_brands", "");

    const resultsBrand = [];
    resultsBrand.push({ key: 0, value: "" });

    dataBrand.brands.map((value) => {
      resultsBrand.push({
        key: value._id,
        value: value.name,
      });
    });

    setOptionsBrand([...resultsBrand]);





    const newdata = await GetApiData("product/get_all_filters", "");

    const arrayProducts = [];
    for (let i = 1; i <= newdata.product / 1000 + 1; i++) {
      arrayProducts.push(i);
    }

    setnewData(arrayProducts);


  }

  useEffect(() => {
    async function fetchData() {
      const dataProducts = await GetApiData(
        "product/product_new" + "/" + value,
        ""
      );

      const resultsProduct = [];
      resultsProduct.push({ key: 0, value: "" });

      dataProducts.map((value) => {
        resultsProduct.push({
          key: value._id,
          value: value.name,
        });
      });

      setOptionsProduct([...resultsProduct]);
    }

    fetchData();
  }, [value]);

  
  useEffect(() => {
    fetchData();
  }, [props]);

  const optionElements = useMemo(() => {
    return options
      ? options.map((option) => (
          <option key={option.key} value={option.value}>
            {option.value}
          </option>
        ))
      : [];
  }, [options]);

  const optionElementsBrand = useMemo(() => {
    return optionsBrand
      ? optionsBrand.map((option) => (
          <option key={option.key} value={option.value}>
            {option.value}
          </option>
        ))
      : [];
  }, [optionsBrand]);


  const optionElementsCategory = useMemo(() => {
    return optionsCategory
      ? optionsCategory.map((option) => (
          <option key={option.key} value={option.value}>
            {option.value}
          </option>
        ))
      : [];
  }, [optionsCategory]);

  const optionElementsProduct = useMemo(() => {
    return optionsProduct
      ? optionsProduct.map((option) => (
          <option key={option.key} value={option.value}>
            {option.value}
          </option>
        ))
      : [];
  }, [optionsProduct]);
  

  const optionElementsCount = useMemo(() => {
    return data
      ? data.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))
      : [];
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      brands:"",
      type: "",
      distributor: "",
      low_cost: 0,
      high_cost: 0,
      currency: "",
      value: "",
      isCustomer: isCustomer,
      groupName: groupName,
      product: "",
    },

    validate: ValidateCreatMarkup,

    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      props(false);
      return false;
    },
  });

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="container mx-auto">
          <div className="flex items-center  justify-center min-h-screen  bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
              <div className="flex flex-col justify-center p-8 md:p-14 ">
                <div className="flex items-end  justify-end  p-0 ">
                  <button
                  
                    className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => {
                      props(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor ">×</span>
                  </button>

                </div>
                <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                  Create Pricing Rule
                </span>


                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Name of Pricing Rule
                    </span>
                    <input
                      {...formik.getFieldProps("name")}
                      id="name"
                      type="text"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      placeholder="Rule Pricing Name"
                    ></input>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Select Pricing Type
                    </span>
                    <select
                      {...formik.getFieldProps("type")}
                      value={formik.values.type}

                      id="type"
                      name="type"
                      className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                      onChange={(e) => {
                        formik.handleChange(e);
                        setType(e.target.value);
                      }}
                    >
                      <option value="" key="0"></option>
                      <option value="category" key="1">
                        Category
                      </option>
                      <option value="distributor" key="2">
                        Distributor
                      </option>
                      <option value="price" id="3">
                        Price
                      </option>
                      <option value="product" id="4">
                        Product
                      </option>
                      <option value="brands" id="5">
                        Brands
                      </option>
                    </select>
                  </div>

                  {type === "category" ? (
                    <div className="py-4">
                      <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                        Category
                      </span>
                      <select
                        {...formik.getFieldProps("category")}
                        value={formik.values.category}
                        className="bg-gray-50 border scrollbar scrollbar-thumb-maincolor border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                      >
                        {optionElementsCategory}
                      </select>

                      <div>
                        <div className="grid grid-cols-3 gap-0 mt-5">
                          <div className="flex items-center">
                            <span className="text-xs mx-1 text-gray-500">
                              Minimum:
                            </span>

                            <input
                              {...formik.getFieldProps("low_cost")}
                              selected={formik.values.low_cost}
                              type="number"
                              min={0}
                              className="  appearance-none w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center  focus:border-2  placeholder:font-light"
                            />
                          </div>
                          <div className="flex items-center">
                            <span className="text-xs mx-1 text-gray-500">
                              Maximum:
                            </span>
                            <input
                              {...formik.getFieldProps("high_cost")}
                              selected={formik.values.high_cost}
                              type="number"
                              min={0}
                              className="w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center   focus:border-2  placeholder:font-light"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === "distributor" ? (
                    <div>
                      <div className="py-4">
                        <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                          Distributor
                        </span>
                        <select
                          {...formik.getFieldProps("distributor")}
                          value={formik.values.distributor}
                          className="bg-gray-50 border scrollbar scrollbar-thumb-maincolor border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                        >
                          {optionElements}
                        </select>
                        <div>
                          <div className="grid grid-cols-3 gap-0 mt-5">
                            <div className="flex items-center">
                              <span className="text-xs mx-1 text-gray-500">
                                Minimum:
                              </span>

                              <input
                                {...formik.getFieldProps("low_cost")}
                                selected={formik.values.low_cost}
                                type="number"
                                min={0}
                                className="  appearance-none w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center  focus:border-2  placeholder:font-light"
                              />
                            </div>
                            <div className="flex items-center">
                              <span className="text-xs mx-1 text-gray-500">
                                Maximum:
                              </span>
                              <input
                                {...formik.getFieldProps("high_cost")}
                                selected={formik.values.high_cost}
                                type="number"
                                min={0}
                                className="w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center   focus:border-2  placeholder:font-light"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type === "price" ? (
                    <div>
                      <div className="grid grid-cols-3 gap-0 mt-5">
                        <div className="flex items-center">
                          <span className="text-xs mx-1 text-gray-500">
                            Minimum:
                          </span>

                          <input
                            {...formik.getFieldProps("low_cost")}
                            selected={formik.values.low_cost}
                            type="number"
                            min={0}
                            className="  appearance-none w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center  focus:border-2  placeholder:font-light"
                          />
                        </div>
                        <div className="flex items-center">
                          <span className="text-xs mx-1 text-gray-500">
                            Maximum:
                          </span>
                          <input
                            {...formik.getFieldProps("high_cost")}
                            selected={formik.values.high_cost}
                            type="number"
                            min={0}
                            className="w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center   focus:border-2  placeholder:font-light"
                          />
                        </div>
                      </div>
                    </div>

                    
                  ) : type === "product" ? (
                    <div>
                      <div className="py-4">
                        <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                          Pages
                        </span>

                        <select
                          {...formik.getFieldProps("values")}
                          value={formik.values.newValue}
                          onChange={(e) => {
                            setValue(e.target.value);
                          }}
                          
                          className="bg-gray-50 border scrollbar scrollbar-thumb-maincolor border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                        >
                          {optionElementsCount}
                        </select>

                        <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                          Products
                        </span>
                        <select
                          {...formik.getFieldProps("product")}
                          value={formik.values.product}
                          className="bg-gray-50 border scrollbar scrollbar-thumb-maincolor border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                        >
                          {optionElementsProduct}
                        </select>

                        <div>
                          <div className="grid grid-cols-3 gap-0 mt-5">
                            <div className="flex items-center">
                              <span className="text-xs mx-1 text-gray-500">
                                Minimum:
                              </span>

                              <input
                                {...formik.getFieldProps("low_cost")}
                                selected={formik.values.low_cost}
                                type="number"
                                min={0}
                                className="  appearance-none w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center  focus:border-2  placeholder:font-light"
                              />
                            </div>
                            <div className="flex items-center">
                              <span className="text-xs mx-1 text-gray-500">
                                Maximum:
                              </span>
                              <input
                                {...formik.getFieldProps("high_cost")}
                                selected={formik.values.high_cost}
                                type="number"
                                min={0}
                                className="w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center   focus:border-2  placeholder:font-light"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : type == "brands" ? (
                    <div>
                    <div className="py-4">
                      <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                        Brands
                      </span>
                      <select
                        {...formik.getFieldProps("brands")}
                        value={formik.values.brands}
                        className="bg-gray-50 border scrollbar scrollbar-thumb-maincolor border-gray-300 text-black text-sm rounded-lg focus:ring-maincolor focus:border-maincolor font-dmsans tra-color block w-full p-2.5 dark:bg-maincolor dark:border-maincolor dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 focus:outline-none focus:ring-0   dark:focus:border-blue-500"
                      >
                        {optionElementsBrand}
                      </select>
                      <div>
                        <div className="grid grid-cols-3 gap-0 mt-5">
                          <div className="flex items-center">
                            <span className="text-xs mx-1 text-gray-500">
                              Minimum:
                            </span>

                            <input
                              {...formik.getFieldProps("low_cost")}
                              selected={formik.values.low_cost}
                              type="number"
                              min={0}
                              className="  appearance-none w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center  focus:border-2  placeholder:font-light"
                            />
                          </div>
                          <div className="flex items-center">
                            <span className="text-xs mx-1 text-gray-500">
                              Maximum:
                            </span>
                            <input
                              {...formik.getFieldProps("high_cost")}
                              selected={formik.values.high_cost}
                              type="number"
                              min={0}
                              className="w-20 placeholder:font-dmsans  border-b-maincolor  focus:outline-none text-center   focus:border-2  placeholder:font-light"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  ):<></>}

                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Value of Pricing Rule
                    </span>
                    <input
                      {...formik.getFieldProps("value")}
                      id="value"
                      type="number"
                      min={0}
                      value={formik.values.value}
                      name="value"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      placeholder="Pricing Value"
                    ></input>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="flex items-center">
                    <div
                      {...formik.getFieldProps("currency")}
                      className="relative items-center"
                    >
                      <input
                        type="radio"
                        name="currency"
                        value="£"
                        onChange={formik.handleChange}
                      />
                      <span className="mx-1">£</span>
                    </div>
                    <div className="relative">
                      <input
                        {...formik.getFieldProps("currency")}
                        type="radio"
                        name="currency"
                        value="%"
                        onChange={formik.handleChange}
                      />
                      <span className="mx-2">%</span>
                    </div>
                  </div>

                  <div className="py-4 ">
                    <button
                      type="submit"
                      className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default MarkupAdd;
