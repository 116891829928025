import React, {  useState } from "react";

import PostApiData from "../helpers/postApiData";

const CategoryUpdate = ({
  categoryState,
  proper,
  setCategoryState,
  sproper,
}) => {
 

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const Values = {
      name: categoryState.name,
      thumb_url: categoryState.thumb_url,
      image_url: categoryState.image_url,
      publish: categoryState.publish,
      desc: categoryState.desc,
      menu_title: categoryState.menu_title,
      menu_desc: categoryState.menu_desc,
      hide_in_navigation: categoryState.hide_in_navigation,
      id: categoryState._id,
    };


    await PostApiData("product/update_categories", Values);
    sproper(!proper);
  };

  return (
    <>
   
      <div className=" flex items-start  justify-center min-h-auto  bg-transparent">
        <div className="flex flex-col justify-center  ">
          <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
            Update Category
          </span>

          <form className="py-4" onSubmit={handleFormSubmit}>
            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Category Name
              </span>
              <input
                id="name"
                type="text"
                value={categoryState.name}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    name: e.target.value,
                  })
                }
                className="w-full placeholder:font-dmsans border-b p-2 focus:outline-none text-center focus:border-maincolor focus:border-b-2 transition-colors placeholder:font-light"
                placeholder="Category Name"
              />

              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Category Description
              </span>
              <textarea
                id="desc"
                type="text"
                value={categoryState.desc}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    desc: e.target.value,
                  })
                }
                className=" w-full placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="Description"
              ></textarea>
              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Menu Description
              </span>
              <textarea
                id="menu_desc"
                type="text"
                value={categoryState.menu_desc}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    menu_desc: e.target.value,
                  })
                }
                className=" w-full placeholder:font-dmsans   overflow:auto scrollbar   scrollbar-thumb-maincolor  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="Menu Description"
              ></textarea>
              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Menu Title
              </span>
              <input
                id="menu_title"
                type="text"
                value={categoryState.menu_title}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    menu_title: e.target.value,
                  })
                }
                className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="Menu Title"
              ></input>
              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  checked={categoryState.publish}
                  onChange={(e) =>
                    setCategoryState({
                      ...categoryState,
                      publish: e.target.checked,
                    })
                  }
                  id="publish"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="publish-checkbox"
                  className="ml-2 text-md font-dmsans  font-medium text-gray-900 dark:text-gray-300"
                >
                  Publish
                </label>
              </div>
            </div>


            <div className="py-4 ">
              <div className="flex items-center mb-4">
                <input
                  type="checkbox"
                  value={categoryState.hide_in_navigation}
                  onChange={(e) =>
                    setCategoryState({
                      ...categoryState,
                      hide_in_navigation: e.target.checked,
                    })
                  }
                  id="hide_in_navigation"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="navigation-checkbox"
                  className="ml-2 text-md font-dmsans  font-medium text-gray-900 dark:text-gray-300"
                >
                  Hide in Navigation
                </label>
              </div>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Thumb URL
              </span>
              <input
                id="thumb_url"
                type="text"
                value={categoryState.thumb_url}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    thumb_url: e.target.value,
                  })
                }
                className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="Url"
              ></input>
              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                Image URL
              </span>
              <input
                id="image_url"
                type="text"
                value={categoryState.image_url}
                onChange={(e) =>
                  setCategoryState({
                    ...categoryState,
                    image_url: e.target.value,
                  })
                }
                className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                placeholder="Image Url"
              ></input>
              <input hidden value={categoryState._id}></input>

              <label className="relative left-0 top-1 cursor-text"></label>
            </div>

            <div className="py-4 ">
              <button
                type="submit"
                className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
              >
                Update Category
              </button>
            </div>
          </form>
        </div>
      </div>

   
    </>
  );
};

export default CategoryUpdate;
