import { Children } from "react";
import GetApiData from "../helpers/getApiData";
import PostApiData from "../helpers/postApiData";

export async function ValidationMenu(values) {
  const errors = verify({}, values);

  return errors;
}

async function verify(error = {}, values) {
  values.parent = values.parent === "" ? 1 : values.parent;

  const data = await GetApiData("menu/get_id_name", values.parent);

  const Values = {
    menu_name: values.menu_name,
    level: values.level,
    parent: data.id,
    id: values.id,
    type:values.type,
    admin:values.admin,
    user:values.user,
    reseller:values.reseller,
    
    

  };

  
  await PostApiData("menu/update_menu", Values);
}

export async function ValidationMenuCreate(values) {
  const errors = verify_menu_create({}, values);

  return errors;
}

async function verify_menu_create(error = {}, values) {
  const data = await GetApiData("menu/get_id_name", values.parent);



  const Values = {
    menu_name: values.menu_name,
    level: values.level,
    parent: data.id === "" ? 0 : data.id,
    type:values.type,
    admin:values.admin,
    user:values.user,
    reseller:values.reseller,

    
    
  };

  await PostApiData("menu/create_menu", Values);
}

export async function ValidationScreenCreate(values) {
  const errors = verify_screen_create({}, values);

  return errors;
}

async function verify_screen_create(error = {}, values) {
  const data = await GetApiData("menu/get_id_name", values.menu_name);

  const Values = {
    screen_name: values.screen_name,
    screen_url: values.screen_url,
    menu_name: data._id,
  };

  await PostApiData("screen/create_screen", Values);
}

export async function ValidationScreen(values) {
  const errors = verify_screen_update({}, values);

  return errors;
}

async function verify_screen_update(error = {}, values) {
  const data = await GetApiData("menu/get_id_name", values.menu_name);

  const Values = {
    screen_name: values.screen_name,
    screen_url: values.screen_url,
    menu_name: data._id,
    ID: values.id,
  };

  await PostApiData("screen/update_screen", Values);

  return error;
}

export async function ValidationUserCreate(values) {
  const errors = verify_user_create({}, values);

  return errors;
}

async function verify_user_create(error = {}, values) {
  

  const Values = {
    username: values.username,
    password: values.password,
    role: values.role,
  };

  await PostApiData("auth/create_user", Values);
}

export async function ValidateUser(values) {
  const errors = verify_user_update({}, values);

  return errors;
}

async function verify_user_update(error = {}, values) {
  const Values = {
    username: values.username,
    role: values.role,
    ID: values.id,
  };

  

  await PostApiData("auth/update_user", Values);
}


export async function ValidationAuthUserCreateAll(values) {
  const errors = verify_auth_screen_all({}, values);

  return errors;
}

async function verify_auth_screen_all(error = {}, values) {
  

  const userData = await GetApiData("auth/get_id_name/" +  values.username,"");

  

  const Values = {  
    username: userData._id,
    
  };

  await PostApiData("auth/create_auth_screens_all", Values);
}







export async function ValidationAuthPageSettingsUpdate(values) {
  const errors = verify_auth_page_settings_update({}, values);

  return errors;
}
async function verify_auth_page_settings_update(error = {}, values) {

  const Values = {  
    title: values.title,
    desc:values.desc,
    info:values.info,
    screen_name:values.screen_name,
    ID:values.ID,
    
  };


  await PostApiData("page_settings/update_pageSetting", Values);
}




export async function ValidationAuthPageSettingsCreate(values) {
  const errors = verify_auth_page_settings({}, values);

  return errors;
}
async function verify_auth_page_settings(error = {}, values) {
  const Values = {  
    title: values.title,
    desc:values.desc,
    info:values.info,
    screen_name:values.screen_name,
    
  };
  await PostApiData("page_settings/create_pageSetting", Values);
}







export async function ValidationAuthUserCreate(values) {
  const errors = verify_auth_screen({}, values);

  return errors;
}

async function verify_auth_screen(error = {}, values) {
  const data = await GetApiData(
    "screen/get_screen_id_by_name",
    values.screen_name
  );

  const userData = await GetApiData("auth/get_id_name/" +  values.username,"");

  

  const Values = {  
    username: userData._id,
    screen_name: data._id,
  };

  await PostApiData("auth/create_auth_screens", Values);
}

export async function ValidateUpdateCategory(values) {
  const errors = verify_category_update({}, values);

  return errors;
}

async function verify_category_update(error = {}, values) {
  
  const Values = {
    name: values.name,
    thumb_url:values.thumb_url,
    image_url: values.image_url,
    publish: values.publish,
    desc: values.desc,
    menu_title: values.menu_title,
    menu_desc: values.menu_desc,
    hide_in_navigation: values.hide_in_navigation,
    id: values.id,
  };


  await PostApiData("product/update_categories", Values);
}



export async function ValidateUpdateDistributor(values) {
  const errors = verify_distributor_update({}, values);

  return errors;
}


async function verify_distributor_update(error = {}, values) {

  const Values = {
    name: values.name,
    cost_per_uplift:values.cost_per_uplift,
    currency: values.currency,
    id:values.id,
    
    
  };

  await PostApiData("product/update_distributor", Values);


}


export async function ValidateCreatMarkup(values) {
  const errors = verify_markup_create({}, values);

  return errors;
}


async function verify_markup_create(error = {}, values) {

  const Values = {
    name:values.name,
    category:values.category,
    type:values.type,
    distributor:values.distributor,
    low_cost:values.low_cost,
    high_cost:values.high_cost,
    currency:values.currency,
    value:values.value, 
    product:values.product,
    
    brands:values.brands,
    

   
  };

  

  if(values.isCustomer == false)
  { 
  
    await PostApiData("product/post_markups", Values);
  }

  else
  {

    const Values = {
      name:values.name,
      category:values.category,
      type:values.type,
      distributor:values.distributor,
      low_cost:values.low_cost,
      high_cost:values.high_cost,
      currency:values.currency,
      value:values.value, 
      customerGroup:values.groupName,
      product:values.product,
      brands:values.brands,
    };
 
     await PostApiData("customer/post_markup", Values);


  }




}







export async function CreateCategoryValidator(values) {
  const errors = verify_category_create({}, values);

  return errors;
}


async function verify_category_create(error = {}, values) {

  if(values.bid == "root")
  { 
   const Values = {
    name:values.name,
    bid:values.bid,
    children : []
    
  };
  

  
  await PostApiData("product/add_category", Values);


  }
 else if(values.bid == "sub")
 {


  

  
  const children = [];
  values.child.map(child =>{

    children.push (child.value);
  })

  
  const Values = {
    name:values.root,
    bid:values.bid,
    child : children,
    retain:values.retain,
    
  };
  
 
 
 await PostApiData("product/add_category", Values);


 }
  
}



export async function ValiadateUserUpdate(values) {
  const errors = verify_user_update_settings({}, values);

  
  return errors;

}



async function verify_user_update_settings(error = {}, values) {

  const data = localStorage.getItem('user_data');
  const newData = JSON.parse(data);


  const Values = {
    username:newData.username,
    password:values.password,

 
 
    company:values.company,
      
    email:values.email,
    name:values.name,
    website:values.website,




        
  };

  
 await PostApiData("auth/update_user_settings", Values);

}

export async function CreateCustomerGroupValidator(values) {
  const errors = verify_create_group__settings({}, values);

  
  return errors;

}


async function verify_create_group__settings(error = {}, values) {

  
  const Values = {
name:values.name,



        
  };

  
 await PostApiData("customer/add_customer", Values);
 



}







export async function ValidateCreateResellerMarkup(values) {
  const errors = verify_markup_reseller_create({}, values);

  return errors;
}


async function verify_markup_reseller_create(error = {}, values) {

  const Values = {
    name:values.name,
    category:values.category,
    type:values.type,
    distributor:values.distributor,
    
    brand:values.brand,
    low_cost:values.low_cost,
    high_cost:values.high_cost,
    currency:values.currency,
    value:values.value,
    id_reseller:values.reseller,
    
    product:values.product,

    
    
  };

  

    await PostApiData("product/reseller_post_markups", Values);
  //}





}











export async function ValidateUpdateMarkup(values) {
  const errors = verify_markup_update({}, values);

  return errors;
}


async function verify_markup_update(error = {}, values) {

  const Values = {
  
    
    currency:values.currency,
    name: values.name,
    value: values.value,
    _id:values._id,

    
   
  };

  

  if(values.isCustomer == false)
  { 
  
    await PostApiData("product/update_markup", Values);
  }

  else
  {

    const Values = {
      
      currency:values.currency,
      name: values.name,
      value: values.value,
      _id:values._id,
    };
 
     await PostApiData("customer/update_markup", Values);


  }




}



export async function CreatePriceRulesValidator(values) {
  const errors = verify_rule_create({}, values);

  return errors;
}

async function verify_rule_create(error = {}, values) {

  const Values = {
          
    hardstop: values.hardstop,
    formula: values.formula,
    globalMarkup :values.globalMarkup,
    resellerGlobalMarkup :values.resellerGlobalMarkup,
    google_hardstop: values.google_hardstop,
    google_search_suppliers : values.google_search_suppliers,
    ean_similar_search:values.ean_similar_search,
    sku_similar_search:values.sku_similar_search,
    name_similar_search:values.name_similar_search,
    desc_similar_search:values.desc_similar_search,
    store_threshold:values.store_threshold,
    google_hardstop_pound:values.google_hardstop_pound,

  };

  await PostApiData("product/rule_create", Values);

}