import React, { useLayoutEffect, useMemo } from "react";
import { useState, useRef, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";

import logo from "../../src/assets/logo.png";
import env from "react-dotenv";
import axios from "axios";
import { AiFillAccountBook } from "react-icons/ai";

import { useNavigate, useNavigation } from "react-router-dom";
import useLoginValid from "../utils/useLoginValid";
import postApiData from "../helpers/postApiData";
import ProductBox from "../Products/ProductBox";
import getApiData from "../helpers/getApiData";

import { Link } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  const [user, setuserData] = useLoginValid(null);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const [role, setRole] = useState();
  const [allProducts, setAllProducts] = useState([]);
  const Menu = [
    "Profile Update",
    "Menu",
    "Screens",
    "Authenticate Screens",
    "User",
    "Product Settings",
    "Page Settings",
    "Tab Settings",
    "Logout",
  ];
  const perms = ["all","admin","admin","admin","admin","admin","admin","all"]

  const filteredMenu = Menu.filter((_, index) => {
    let rolenew = "";

    if (localStorage.getItem("user_data")) {
     rolenew = JSON.parse(localStorage.getItem("user_data")).role;
    }

    return rolenew === "admin" || perms[index] === "all";
  },[]);

  

  const [open, setOpen] = useState(false);
  const menuRef = useRef();
  const imgRef = useRef();

  const fetchData = async () => {
    try {
      const response = await getApiData("product/get_products", "");

      setAllProducts(response);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    
  };

  useLayoutEffect(() => {
    fetchData();

    return () => {
      if (localStorage.getItem("user_data")) {
        setRole(JSON.parse(localStorage.getItem("user_data")).role);
      }
    };
  }, []);

  useEffect(() => {
    if (searchText && searchText.length >= 2) {
      const filteredResults =
        allProducts &&
        allProducts.filter(
          (product) =>
            product.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (product.sku &&
              product.sku.toLowerCase().includes(searchText.toLowerCase())) ||
            (product.description &&
              product.description
                .toLowerCase()
                .includes(searchText.toLowerCase()))
        );

      setSearchResults(filteredResults);
    } else {
      setSearchResults([]);
    }
  }, [searchText, allProducts]);

  const handleOption = (menu) => {
    let user = localStorage.getItem("user_data");
    let role = JSON.parse(user).role;
    
    if (menu === "Logout") {
      let user = localStorage.getItem("user_data");

      localStorage.removeItem("user_data");
      const userValues = { username: user.username };

      const logout = axios
        .post(env.API_URL + "auth/logout", userValues, {})
        .then((response) => {
          if (response.status == 200) {
            localStorage.removeItem("user");
            navigate("/login");
          }
        })
        .catch((err) => {});
    }
    if (menu === "Profile Update") {
      navigate("/settings");
    }
    if (menu === "Menu" && role == "admin") {
      navigate("/menu_list");
    }
    if (menu === "Screens" && role == "admin") {
      navigate("/screen_list");
    }
    if (menu === "Authenticate Screens" && role == "admin") {
      navigate("/auth_list");
    }
    if (menu === "User" && role == "admin") {
      navigate("/user_list");
    }

    if (menu === "Product Settings" && role == "admin") {
      navigate("/product_settings");
    }

    if (menu === "Page Settings" && role == "admin") {
      navigate("/screen_settings");
    }

    if (menu === "Tab Settings" && role == "admin") {
      navigate("/tab_settings");
    }
    
  };

  useLayoutEffect(() => {
    return () => {
      if (localStorage.getItem("user_data") == null) {
        navigate("/login");
      } else {
        setuserData();
      }
    };
  });

  window.addEventListener("click", (e) => {
    if (e.target !== menuRef.current && e.target !== imgRef.current) {
      setOpen(false);
    }
  });

  const memoizedProductBox = useMemo(
    () => <ProductBox currentItems={searchResults && searchResults} />,
    [searchResults]
  );

  return (
    <>
      <nav className="bg-extra-color">
        <div className="mx-auto  max-w-7xl ">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex flex-shrink-0 items-center rounded-full">
                <Link to="/dashboard">
                  <img
                    className="block h-8   lg:hidden"
                    src={logo}
                    alt="Sip Desk"
                  />
                </Link>
                <Link to="/dashboard">
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src={logo}
                    alt="Sip Disk"
                  />
                </Link>
              </div>
            </div>

            <div className="mb-7 items-center justify-center lg:mr-44 md:mr-15 sm:mr-10 xl:mr-44 2xl:mr-44 ml-20  ">
              <div className="z-50 translate-y-1/3">
                <div className="relative w-full max-w-lg">
                  <form>
                    <div className="flex justify-between overflow-hidden rounded-md bg-white shadow shadow-black/20">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <AiOutlineSearch className="w-5 h-5 text-gray-500 dark:text-gray-400" />
                      </div>
                      <input
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        type="search"
                        className="bg-gray-50 border flex-shrink-0 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-white focus:border-white block w-full pl-10 p-2.5 focus:outline-none focus:ring-0"
                        placeholder="Search product... (Name or by SKU)"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className=" absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto  ">
              <div className="relative ml-44">
                <div className="h-auto  bg-extra-color  border-solid border-white flex justify-center pt-0 ">
                  <button
                    type="button"
                    className="flex rounded-full bg-maincolor text-sm focus:outline-none ring-2 ring-white focus:ring-white focus:ring-offset-2 focus:ring-offset-white"
                  >
                    <img
                      ref={imgRef}
                      onClick={() => setOpen(!open)}
                      src={logo}
                      alt="user"
                      className="h-10 w-10 object-cover border-4 border-maincolor rounded-full cursor-pointer  "
                    ></img>
                  </button>

                  {open && (
                    <div
                      ref={menuRef}
                      className="bg-white p-4 w-52 absolute h-max shadow-lg z-20 top-10 right-2"
                    >
                      <ul>
                        { filteredMenu.map((menu) => (
                          <li
                            onClick={() => {
                              setOpen(false);
                              handleOption(menu);
                            }}
                            className="font-dmsans p-2 text-md cursor-pointer rounded hover:bg-maincolor-light bg-opacity-75 hover:text-white"
                            key={menu}
                          >
                            {menu}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li>
                  <div>
                    <h1 className=" p-14 md:p-10 sm:p-0 lg:p-0 xl:p-0  2xl:p-0 caret-maincolor text-white text-dmsans  font-medium">
                      {user && user.username}
                    </h1>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="flex items-center justify-center h-fit bg-transparent">
        <div className="container mx-auto px-4">
          {searchText && memoizedProductBox}
        </div>
      </div>
    </>
  );
};

export default Header;
