import React, { useState } from "react";
import { useEffect, useMemo, useContext } from "react";
import { useFormik } from "formik";

import { ValidateUpdateMarkup } from "../utils/validateAPI";
import { GroupContext } from "./Markup";

import GetApiData from "../helpers/getApiData";
const MarkupUpdate = ({ props, markupState, isCustomer }) => {
  const { groupName } = useContext(GroupContext);

  const [value, setValue] = useState();

  async function fetchData() {
    


  }

  useEffect(() => {
    async function fetchData() {
    
    }

    fetchData();
  }, [value]);

  
  useEffect(() => {
    fetchData();
  }, [props]);




  const formik = useFormik({
    initialValues: {
      name: markupState.name,
      currency:markupState.valuePriceSign,
      value: markupState.value,
      isCustomer: isCustomer,
      groupName: groupName,
      product: markupState.product,
      _id:markupState._id,


    },

    validate: ValidateUpdateMarkup,

    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      values = await Object.assign(values);
      props(false);
      return false;
    },
  });


  
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="container mx-auto">
          <div className="flex items-center  justify-center min-h-screen  bg-transparent">
            <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl md:flex-row md:space-y-0">
              <div className="flex flex-col justify-center p-8 md:p-14 ">
                <div className="flex items-end  justify-end  p-0 ">
                  
                  <button
                    className="p-1 border-0 text-maincolor  ml-auto bg-transparent   text-3xl leading-none font-semibold outline-none focus:outline-none "
                    onClick={() => {
                      props(false);
                    }}
                  >
                    <span className="bg-transparent text-maincolor ">×</span>
                  </button>
                </div>
                <span className="mb-3 text-2xl font-bold font-dmsans text-subheading-400  text-maincolor">
                  Update Pricing Rule
                </span>
                <form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Name of Pricing Rule
                    </span>
                    <input
                      {...formik.getFieldProps("name")}
                      id="name"
                      type="text"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      placeholder="Rule Pricing Name"
                    ></input>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                
                  <div className="py-4 ">
                    <span className="mb-2 text-md font-dmsans font-bold text-gray-500">
                      Value of Pricing Rule
                    </span>
                    <input
                      {...formik.getFieldProps("value")}
                      id="value"
                      type="number"
                      min={0}
                      value={formik.values.value}
                      name="value"
                      className=" w-full placeholder:font-dmsans  border-b p-2 focus:outline-none text-center focus:border-maincolor  focus:border-b-2 transition-colors placeholder:font-light  "
                      placeholder="Pricing Value"
                    ></input>
                    <label className="relative left-0 top-1 cursor-text"></label>
                  </div>

                  <div className="flex items-center">
                    <div
                      {...formik.getFieldProps("currency")}
                      className="relative items-center"
                    >
                      <input
                        type="radio"
                        name="currency"
                        checked={markupState.valuePriceSign  === '£'}


                        onChange={formik.handleChange}
                      />
                      <span className="mx-1">£</span>
                    </div>
                    
                    <div className="relative">
                        <input
                          {...formik.getFieldProps("currency")}
                          type="radio"
                          name="currency"
                          
                          checked={markupState.valuePriceSign  === '%'}


                          onChange={formik.handleChange}
                        />
                        <span className="mx-2">%</span>
                    </div>
                  </div>


                  <div className="py-4 ">
                    <button
                      type="submit"
                      className="w-full border h-12 bg-maincolor text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default MarkupUpdate;
