import React from "react";

import { useState, useEffect, useMemo } from "react";
import postApiData from "../helpers/postApiData";
import { RxUpdate } from "react-icons/rx";
import noProduct from "../assets/noproduct.png";
import { FaSort } from "react-icons/fa";
import { CiDeliveryTruck } from "react-icons/ci";
import { IoLink } from "react-icons/io5";

import Parser from 'html-react-parser';



import { CircularLoader } from "../Container/CircularLoader";

import { AiFillInfoCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";


import { Link } from "react-router-dom";

import ToasterAlert from "../utils/ToasterAlert";
import useTabSetting from "../utils/useTabSettings";

const ProductDetail = ({
  handleColumnSort,
  sortColumn,
  sortDirection,
  currentItems,
  role,
  userid,
  published,
  setpublishUpdate,
}) => {
  

  

  const [CircularProgress, setCircularProgress] = useState({});
const [name,key]= useTabSetting('/product_details');


  const customStyleToolTip = {
    backgroundColor: "#f5621c",
    fontFamily: "DMSans, sans-serif",
    flexWrap: "wrap",
    whiteSpace: "normal",
    fontWeight: "normal",
    whiteSpace: "pre-wrap",
    overflowWrap: "break-word",
    flex: 1,
    flexWrap: "wrap",
    whiteSpace: "wrap", 
    maxWidth: "400px", 
  };
  

  const newPublishStates = useMemo(() => {
    const publishStates = {};

    for (const loadBrands of currentItems) {
      publishStates[loadBrands._id] = loadBrands.publish;
    }
    return publishStates;
  }, [currentItems]);

  const handleCheckboxChange = async (productId, publishValue) => {
    try {
      setCircularProgress(true);

      await postApiData("product/update_product", {
        id: productId,
        value: publishValue,
      });

      setpublishUpdate((prev) => prev + 1);

      setCircularProgress(false);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="relative flex flex-col m-6 space-y-8 bg-white shadow-2xl rounded-2xl">
   
      <div className="p-8 md:p-14">
       
        <span className="mb-3 font-dmsans text-subheading-400 text-maincolor">
          Product List
        </span>

        {CircularProgress == true ? <CircularLoader></CircularLoader> : <></>}

        <ToasterAlert></ToasterAlert>

        <div className="overflow-x-auto      scrollbar-thin scrollbar-thumb-maincolor">
          <table className="w-full divide-y divide-gray-200">

          <thead className="bg-gray-50">
  <tr>
    {name && name.map((el, i) => {
      switch (el) {
        case "PUBLISH":

          return (<> {role === "admin" ? (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Publish
            </th>
          ) : null}</>);


          
        case "STOCK":
          return (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              STOCK
            </th>
          );
        case "IMAGE":
          return (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Image
            </th>
          );
        case "NAME":
          return (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <button onClick={() => handleColumnSort("name")} className="flex items-center space-x-1">
                NAME
                {sortColumn === "name" && (
                  <span className={`${sortDirection === "asc" ? "text-gray-600" : "text-maincolor dark:text-maincolor"}`}>
                    {sortDirection === "asc" ? <FaSort /> : <FaSort />}
                  </span>
                )}
              </button>
            </th>
          );
        case "PRICE":
          return (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <button onClick={() => handleColumnSort("price")} className="flex items-center space-x-1">
                PRICE
                {sortColumn === "price" && (
                  <span className={`${sortDirection === "asc" ? "text-gray-600" : "text-maincolor dark:text-maincolor"}`}>
                    {sortDirection === "asc" ? <FaSort /> : <FaSort />}
                  </span>
                )}
              </button>
            </th>
          );
        case "DESCRIPTION":
          return (
            <th key={i} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              <button onClick={() => handleColumnSort("description")} className="flex items-center space-x-1">
                DESCRIPTION
                {sortColumn === "description" && (
                  <span className={`${sortDirection === "asc" ? "text-gray-600" : "text-maincolor dark:text-maincolor"}`}>
                    {sortDirection === "asc" ? <FaSort /> : <FaSort />}
                  </span>
                )}
              </button>
            </th>
          );
          case "STATUS":
            return (  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Status
        </th>);
              case "CATEGORY":
                return (  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          <button
            onClick={() => handleColumnSort("category")}
            className="flex items-center space-x-1"
          >
            CATEGORY
            {sortColumn === "category" && (
              <span
                className={`${
                  sortDirection === "asc"
                    ? "text-gray-600"
                    : "text-maincolor dark:text-maincolor"
                }`}
              >
                {sortDirection === "asc" ? (
                         <FaSort></FaSort>
                ) : (
                  <FaSort></FaSort>
                )}
              </span>
            )}
          </button>
        </th>);

case "DISTRIBUTOR":
  return (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          DISTRIBUTOR
        </th>
  );
case "WEIGHT":
  return (  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Weight
        </th>);
        case "BRAND":
          return (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Brand
        </th>);
     case "MARK UP":
      return (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          MARK UP
        </th>);

 case "PRICE AFTER MARK UP": 
 return (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Price After Mark Up
        </th>
 )
        
      case "MARK UP TYPE":
        return (
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Mark Up Type
        </th>);
          case "REASON TO PUBLISH" :
            return (<>
        {role != "reseller" ? (
          
          <>
            {published == true ? (
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Reason to Publish
              </th>
            ) : (
              <></>
            )}
               </>
        ) : (
          <></>
        )}</>
            );


        case "URL" :
          return (
            <>
          {  console.log("url",published)}
        {role != "reseller" ? (
          <>
              {published == true ? (
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                URL
              </th>
            ) : null}
         </>
        ) : null}
        </>
          );
          


        case "CUSTOMER MARK UP" :
          return ( <>
  {role != "reseller" ? ( <>
            
            <th className="px-6 py-3  text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Customer Mark up
            </th>
            </>
        ) : (
          <></>
        )}
        </>);


      case  "CUSTOMER PRICING":
        return ( <>
        {role != "reseller" ? ( <>

            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Customer Pricing
            </th>
       

            </>
        ) : (
          <></>
        )}


</>);
        
        
        case "STOCK THRESH HOLD":
          return ( <>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          Stock Thresh Hold
        </th>
        </>);
        

              case "EAN":
                return ( <>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          EAN
        </th>
        </>);
        
              case "SKU":
                return ( <>
        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          sku
        </th>
        </>);
        
  
        case "PUBLISHED PRICE":
          return ( <>
                {published == true ? (
                  
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
               PUBLISHED PRICE
                  </th>
                ) : null}
    </>);
          

      
      }
    })}
  </tr>
</thead>




            

<tbody className="bg-white divide-y divide-gray-200">
  {currentItems &&
    currentItems.map((item) => (
      <tr key={item._id}>
    
        {name && name.map((column, index) => {
          switch (column) {
            case "PUBLISH":
              return role === "admin" && !published ? (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    <button
                      disabled={item.publish}
                      className={`flex items-center justify-center w-auto border h-12 ${
                        item.publish
                          ? "bg-gray-300 text-gray-500 bg-text cursor-not-allowed"
                          : "bg-textColor text-white hover:bg-textColor hover-text-white"
                      } border-gray-300 text-md p-2 rounded-lg mb-6`}
                      onClick={(e) => handleCheckboxChange(item._id, true)}
                    >
                      <CiDeliveryTruck className="text-maincolor text-xl" />
                    </button>
                  </div>
                </td>
              ) : published ? (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  Published
                </td>
              ) : item.publish ? (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  Published
                </td>
              ) : null

            case "STOCK":
              return (
                <td key={index} className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.stock}
                  </div>
                </td>
              );

            case "IMAGE":
              return (
                <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900 whitespace-normal">
                  <img
                    src={item.image_url ? item.image_url : noProduct}
                  ></img>
                </div>
                
              </td>

              );


              case "NAME":
                return (
                  <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.name}
                      </div>
                    </td>
                );


                
           


                case "PRICE":
                  return (
                    <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                    {item.price >= 0 ? "£" : ""}

                      
                      {item.price && item.price.toFixed(2)}
                      
                    </div>
                  </td>
                  );
                case "DESCRIPTION":
                  return (
                    <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900 whitespace-normal">
                      <div className="flex grid-row-2 gap-2">
                        <div>{item.description}</div>
                        {item.description ? (
                          <div>
                            <a
                              data-tooltip-id="main_desc_tooltip"
                              data-tooltip-content={item.main_desc}
                              data-tooltip-place="top"
                              className="bg-red-500  whitespace-normal font-dmsans "
                            >
                              {item.main_desc ? (
                                <AiFillInfoCircle className="w-6 h-6  text-maincolor"></AiFillInfoCircle>
                              ) : (
                                <></>
                              )}


                            </a>
                            <Tooltip
                              id="main_desc_tooltip"
                              style={customStyleToolTip}
                              className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
                            ></Tooltip>
                            
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </td>
                  
                  );
                  case "STATUS":
                    return (    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.status}
                      </div>
                    </td>);
                      case "CATEGORY":
                        return (    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.category}
                      </div>
                    </td>);
        
        case "DISTRIBUTOR":
          return (
                <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {item.distributor}
                      </div>
                    </td>
          );
        case "WEIGHT":
          return (  <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.weight}
                      </div>
                    </td>);
                case "BRAND":
                  return (
                    <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {item.brand}
                    </div>
                  </td>
);
             case "MARK UP":
              return (
                <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                

                  
                  {item.markup}
                  {item.markupPriceSign == "percentage" ? "%" : ""}
                  
                </div>
              </td>
              );
         case "PRICE AFTER MARK UP": 
         return (
        
          <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm font-medium text-gray-900">
          {item.markup_price >= 0 ? "£" : ""}
            
            {item.markup_price && item.markup_price.toFixed(2)}
            
          </div>
        </td>

         )
                
              case "MARK UP TYPE":
                return (
            
                
                  <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {item.markupType}
                  </div>
                </td>


                );
                  case "REASON TO PUBLISH" :
                   return (<>   {published == true ? (
                    <td className="px-8 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 ">
                          {item.reason_to_publish}
                        </div>
                        
                      
                    </td>
                  ) : (
                    <></>
                  )}

              </>)
        
                case "URL" :
                  return (
                    <>
            

            {published == true ? (
                      <td className="px-8 py-4 whitespace-nowrap">
                        <div className="flex grid-row-2 gap-2">
                          <div className="text-sm font-medium text-gray-900 whitespace-normal">
                            {item.url_imported}
                          </div>
                          <Link to={item.url_imported} target="_blank">
                         
                            <IoLink />
 

                          </Link>
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}





                </>
                  );
                  
        
        
                case "CUSTOMER MARK UP" :
                  return ( <>

{role != "reseller" ? (
          <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                          
                              

                            {item.customer_markup}
                            {item.customer_markupPriceSign == "percentage"
                              ? "%"
                              : ""}

                              
                          </div>

                        </td>
                        
               ):null
              }

                </>);

        
        
    
case "PUBLISHED PRICE":
           
  return ( <>
{published == true ? (
  <td className="px-6 py-4 whitespace-nowrap">
    <div className="text-sm font-medium text-gray-900">
      {item.published_price}
    </div>
  </td>
) : (
  <></>
)}


        
</>);
                

              case  "CUSTOMER PRICING":
                return ( <>
               

               {role != "reseller" ? (
              
               <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                          {item.customer_group_price >= 0 ? "£" : ""}
                            
                            {item.customer_group_price &&
                              item.customer_group_price.toFixed(2)}
                            
                          </div>
                        </td>

               ):null
                            }

        
        </>);
                
                
                case "STOCK THRESH HOLD":
                  return ( <>
                     <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.stock_threshold}
                      </div>
                      
                    </td>


               
                </>);
                


                      case "EAN":
                        return ( <>
            
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {item.ean}
                </div>

              </td>
                </>);
                
                      case "SKU":
                        return ( <>
                        
              <td className="px-6 py-4 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {item.sku}
                </div>
              </td>
        
              
                </>);
                
      

                

            default:
              return null;
          }
        })}
      </tr>
    ))}
</tbody>


{/* 
            <tbody className="bg-white divide-y divide-gray-200">
              {currentItems &&
                currentItems.map((item) => (
                  <tr key={item._id}>
                    {role == "admin" && published == false ? (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          <button
                            disabled={item.publish}
                            className={`flex items-center justify-center w-auto border h-12 ${
                              item.publish
                                ? "bg-gray-300 text-gray-500 bg-text cursor-not-allowed"
                                : "bg-textColor text-white hover:bg-textColor hover-text-white"
                            } border-gray-300 text-md p-2 rounded-lg mb-6`}
                            //    className="flex items-center justify-center w-auto border h-12 bg-white  text-white border-gray-300 text-md p-2 rounded-lg mb-6 hover:bg-maincolor hover-text-white"
                            onClick={(e) =>
                              handleCheckboxChange(item._id, true)
                            }
                          >
                            <CiDeliveryTruck className="text-maincolor text-xl" />
                          </button>

                        </div>
                      </td>
                    ) : published == true ? (
                      <td className="px-6 py-4 whitespace-nowrap">
    
                        Published
                      </td>
                    ) : item.publish == true ? (
                      <td className="px-6 py-4 whitespace-nowrap">
                        
                        Published
                      </td>
                    ) : (
                      "Not Published"
                    )}

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.stock}
                      </div>
                    </td>

                    {published == true ? (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {item.published_price}
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}

                   <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        <img
                          src={item.image_url ? item.image_url : noProduct}
                        ></img>
                      </div>

                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 whitespace-normal">
                        {item.name}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      {item.price >= 0 ? "£" : ""}

                        
                        {item.price && item.price.toFixed(2)}
                        
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                          <div className="flex grid-row-2 gap-2">
                            <div>{item.description}</div>
                            {item.description ? (
                              <div>
                                <a
                                  data-tooltip-id="main_desc_tooltip"
                                  data-tooltip-content={item.main_desc}
                                  data-tooltip-place="top"
                                  className="bg-red-500  whitespace-normal font-dmsans "
                                >
                                  {item.main_desc ? (
                                    <AiFillInfoCircle className="w-6 h-6  text-maincolor"></AiFillInfoCircle>
                                  ) : (
                                    <></>
                                  )}


                                </a>
                                <Tooltip
                                  id="main_desc_tooltip"
                                  style={customStyleToolTip}
                                  className="bg-maincolor font-dmsans flex-wrap   whitespace-normal   font-normal "
                                ></Tooltip>
                                
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </td>
  

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.status}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500 whitespace-normal">
                        {item.category}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {item.distributor}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.weight}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.brand}
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      

                        
                        {item.markup}
                        {item.markupPriceSign == "percentage" ? "%" : ""}
                        
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                      {item.markup_price >= 0 ? "£" : ""}
                        
                        {item.markup_price && item.markup_price.toFixed(2)}
                        
                      </div>
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.markupType}
                      </div>
                    </td>

                    


                    {published == true ? (
                      <td className="px-8 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900 ">
                            {item.reason_to_publish}
                          </div>
                          
                        
                      </td>
                    ) : (
                      <></>
                    )}


                    {published == true ? (
                      <td className="px-8 py-4 whitespace-nowrap">
                        <div className="flex grid-row-2 gap-2">
                          <div className="text-sm font-medium text-gray-900 whitespace-normal">
                            {item.url_imported}
                          </div>
                          <Link to={item.url_imported} target="_blank">
                            {/* <BiLinkExternal></BiLinkExternal>
                            
                            <IoLink />
                        

                          </Link>
                        </div>
                      </td>
                    ) : (
                      <></>
                    )}
                    

                    {role != "reseller" ? (
                      <>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                          
                              

                            {item.customer_markup}
                            {item.customer_markupPriceSign == "percentage"
                              ? "%"
                              : ""}

                              
                          </div>

                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">
                          {item.customer_group_price >= 0 ? "£" : ""}
                            
                            {item.customer_group_price &&
                              item.customer_group_price.toFixed(2)}
                            
                          </div>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.stock_threshold}
                      </div>
                      
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.ean}
                      </div>

                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {item.sku}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>  */}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
