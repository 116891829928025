import DashBoard from "../DashBoard/DashBoard";
import Home from "../components/Home/Home";
import Login from "../components/Login/Login";
import { createBrowserRouter } from "react-router-dom";

import CreateMenu from "../Menu/CreateMenu";
import MenuList from "../Menu/MenuList";
import ScreenList from "../Screen/ScreenList";
import UserList from "../User/UserList";
import AuthScreenList from "../Screen/AuthScreenList";
import NotFound from "./NotFound";
import Product from "../Products/Product";
import Category from "../Products/Category";
import Distributor from "../Products/Distributor";
import Markup from "../Products/Markup";
import ListPage from "../Products/ListPage";
import Brand from "../Products/Brand";
import CategoryMain from "../Products/CategoryMain";
import CategoryDetail from "../Products/CategoryDetail";
import UserSetting from "../User/UserSetting";
import ProductPricing from "../Products/ProductPricing";
import ResellerMarkup from "../Products/ResellerMarkup";
import ResellerProduct from "../Products/ResellerProduct";
import ProductLight from "../Products/ProductLight";
import UpdateProducts from "../Products/UpdateProducts";
import PublishProducts from "../Products/PublishProducts";
import SearchProducts from "../Products/SearchProducts";
import ProductSimilarSearch from "../Products/ProductSimilarSearch";
import Setting from "../Settings/Setting";
import ProductHistoryData from "../Products/ProductHistoryData";
import GoogleProducts from "../Products/GoogleProducts";
import ScreenRights from "../utils/ScreenRights";
import Page_Settings from "../Settings/Page_Settings";

import TableSettings from "../Settings/TableSettings";
import GoogleDataMetrics from "../Products/GoogleDataMetrics";
export const router = createBrowserRouter([
  {
    path: "/",
    element:   <Login />,
  },

  {
    path: "/product_details",
    element: <ProductLight />,
  },

  {
    path: "/screen_rights",

    element: <ScreenRights />,
  },


  

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/home",
    element: <Home />,
  },
  {
    path: "/dashboard",
    element: <DashBoard />,
  },

  {
    path: "/menu",
    element: <CreateMenu />,
  },
  {
    path: "/menu_list",
    element: <MenuList />,
  },
  {
    path: "/screen_list",
    element: <ScreenList />,
  },
  {
    path: "/user_list",
    element: <UserList />,
  },
  {
    path: "/auth_list",
    element: <AuthScreenList />,
  },
  {
    path: "/product_list",
    element: <Product />,
  },
  {
    path: "/category_list",
    element: <Category />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/distributors",
    element: <Distributor />,
  },
  {
    path: "/markup",
    element: <Markup />,
  },
  {
    path: "/listpage",
    element: <ListPage />,
  },
  {
    path: "/brand",
    element:  <Brand />,
  },
  
  {
    path: "/category_main",
    element: <CategoryMain />,
  },
  {
    path: "/cat_detail",
    element: <CategoryDetail />,
  },
  {
    path: "/settings",
    element: <UserSetting />,
  },
  {
    path: "/product_price",
    element: <ProductPricing />,
  },
  {
    path: "/tab_settings",
    element: <TableSettings />,
  },

  

  {
    path: "/r_markup",
    element: <ResellerMarkup />,
  },
  {
    path: "/reseller_products",
    element: <ResellerProduct />,
  },

  ,
  {
    path: "/update_product",
    element: <UpdateProducts />,
  },

  {
    path: "/publish_product",
    element: <PublishProducts />,
  },
  {
    path: "/search_products",
    element: <SearchProducts />,
  },

  {
    path: "/product_settings",
    element: <Setting />,
  },
  {
    path: "/metrics",
    element: <GoogleDataMetrics />,
  },
  
  {
    path: "/product_history",
    element: <ProductHistoryData />,
  },

  {
    path: "/search_pattern",
    element: <ProductSimilarSearch></ProductSimilarSearch>,
  },
  {
    path: "/google_products",
    element: <GoogleProducts></GoogleProducts>,
  },
  {
    path: "/screen_settings",
    element: <Page_Settings></Page_Settings>,
  },
  

]);

export default router;
